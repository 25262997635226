import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const RedirectToExternalUrl = ({ url }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (url.startsWith("http")) {
      // If the URL is external, redirect using window.location
      window.location.replace(url);
    } else {
      // If the URL is internal, use navigate
      navigate(url);
    }
  }, [url, navigate]);

  // Render nothing since this component is only for redirection
  return null;
};

// Usage in your route configuration
