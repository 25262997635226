import React, { useState } from "react";
import Modal from "react-modal";
import { Logo } from "./Logo";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

Modal.setAppElement("#root");

const Popup = ({ isOpen, onRequestClose }) => {
  const [principal, setPrincipal] = useState();
  const [rateOfInterest, setRateOfInterest] = useState();
  const [period, setPeriod] = useState();
  const [periodUnit, setPeriodUnit] = useState('');
  const [frequency, setFrequency] = useState('');
  const [result, setResult] = useState({ maturityAmount: 0, interestEarned: 0 });



  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose} contentLabel="Popup">
      <div className="col-12 pull-left position-relative mb-2">
        <div className="col-lg-3 col-6">
          <Logo />
        </div>
        <button onClick={() => {
          onRequestClose();
          setPrincipal('');
          setRateOfInterest('');
          setPeriod('');
          setPeriodUnit('');
          setFrequency('');
          setResult({ maturityAmount: 0, interestEarned: 0 });
        }} className="cross">
          X
        </button>
      </div>
      <div className="calcpopup">
        <div className="tablisthead mb-3">
          <ul>
            <li>Fixed Deposit Calculator</li>
          </ul>
        </div>
        <p>
          Fixed Deposits are a great way to invest for those who rate safety
          higher than returns.
        </p>{" "}
        <p>
          This Fixed Deposit (FD) Calculator helps you find out how much
          interest you can earn on an FD and the value of your invesment
          (Principal) on Maturity when compounding of interest is done on a
          monthly, quarterly, half-yearly or yearly basis.
        </p>
        <div className="referralform w-100 m-0 pull-left p-3 pt-0">
          <Box component="form" noValidate autoComplete="off">
            <ul>
              <li>
                <TextField
                  id="filled-basic"
                  label="Principal"
                  variant="standard"
                  type="number"
                  value={principal}
                  onChange={(e) => setPrincipal(e.target.value)}
                />
              </li>
              <li>
                <TextField
                  id="filled-basic"
                  label="Rate of Interest"
                  variant="standard"
                  type="number"
                  value={rateOfInterest}
                  onChange={(e) => setRateOfInterest(e.target.value)}
                />
              </li>
              <li className="d-flex gap-3">
                <TextField
                  id="filled-basic"
                  label="Period"
                  variant="standard"
                  type="number"
                  value={period}
                  onChange={(e) => setPeriod(e.target.value)}
                />
                <FormControl fullWidth className="formcontrol">
                  <InputLabel id="demo-simple-select-label">Select</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={periodUnit}
                    onChange={(e) => setPeriodUnit(e.target.value)}

                  >
                    <MenuItem value={"days"}>Days</MenuItem>
                    <MenuItem value={"months"}>Months</MenuItem>
                    <MenuItem value={"years"}>Years</MenuItem>
                  </Select>
                </FormControl>
              </li>
              <li>
                {" "}
                <FormControl fullWidth className="formcontrol">
                  <InputLabel id="demo-simple-select-label">
                    Frequency
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={frequency}
                    label="Days"
                    onChange={(e) => setFrequency(e.target.value)}
                  >
                    <MenuItem value={"Simple Interest"}>Simple Interest</MenuItem>
                    <MenuItem value={"Compounded Monthly"}>Compounded Monthly</MenuItem>
                    <MenuItem value={"Compounded Quarterly"}>Compounded Quarterly</MenuItem>
                    <MenuItem value={"Compounded Half yearly"}>Compounded Half Yearly</MenuItem>
                    <MenuItem value={"Compounded Yearly"}>Compounded Yearly</MenuItem>
                  </Select>
                </FormControl>
              </li>
            </ul>
            <div className="mbtn refformbtn gap-3 mt-4">
              <div className="btnn">
                <button onClick={(e) => {
                  e.preventDefault();
                  const p = parseFloat(principal);
                  const rate = parseFloat(rateOfInterest) / 100;
                  let t = parseFloat(period);
                  // Convert period to years based on the selected unit
                  if (periodUnit === 'months') {
                    t /= 12;
                  } else if (periodUnit === 'days') {
                    t /= 365;
                  }
                  let amount;
                  switch (frequency) {
                    case 'Simple Interest':
                      amount = principal * (1 + rate * period);
                      break;
                    case 'Compounded Monthly':
                      amount = principal * Math.pow((1 + rate / 12), 12 * period);
                      break;
                    case 'Compounded Quarterly':
                      amount = principal * Math.pow((1 + rate / 4), 4 * period);
                      break;
                    case 'Compounded Half yearly':
                      amount = principal * Math.pow((1 + rate / 2), 2 * period);
                      break;
                    case 'Compounded Yearly':
                      amount = principal * Math.pow((1 + rate), period);
                      break;
                    default:
                      throw new Error('Invalid frequency');
                  }
                  let interestEarned = amount - p;
                  setResult({ maturityAmount: amount, interestEarned: interestEarned });
                }}>Calculate</button>
              </div>
              <div className="btnn">
                <button onClick={() => {
                  setPrincipal('');
                  setRateOfInterest('');
                  setPeriod('');
                  setPeriodUnit('');
                  setFrequency('');
                  setResult({ maturityAmount: 0, interestEarned: 0 });
                }} type="reset">Reset</button>
              </div>
            </div>
          </Box>
          <p className="mt-2 col-12 pull-left text-center">
            <small>
              <strong>Note</strong>: In India, banks use quarterly compounding
              to calculate interest in rupees.
            </small>
          </p>
          {/* <h5 className="fs-4 m-0">
            You Invested{" "}
            <span className="fs-3 fw-bold">
              <i className="finr"></i>1000/-
            </span>
          </h5> */}
          <h5 className="fs-5 m-0">
            Maturity Value{" "}
            <span className="fs-3 fw-bold">
              <i className="finr"></i>{result.maturityAmount.toFixed(2)}
            </span>{" "}
            Interest earned{" "}
            <span className="fs-3 fw-bold">
              <i className="finr"></i>{result.interestEarned.toFixed(2)}{" "}
            </span>
          </h5>
        </div>
      </div>
    </Modal>
  );
};

export default Popup;
