import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Context } from "../context";
import { loadReCaptchaScript } from "./common/FormattedDate";
import { Box, TextField } from "@mui/material";
import { leadApi, sendOtpApi } from "../utils/APICollection";
import ClearIcon from "@mui/icons-material/Clear";
import { CAPTCHA_KEY } from "../utils/constants";
import { InputAdornment } from "@mui/material";

const ReferForm = ({ catID, smsType }) => {
  const [data, setData] = useState({ name: "", mobileNo: "" });
  const [formValues, setFormValues] = useState([
    {
      user_name: "",
      contact_number: "",
      email: "",
      city: "",
      state: "",
      category_code: "ReferEarn",
      ref: "",
      source: "",
      medium: "",
      campaign: "",
      country: "",
      product: "ReferEarn",
      ip: "",
      device: "",
      status: "",
      client_id: sessionStorage.getItem("clientId"),
      sub_query: "",
      existing_customer: "",
      explain_query: "",
    },
  ]);
  const navigate = useNavigate();
  const {
    setCategoryID,
    setUrlData,
    ipAddress,
    setIPAddress,
    loading,
    setLoading,
    deviceID,
    setDeviceID,
    setNum,
    setName,
    setIsLeadCapture,
  } = useContext(Context);

  const [searchParams, setSearchParams] = useSearchParams();
  searchParams.get("refcode");
  const getIP = async () => {
    try {
      const res = await axios.get("https://api.ipify.org?format=json");
      setIPAddress(res?.data?.ip);
    } catch (error) {
      console.log(error);
    }
  };
  const handleDefaultChange = (e) => {
    if (e.target.name == "mobileNo") {
      setNum(e.target.value);
    }
    if (e.target.name == "name") {
      setName(e.target.value);
    }
    setData({
      ...data,
      [e.target.name]:
        e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1),
    });
  };

  const handleOnchange = (e, i) => {
    let newFormValues = [...formValues];
    newFormValues[i][e.target.name] =
      e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
    setFormValues(newFormValues);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLeadCapture(true);
    setLoading(true);
    try {
      getIP();
      setCategoryID("ReferEarn");
      setUrlData({
        ref: searchParams.get("utm_refcode"),
        source: searchParams.get("utm_source"),
        medium: searchParams.get("utm_medium"),
        campaign: searchParams.get("utm_campaign"),
      });
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(String(CAPTCHA_KEY), { action: "submit" })
          .then(async (token) => {
            const { data: data1 } = await leadApi({
              mobile_no: data?.mobileNo,
              captchaToken: token,
              leadInfo: [
                {
                  user_name: data.name,
                  contact_number: data.mobileNo,
                  email: "",
                  city: "",
                  state: "",
                  category_code: "ReferEarn",
                  ref: searchParams.get("utm_refcode"),
                  source: searchParams.get("utm_source"),
                  medium: searchParams.get("utm_medium"),
                  campaign: searchParams.get("utm_campaign"),
                  country: "",
                  product: "ReferEarn",
                  ip: ipAddress,
                  device: deviceID,
                  status: "",
                  client_id: "",
                  sub_query: "",
                  existing_customer: "",
                  explain_query: "",
                },
                ...formValues,
              ],
            });
            if (data1) {
              window.grecaptcha.ready(() => {
                window.grecaptcha
                  .execute(String(CAPTCHA_KEY), { action: "submit" })
                  .then(async (token) => {
                    const { data: data2 } = await sendOtpApi({
                      mobile_no: data.mobileNo,
                      sms_type: smsType ? smsType : "sms_common",
                      user_name: data.name,
                      captchaToken: token,
                    });
                    if (data2) {
                      setLoading(false);
                      navigate("/validateotp");
                    } else {
                      setLoading(false);
                    }
                  });
              });
            } else {
              setLoading(false);
            }
          });
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const getSourceByCategory = (categoryID) => {
    const categorySourceMap = {
      ReferEarn: "SMC Website | ReferEarn",
    };
    return categorySourceMap[categoryID] || "SMC Website | Refer a friend";
  };

  const handleAddField = () => {
    setFormValues([
      ...formValues,
      {
        user_name: "",
        contact_number: "",
        email: "",
        city: "",
        state: "",
        category_code: "ReferEarn",
        ref: searchParams.get("utm_refcode"),
        source: searchParams.get("utm_source"),
        medium: searchParams.get("utm_medium"),
        campaign: searchParams.get("utm_campaign"),
        country: "",
        product: "ReferEarn",
        ip: "",
        device: "",
        status: "",
        client_id: sessionStorage.getItem("clientId"),
        sub_query: "",
        existing_customer: "",
        explain_query: "",
      },
    ]);
  };

  const handleRemove = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };

  React.useEffect(() => {
    sessionStorage.setItem("clientId", data.name);
    let modifyFormValues = [...formValues];
    modifyFormValues.forEach((element) => {
      element.client_id = data.name;
      element.source = searchParams.get("utm_source");
      element.ref = searchParams.get("utm_refcode");
      element.campaign = searchParams.get("utm_campaign");
      element.medium = searchParams.get("utm_medium");
    });
    setFormValues(modifyFormValues);
  }, [data.name, formValues[0].contact_number]);

  useEffect(() => {
    let userAgent = navigator.userAgent;
    if (/Android/i.test(userAgent)) {
      setDeviceID("Android device.");
    } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
      setDeviceID("iOS device.");
    } else if (/Macintosh/i.test(userAgent)) {
      setDeviceID("macOS device.");
    } else if (/Windows/i.test(userAgent)) {
      setDeviceID("Windows device.");
    } else {
      setDeviceID("Unknown Device");
    }
    getIP();
    loadReCaptchaScript();
  }, []);
  const ALPHA_REGEX = /^[a-zA-Z0-9 ]+$/;
  const ALPHA_REGEXY = /^[a-zA-Z ]+$/;
  return (
    <>
      <div
        className="webcent"
        data-aos="fade-up"
        data-aos-offset="200"
        data-aos-duration="600"
        data-aos-once="true"
        data-aos-easing="ease-in-sine"
      >
        <div className="container">
          <div className="row">
            <div className="web-container">
              <div className="title">
                <h3>Start Referring Now</h3>
              </div>
              <div className="referralform">
                <Box
                  component="form"
                  sx={{
                    "& > :not(style)": { m: 1 },
                  }}
                  autoComplete="off"
                  onSubmit={handleSubmit}
                >
                  <ul>
                    <li>
                      <TextField
                        id="filled-basic"
                        label="Enter Your Name or Client Code"
                        variant="standard"
                        name="name"
                        required
                        InputLabelProps={{
                          required: false,
                        }}
                        onKeyDown={(event) => {
                          if (!ALPHA_REGEX.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        inputProps={{ minLength: 1, maxLength: 50 }}
                        value={data?.name}
                        onChange={(e) => handleDefaultChange(e)}
                      />
                    </li>
                    <li>
                      <TextField
                        id="filled-basic"
                        label="Mobile No."
                        variant="standard"
                        name="mobileNo"
                        type="Number"
                        required
                        InputLabelProps={{
                          required: false,
                        }}
                        value={data?.mobileNo || ""}
                        onChange={(e) => handleDefaultChange(e)}
                        onInput={(e) => {
                          e.target.value = Math.max(0, parseInt(e.target.value))
                            .toString()
                            .slice(0, e.target.maxLength);
                        }}
                        inputProps={{
                          minLength: 10,
                          maxLength: 10,
                          type: "number",
                        }}
                      />
                    </li>
                    {formValues?.map((val, i) => (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <ul>
                          <li>
                            <TextField
                              id="filled-basic"
                              label="Friend’s Name"
                              variant="standard"
                              name="user_name"
                              required
                              InputLabelProps={{
                                required: false,
                              }}
                              onKeyDown={(event) => {
                                if (!ALPHA_REGEXY.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              inputProps={{ minLength: 1, maxLength: 50 }}
                              value={val?.user_name}
                              onChange={(e) => handleOnchange(e, i)}
                            />
                          </li>

                          <li>
                            <TextField
                              id="filled-basic"
                              label="Friend’s Contact No."
                              variant="standard"
                              name="contact_number"
                              type="Number"
                              fullWidth
                              required
                              InputLabelProps={{
                                required: false,
                              }}
                              value={formValues?.contact_number}
                              onChange={(e) => handleOnchange(e, i)}
                              onInput={(e) => {
                                e.target.value = Math.max(
                                  0,
                                  parseInt(e.target.value)
                                )
                                  .toString()
                                  .slice(0, e.target.maxLength);
                              }}
                              inputProps={{
                                minLength: 10,
                                maxLength: 10,
                                type: "number",
                              }}
                              disabled={loading}
                              InputProps={{
                                endAdornment:
                                  i == 0 ? null : (
                                    <InputAdornment position="end">
                                      <ClearIcon
                                        sx={{
                                          position: "absolute",
                                          right: 0,
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          handleRemove(i);
                                        }}
                                      />
                                    </InputAdornment>
                                  ),
                              }}
                            />
                          </li>
                        </ul>
                      </div>
                    ))}
                  </ul>
                  <div className="mbtn refformbtn gap-3">
                    <div className="btnn">
                      <button onClick={() => handleAddField()}>
                        + Add More Friend
                      </button>
                    </div>
                    <div className="btnn">
                      <button type="submit">Submit</button>
                    </div>
                  </div>
                </Box>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReferForm;
