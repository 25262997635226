import React, { useContext } from "react";
import Home from "../pages";
import { Invest } from "../pages/invest";
import { Ipodetail } from "../pages/invest/investList/upComingIPODetais";
import { ApplyIpo } from "../pages/invest/investList/applyIPO";
import { MFDetail } from "../pages/invest/investList/mutualFundDetails";
import { Tablecharts } from "../charts/Tablechart";
import { TradingView } from "../charts/TradingView";
import { Trading } from "../pages/trade";
import { Learningcenter } from "../pages/learn";
import { Partnerus } from "../pages/partnerUs";
import { SipCalculor } from "../calculators/SipCalculor";
import { ReturnCalculator } from "../calculators/ReturnCalculator";
import { LineCharts } from "../charts/LineChart";
import { PieChart } from "../charts/PieChart";
import { Distributor } from "../pages/partnerUs/partnerList/BecomeDistributer";
import { Infographic } from "../charts/Infographic";
import { Optionchain } from "../components/Optionchain";
import { Webinar } from "../pages/webinar";
import Error from "../components/Error";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import ScrollToTop from "../components/ScrollToTop";
import InvestHolidayTradingHolidays from "../pages/invest/holidaysList/TradingHolidays";
import InvestHolidaysNSEHolidays from "../pages/invest/holidaysList/NSEHolidays";
import InvestHolidaysBSEHolidays from "../pages/invest/holidaysList/BSEHolidays";
import InvestHolidaysMCXHolidays from "../pages/invest/holidaysList/MCXHolidays";
import InvestHolidaysMuhuratDates from "../pages/invest/holidaysList/MuhuratDates";
import MutualFund from "../pages/commonpages/investList/MutualFund";
import Stocks from "../pages/commonpages/investList/Stocks";
import UpcomingIPO from "../pages/commonpages/investList/Ipo";
import USStock from "../pages/commonpages/investList/USStock";
import ReferAndEarn from "../pages/commonpages/investList/ReferAndEarn";
import Autotrander from "../pages/commonpages/tools/Autotrander";
import TechnoFunda from "../pages/commonpages/telegramChannelsList/TechnoFunda";
import EquityFunda from "../pages/commonpages/telegramChannelsList/EquityFunda";
import IndexTrading from "../pages/commonpages/telegramChannelsList/IndexTrading";
import CommodityMantra from "../pages/commonpages/telegramChannelsList/CommodityMantra";
import { Research } from "../pages/research";
import { OpenDematAccount } from "../pages/openDematAccount";
import ValidateOtp from "../components/ValidateOtp";
import Thankyou from "../components/Thankyou";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { WebinarDetails } from "../pages/learn/webinar";
import { WiseMoney } from "../pages/research/research-reports/wisemoney";
import { Contactus } from "../pages/contactus";
import { Escalation } from "../pages/contactus/escalation-matrix";
import { Downloads } from "../pages/contactus/downloads";
import { FAQ } from "../pages/contactus/faq";
import { DoDont } from "../pages/contactus/dodont";
import { DerivativesMarketAnalysis } from "../pages/commonpages/tradingList/future";
import { AlgoTrading } from "../pages/trade/tools/algo-trading-plateform";
import { CommodityMarketAnalysis } from "../pages/commonpages/tradingList/commodities";
import { CurrencyMarketAnalysis } from "../pages/commonpages/tradingList/currency";
import { FixedIncome } from "../pages/commonpages/investList/FixedIncome";
import StockDetails from "../pages/invest/investList/stockDetails";
import { Context } from "../context";
import { ResearchCategoryDetails } from "../pages/research/common-research-pages";
import FundamentalResearch from "../pages/research/fundaMentalResearch";
import TechnicalResearch from "../pages/research/technicalResearch";
import Disclaimer from "../components/Disclaimer";
import PrivacyPolicy from "../components/PrivacyPolicy";
import Copyright from "../components/Copyright";
import Testimonials from "../components/Testimonials";
import SiteMap from "../components/SiteMap";
import TermsCondition from "../components/TermsCondition";
import { CategoryFiter } from "../CategoryFilter";
import { newRoutes, oldRoutes } from "../utils/Data";
import CommonDialog from "../components/common/CommonDialog";
import Conditions from "../components/Conditions";
import Ipo from "../pages/commonpages/investList/Ipo";
import AboutUs from "../pages/partnerUs/about-us";
import { RedirectToExternalUrl } from "../components/BankingPage";

const Routing = () => {
  return (
    <>
      <Router>
        <ScrollToTop />
        <Routes>
          {/* invest menu */}
          <Route exact path="/invest/Equities" element={<Stocks />} />
          <Route exact path="/product/Equities" element={<Stocks />} />
          <Route exact path="/invest/mutual-fund" element={<MutualFund />} />
          <Route exact path="/invest/ipo" element={<Ipo />} />
          <Route exact path="/invest/us-stocks" element={<USStock />} />
          <Route exact path="/product/us-stocks" element={<USStock />} />
          <Route
            exact
            path="/invest/mutual-fund/detail"
            element={<CommonDialog />}
          />
          <Route
            exact
            path={oldRoutes?.[0]}
            element={<Navigate to={newRoutes?.[0]} />}
          />
          <Route exact path="/invest/refer-earn" element={<ReferAndEarn />} />
          <Route
            exact
            path={oldRoutes?.[14]}
            element={<Navigate to={newRoutes?.[14]} />}
          />
          <Route
            exact
            path="/invest/ipo/ipodetails/:id"
            element={<Ipodetail />}
          />
          <Route exact path="/invest/ipo/appy-ipo/:id" element={<ApplyIpo />} />
          <Route
            exact
            path="/invest/mutual-fund/detail/:id"
            element={<MFDetail />}
          />
          <Route exact path="/invest/Equities/:id" element={<StockDetails />} />

          {/* Holidaye Menu Routes */}
          <Route
            exact
            path="/invest/trading-holidays-2024"
            element={<InvestHolidayTradingHolidays />}
          />

          <Route
            exact
            path="/invest/nse-holiday-2024"
            element={<InvestHolidaysNSEHolidays />}
          />
          <Route
            exact
            path="/invest/bse-holiday-2024"
            element={<InvestHolidaysBSEHolidays />}
          />
          <Route
            exact
            path="/invest/mcx-holiday-2024"
            element={<InvestHolidaysMCXHolidays />}
          />
          <Route
            exact
            path="/invest/muhurat-holiday-2024"
            element={<InvestHolidaysMuhuratDates />}
          />

          {/* Trade Tabs Tools Menu Routes */}
          <Route exact path="/autotrender" element={<Autotrander />} />

          {/* Trade Tabs Research Via Telegram Menu Routes */}
          <Route
            exact
            path="/research/fundamental-research"
            element={<FundamentalResearch />}
          />
          <Route
            exact
            path="/research/technical-research"
            element={<TechnicalResearch />}
          />

          {/* Telegram Menu routes */}

          {/* <Route exact path="/techno-funda" element={<TechnoFunda />} /> */}
          {/* <Route exact path="/equity-funda" element={<EquityFunda />} /> */}
          {/* <Route exact path="/index-trading" element={<IndexTrading />} /> */}

          {/* <Route exact path="/commodity-mantra" element={<CommodityMantra />} /> */}

          <Route exact path="/" element={<Home />} />
          <Route
            exact
            path={oldRoutes?.[17]}
            element={<Navigate to={newRoutes?.[17]} />}
          />
          <Route
            exact
            path={oldRoutes?.[19]}
            element={<RedirectToExternalUrl url={newRoutes?.[19]} />}
          />
          <Route
            exact
            path={oldRoutes?.[20]}
            element={<RedirectToExternalUrl url={newRoutes?.[20]} />}
          />
          <Route
            exact
            path={oldRoutes?.[26]}
            element={<RedirectToExternalUrl url={newRoutes?.[20]} />}
          />
          <Route
            exact
            path={oldRoutes?.[21]}
            element={<RedirectToExternalUrl url={newRoutes?.[21]} />}
          />
          <Route
            exact
            path={oldRoutes?.[25]}
            element={<RedirectToExternalUrl url={newRoutes?.[21]} />}
          />
          <Route
            exact
            path={oldRoutes?.[22]}
            element={<RedirectToExternalUrl url={newRoutes?.[22]} />}
          />
          <Route
            exact
            path={oldRoutes?.[23]}
            element={<RedirectToExternalUrl url={newRoutes?.[23]} />}
          />
          <Route
            exact
            path={oldRoutes?.[27]}
            element={<RedirectToExternalUrl url={newRoutes?.[23]} />}
          />
          <Route
            exact
            path={oldRoutes?.[29]}
            element={<RedirectToExternalUrl url={newRoutes?.[20]} />}
          />
          <Route
            exact
            path={oldRoutes?.[24]}
            element={<RedirectToExternalUrl url={newRoutes?.[24]} />}
          />
          <Route
            exact
            path={oldRoutes?.[19]}
            element={<RedirectToExternalUrl url={newRoutes?.[19]} />}
          />
          <Route
            exact
            path={oldRoutes?.[28]}
            element={<RedirectToExternalUrl url={newRoutes?.[25]} />}
          />
          <Route exact path="/invest" element={<Invest />} />
          <Route
            exact
            path="/online-trading/overview"
            element={<Navigate to="/trade" />}
          />
          <Route exact path="/trade" element={<Trading />} />
          <Route
            exact
            path={oldRoutes?.[3]}
            element={<Navigate to={newRoutes?.[3]} />}
          />
          <Route exact path="/research" element={<Research />} />
          {/* <Route exact path="/learning-centre" element={<Learningcenter />} /> */}
          <Route
            exact
            path="/partner-us/authorized-person"
            element={<Partnerus />}
          />
          <Route
            exact
            path={oldRoutes?.[11]}
            element={<Navigate to={newRoutes?.[11]} />}
          />
          <Route exact path="/partner-us" element={<Partnerus />} />

          {/* <Route exact path="/invest/tchart" element={<Tablecharts />} /> */}
          {/* <Route exact path="/invest/trading-view" element={<TradingView />} /> */}

          <Route exact path="/demat-account" element={<OpenDematAccount />} />

          <Route exact path="/sip-calculator" element={<SipCalculor />} />
          <Route
            exact
            path="/return-calculator"
            element={<ReturnCalculator />}
          />
          <Route exact path="/line-chart" element={<LineCharts />} />
          <Route exact path="/pic-chart" element={<PieChart />} />

          {/* <Route exact path="/search" element={<Searchbox />} /> */}
          <Route
            exact
            path="/partner-us/become-distributor"
            element={<Distributor />}
          />
          <Route exact path="/about-us" element={<AboutUs />} />

          <Route
            exact
            path={oldRoutes?.[12]}
            element={<Navigate to={newRoutes?.[12]} />}
          />
          {/* <Route exact path="/infographic" element={<Infographic />} /> */}
          <Route
            exact
            path="/invest/Equities/option-chain/:symbol/:id"
            element={<Optionchain />}
          />
          <Route
            exact
            path="/invest/mutual-fund/category-filter"
            element={<CategoryFiter />}
          />

          <Route
            exact
            path={oldRoutes?.[10]}
            element={<Navigate to={newRoutes?.[10]} />}
          />
          <Route
            exact
            path={oldRoutes?.[13]}
            element={<Navigate to={newRoutes?.[13]} />}
          />
          <Route exact path="/invest/webinar" element={<Webinar />} />

          <Route path="*" element={<Error />} />
          <Route path="/validateotp" element={<ValidateOtp />} />
          <Route path="/thankyou" element={<Thankyou />} />
          <Route exact path="/learning-centre/webinar" element={<Webinar />} />
          <Route
            exact
            path="/learning-centre/webinar/webinar-details/:id"
            element={<WebinarDetails />}
          />
          <Route
            exact
            path="/research/wise-money/:id"
            element={<WiseMoney />}
          />
          <Route
            exact
            path="/research/:name/:id"
            element={<ResearchCategoryDetails />}
          />
          <Route
            exact
            path={oldRoutes?.[4]}
            element={<Navigate to={newRoutes?.[4]} />}
          />
          <Route
            exact
            path={oldRoutes?.[5]}
            element={<Navigate to={newRoutes?.[5]} />}
          />
          <Route
            exact
            path={oldRoutes?.[6]}
            element={<Navigate to={newRoutes?.[6]} />}
          />
          <Route
            exact
            path={oldRoutes?.[7]}
            element={<Navigate to={newRoutes?.[7]} />}
          />
          <Route
            exact
            path={oldRoutes?.[8]}
            element={<Navigate to={newRoutes?.[8]} />}
          />
          <Route
            exact
            path={oldRoutes?.[9]}
            element={<Navigate to={newRoutes?.[3]} />}
          />

          <Route
            exact
            path={oldRoutes?.[4]}
            element={<Navigate to={newRoutes?.[4]} />}
          />

          <Route exact path="/contact-us" element={<Contactus />} />
          <Route
            exact
            path={oldRoutes?.[16]}
            element={<Navigate to={newRoutes?.[16]} />}
          />
          <Route
            exact
            path={oldRoutes?.[18]}
            element={<Navigate to={newRoutes?.[18]} />}
          />

          <Route
            exact
            path="/contact-us/escalation-matrix"
            element={<Escalation />}
          />
          <Route exact path="/contact-us/downloads" element={<Downloads />} />
          <Route
            exact
            path={oldRoutes?.[15]}
            element={<Navigate to={newRoutes?.[15]} />}
          />
          <Route exact path="/contact-us/faqs" element={<FAQ />} />
          <Route exact path="/contact-us/do-dont" element={<DoDont />} />
          <Route
            exact
            path="/FuturesOptions"
            element={<DerivativesMarketAnalysis />}
          />
          {/* <Route exact path="/derivatives-market-analysis/view-archives" element={<DerivativeArchives />} /> */}
          <Route
            exact
            path="/trade/algo-trading-platform"
            element={<AlgoTrading />}
          />
          <Route
            exact
            path="/product/algo-trading-platform"
            element={<AlgoTrading />}
          />
          <Route
            exact
            path={oldRoutes?.[2]}
            element={<Navigate to={newRoutes?.[2]} />}
          />

          {/* <Route
            exact
            path="/Commodity"
            element={<CommodityMarketAnalysis />}
          /> */}
          {/* <Route exact path="/Currency" element={<CurrencyMarketAnalysis />} /> */}
          {/* <Route exact path="/fixed-income" element={<FixedIncome />} /> */}
          <Route exact path="/disclaimer" element={<Disclaimer />} />
          <Route exact path="/privacyPolicy" element={<PrivacyPolicy />} />
          <Route exact path="/copyright" element={<Copyright />} />
          <Route exact path="/Conditions" element={<Conditions />} />
          <Route exact path="/testimonials" element={<Testimonials />} />
          <Route exact path="/siteMap" element={<SiteMap />} />
          <Route
            exact
            path="/invest/refer-earn/termsandconditions"
            element={<TermsCondition />}
          />
        </Routes>
        <ToastContainer />
      </Router>
    </>
  );
};

export default Routing;
