
import React from "react";
import { useState, useEffect } from "react";
const PaginationComponent = ({ data, currentPage, setCurrentPage }) => {
    const DOTS = "...";

    const range = (start, end) => {
        let length = end - start + 1;
        return Array.from({ length }, (_, idx) => idx + start);
    };

    const paginationRange = () => {
        const siblingCount = 1;
        const totalPageCount = Math.ceil(data.length / 10);
        const totalPageNumbers = siblingCount + 5;
        if (totalPageNumbers >= totalPageCount) {
            return range(1, totalPageCount);
        }
        const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
        const rightSiblingIndex = Math.min(
            currentPage + siblingCount,
            totalPageCount
        );
        const shouldShowLeftDots = leftSiblingIndex > 2;
        const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;

        const firstPageIndex = 1;
        const lastPageIndex = totalPageCount;

        if (!shouldShowLeftDots && shouldShowRightDots) {
            let leftItemCount = 3 + 2 * siblingCount;
            let leftRange = range(1, leftItemCount);

            return [...leftRange, DOTS, totalPageCount];
        }

        if (shouldShowLeftDots && !shouldShowRightDots) {
            let rightItemCount = 3 + 2 * siblingCount;
            let rightRange = range(
                totalPageCount - rightItemCount + 1,
                totalPageCount
            );
            return [firstPageIndex, DOTS, ...rightRange];
        }

        if (shouldShowLeftDots && shouldShowRightDots) {
            let middleRange = range(leftSiblingIndex, rightSiblingIndex);
            return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
        }
    };
    let lastPage = paginationRange()[paginationRange().length - 1];
    return (
        <div className="pagination">
            {currentPage !== 1 && (
                <a
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                        window.scrollTo({ top: 0, behavior: 'smooth' });
                        setCurrentPage(currentPage - 1)
                    }}
                >
                    <i className="previous"></i>
                </a>
            )}
            {paginationRange().map((page) => {
                if (page === DOTS) {
                    return <a>&#8230;</a>;
                }
                return (
                    <a
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            setCurrentPage(page)
                            window.scrollTo({ top: 0, behavior: 'smooth' });
                        }}
                        className={currentPage === page ? "active" : ""}
                    >
                        {page}
                    </a>
                );
            })}
            {currentPage !== lastPage && (
                <a
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                        setCurrentPage(currentPage + 1)
                        window.scrollTo({ top: 0, behavior: 'smooth' });
                    }}
                >
                    <i className="next"></i>
                </a>
            )}
        </div>
    )
}
export default PaginationComponent;