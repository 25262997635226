import React, { useEffect, useState } from "react";
import Footer from "../../../components/Footer";
import { Link } from "react-router-dom";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import Header from "../../../components/Header";
import { ThreeDots } from "react-loader-spinner";
import SEO from "../../../components/common/SEO";
import { seoData } from "../../../utils/Data";
import { getBseHoliday } from "../../../utils/APICollection";

const InvestHolidaysBSEHolidays = () => {
  const [bseHolidaysData, setBseHolidaysData] = useState([]);
  const [ipoLoading, setIpoLoading] = useState(false);
  useEffect(() => {
    fetchBseHolidaysData();
  }, []);

  const fetchBseHolidaysData = async () => {
    setIpoLoading(true);
    try {
      const res = await getBseHoliday();
      setBseHolidaysData(res?.data?.data);
      setIpoLoading(false);
    } catch (err) {
      console.log(err);
    }
  };
  const monthList = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const getDate = (dateValue) => {
    let date = new Date(dateValue);
    return (
      date.getDate() +
      " " +
      monthList[date.getMonth()] +
      " " +
      date.getFullYear()
    );
  };
  const getMonthIndex = (dateValue) => {
    let date = new Date(dateValue);
    return date.getMonth();
  };

  return (
    <>
      <Header />
      <SEO
        title={seoData.bseHolidays.title}
        description={seoData.bseHolidays.description}
        name="Company name."
        type="article"
        url={seoData.bseHolidays.url}
        image={seoData.bseHolidays.image}
      />

      <section id="banner">
        <div className="banner internal mpadding">
          <div className="container">
            <div className="bredcrum">
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/invest">Invest</Link>
                </li>
                <li>BSE Holidays</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <main>
        <section id="main">
          <div className="webcent">
            <div className="container">
              <div className="row">
                <div className="web-container">
                  <div className="col-lg-10 m-auto">
                    <div className="title">
                      <h3>BSE Holidays 2024</h3>
                    </div>
                    {ipoLoading ? (
                      <ThreeDots
                        height="90"
                        width="90"
                        radius="10"
                        color="#3AA29F"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                        // wrapperClassName=""
                        visible={true}
                      />
                    ) : (
                      <div className="ipostable holidays">
                        <div className="tablist gap-sm-4 d-flex flex-wrap">
                          <div className="ipostn p-0">
                            <div className="ipotitle">
                              <h4>BSE Holidays 2024 list</h4>
                            </div>
                            <div className="ipotable defaulttable">
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th>Holidays</th>
                                    <th>Date</th>
                                    <th>Day</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {bseHolidaysData?.map((holiday) => (
                                    <tr>
                                      <td> {holiday?.Purpose}</td>
                                      <td>{getDate(holiday?.HolidayDate)}</td>
                                      <td>{holiday?.day}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div className="ipostn">
                            <div className="ipotitle">
                              <h4>
                                Following public holidays are falling on
                                Saturday / Sunday:
                              </h4>
                            </div>
                            {/* <div className="ipotable defaulttable">
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th>Holiday</th>
                                    <th>Date</th>
                                    <th>Day</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {bseHolidaysData
                                    ?.filter(
                                      (holiday) => holiday.day === "Sunday"
                                    )
                                    .map((holiday) => (
                                      <tr>
                                        <td>{holiday?.Purpose}</td>
                                        <td>{getDate(holiday?.HolidayDate)}</td>
                                        <td>{holiday?.day}</td>
                                      </tr>
                                    ))}
                                </tbody>
                              </table>
                            </div> */}
                            <div className="ipotable defaulttable">
                              <table class="table">
                                <thead>
                                  <tr>
                                    <th scope="col">Holidays</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Day</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>Dr. Baba Saheb Ambedkar Jayanti</td>
                                    <td>14-Apr-2024</td>
                                    <td>Sunday</td>
                                  </tr>
                                  <tr>
                                    <td>Shri Mahavir Jayanti</td>
                                    <td>21-Apr-2024</td>
                                    <td>Sunday</td>
                                  </tr>
                                  <tr>
                                    <td>Ganesh Chaturthi</td>
                                    <td>07-Sep-2024</td>
                                    <td>Saturday</td>
                                  </tr>
                                  <tr>
                                    <td>Dussehra</td>
                                    <td>12-Oct-2024</td>
                                    <td>Saturday</td>
                                  </tr>
                                  <tr>
                                    <td>Diwali-Balipratipada</td>
                                    <td>02-Nov-2024</td>
                                    <td>Saturday</td>
                                  </tr>
                                </tbody>
                              </table>
                              <small>
                                *The regular trading timings are from 9:15 am to
                                3:30 pm
                                <br />* The Muhurat Trading day timings will be
                                notified closer to Diwali by the Exchanges.
                              </small>
                            </div>
                          </div>
                          <div className="ipostn">
                            <div className="ipotitle">
                              <h4>BSE Holidays In January 2024</h4>
                            </div>
                            <div className="ipotable defaulttable">
                              <table class="table">
                                <thead>
                                  <tr>
                                    <th scope="col">Holidays</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Day</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {bseHolidaysData
                                    ?.filter(
                                      (holiday) =>
                                        getMonthIndex(holiday.HolidayDate) === 0
                                    )
                                    .map((holiday) => (
                                      <tr>
                                        <td>{holiday?.Purpose}</td>
                                        <td>{getDate(holiday?.HolidayDate)}</td>
                                        <td>{holiday?.day}</td>
                                      </tr>
                                    ))}
                                </tbody>
                              </table>{" "}
                            </div>
                          </div>
                          {/* <div className="ipostn">
                            <div className="ipotitle">
                              <h4>BSE Holidays In February 2024</h4>
                            </div>
                            <div className="ipotable defaulttable">
                              <table class="table">
                                <thead>
                                  <tr>
                                    <th scope="col">Holidays</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Day</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {bseHolidaysData
                                    ?.filter(
                                      (holiday) =>
                                        getMonthIndex(holiday.HolidayDate) === 1
                                    )
                                    .map((holiday) => (
                                      <tr>
                                        <td>{holiday?.Purpose}</td>
                                        <td>{getDate(holiday?.HolidayDate)}</td>
                                        <td>{holiday?.day}</td>
                                      </tr>
                                    ))}
                                </tbody>
                              </table>
                            </div>
                          </div> */}
                          <div className="ipostn">
                            <div className="ipotitle">
                              <h4>BSE Holidays In March 2024</h4>
                            </div>
                            <div className="ipotable defaulttable">
                              <table class="table">
                                <thead>
                                  <tr>
                                    <th scope="col">Holidays</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Day</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {bseHolidaysData
                                    ?.filter(
                                      (holiday) =>
                                        getMonthIndex(holiday.HolidayDate) === 2
                                    )
                                    .map((holiday) => (
                                      <tr>
                                        <td>{holiday?.Purpose}</td>
                                        <td>{getDate(holiday?.HolidayDate)}</td>
                                        <td>{holiday?.day}</td>
                                      </tr>
                                    ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div className="ipostn">
                            <div className="ipotitle">
                              <h4>BSE Holidays In April 2024</h4>
                            </div>
                            <div className="ipotable defaulttable">
                              <table class="table">
                                <thead>
                                  <tr>
                                    <th scope="col">Holidays</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Day</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {bseHolidaysData
                                    ?.filter(
                                      (holiday) =>
                                        getMonthIndex(holiday.HolidayDate) === 3
                                    )
                                    .map((holiday) => (
                                      <tr>
                                        <td>{holiday?.Purpose}</td>
                                        <td>{getDate(holiday?.HolidayDate)}</td>
                                        <td>{holiday?.day}</td>
                                      </tr>
                                    ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div className="ipostn">
                            <div className="ipotitle">
                              <h4>BSE Holidays In May 2024</h4>
                            </div>
                            <div className="ipotable defaulttable">
                              <table class="table">
                                <thead>
                                  <tr>
                                    <th scope="col">Holidays</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Day</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {bseHolidaysData
                                    ?.filter(
                                      (holiday) =>
                                        getMonthIndex(holiday.HolidayDate) === 4
                                    )
                                    .map((holiday) => (
                                      <tr>
                                        <td>{holiday?.Purpose}</td>
                                        <td>{getDate(holiday?.HolidayDate)}</td>
                                        <td>{holiday?.day}</td>
                                      </tr>
                                    ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div className="ipostn">
                            <div className="ipotitle">
                              <h4>BSE Holidays In June 2024</h4>
                            </div>
                            <div className="ipotable defaulttable">
                              <table class="table">
                                <thead>
                                  <tr>
                                    <th scope="col">Holidays</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Day</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {bseHolidaysData
                                    ?.filter(
                                      (holiday) =>
                                        getMonthIndex(holiday.HolidayDate) === 5
                                    )
                                    .map((holiday) => (
                                      <tr>
                                        <td>{holiday?.Purpose}</td>
                                        <td>{getDate(holiday?.HolidayDate)}</td>
                                        <td>{holiday?.day}</td>
                                      </tr>
                                    ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div className="ipostn">
                            <div className="ipotitle">
                              <h4>BSE Holidays In July 2024</h4>
                            </div>
                            <div className="ipotable defaulttable">
                              <table class="table">
                                <thead>
                                  <tr>
                                    <th scope="col">Holidays</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Day</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {bseHolidaysData
                                    ?.filter(
                                      (holiday) =>
                                        getMonthIndex(holiday.HolidayDate) === 6
                                    )
                                    .map((holiday) => (
                                      <tr>
                                        <td>{holiday?.Purpose}</td>
                                        <td>{getDate(holiday?.HolidayDate)}</td>
                                        <td>{holiday?.day}</td>
                                      </tr>
                                    ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div className="ipostn">
                            <div className="ipotitle">
                              <h4>BSE Holidays In August 2024</h4>
                            </div>
                            <div className="ipotable defaulttable">
                              <table class="table">
                                <thead>
                                  <tr>
                                    <th scope="col">Holidays</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Day</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {bseHolidaysData
                                    ?.filter(
                                      (holiday) =>
                                        getMonthIndex(holiday.HolidayDate) === 7
                                    )
                                    .map((holiday) => (
                                      <tr>
                                        <td>{holiday?.Purpose}</td>
                                        <td>{getDate(holiday?.HolidayDate)}</td>
                                        <td>{holiday?.day}</td>
                                      </tr>
                                    ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                          {/* <div className="ipostn">
                            <div className="ipotitle">
                              <h4>BSE Holidays In September 2024</h4>
                            </div>
                            <div className="ipotable defaulttable">
                              <table class="table">
                                <thead>
                                  <tr>
                                    <th scope="col">Holidays</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Day</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {bseHolidaysData
                                    ?.filter(
                                      (holiday) =>
                                        getMonthIndex(holiday.HolidayDate) === 8
                                    )
                                    .map((holiday) => (
                                      <tr>
                                        <td>{holiday?.Purpose}</td>
                                        <td>{getDate(holiday?.HolidayDate)}</td>
                                        <td>{holiday?.day}</td>
                                      </tr>
                                    ))}
                                </tbody>
                              </table>
                            </div>
                          </div> */}
                          <div className="ipostn">
                            <div className="ipotitle">
                              <h4>BSE Holidays In October 2024</h4>
                            </div>
                            <div className="ipotable defaulttable">
                              <table class="table">
                                <thead>
                                  <tr>
                                    <th scope="col">Holidays</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Day</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {bseHolidaysData
                                    ?.filter(
                                      (holiday) =>
                                        getMonthIndex(holiday.HolidayDate) === 9
                                    )
                                    .map((holiday) => (
                                      <tr>
                                        <td>{holiday?.Purpose}</td>
                                        <td>{getDate(holiday?.HolidayDate)}</td>
                                        <td>{holiday?.day}</td>
                                      </tr>
                                    ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div className="ipostn">
                            <div className="ipotitle">
                              <h4>BSE Holidays In November 2024</h4>
                            </div>
                            <div className="ipotable defaulttable">
                              <table class="table">
                                <thead>
                                  <tr>
                                    <th scope="col">Holidays</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Day</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {bseHolidaysData
                                    ?.filter(
                                      (holiday) =>
                                        getMonthIndex(holiday.HolidayDate) ===
                                        10
                                    )
                                    .map((holiday) => (
                                      <tr>
                                        <td>{holiday?.Purpose}</td>
                                        <td>{getDate(holiday?.HolidayDate)}</td>
                                        <td>{holiday?.day}</td>
                                      </tr>
                                    ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div className="ipostn">
                            <div className="ipotitle">
                              <h4>BSE Holidays In December 2024</h4>
                            </div>
                            <div className="ipotable defaulttable">
                              <table class="table">
                                <thead>
                                  <tr>
                                    <th scope="col">Holidays</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Day</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {bseHolidaysData
                                    ?.filter(
                                      (holiday) =>
                                        getMonthIndex(holiday.HolidayDate) ===
                                        11
                                    )
                                    .map((holiday) => (
                                      <tr>
                                        <td>{holiday?.Purpose}</td>
                                        <td>{getDate(holiday?.HolidayDate)}</td>
                                        <td>{holiday?.day}</td>
                                      </tr>
                                    ))}
                                </tbody>
                              </table>
                              <p>
                                *Additionally, it is important to keep in mind
                                that the BSE may declare additional holidays
                                throughout the year due to unforeseen
                                circumstances. In such cases, the exchange will
                                issue a circular notifying traders and investors
                                of the holiday.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="webcent">
            <div className="container">
              <div className="row">
                <div className="web-container">
                  <div className="title">
                    <h3>Frequently Asked Questions</h3>
                  </div>
                  <div className="faqcont">
                    <Accordion allowZeroExpanded="true">
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            What comprises the BSE holiday list?
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <div className="accordiancont">
                            <p>
                              The BSE holiday list encompasses the days when
                              this stock exchange in India suspends trading
                              activities.
                            </p>
                          </div>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            Do trading timings vary across different BSE trading
                            instruments?
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <div className="accordiancont">
                            <p>
                              Yes, trading timings indeed differ for various
                              instruments in BSE trading. The BSE comprises
                              different market segments, each with its distinct
                              trading hours. For instance, the Equity and Equity
                              Derivatives segments maintain different trading
                              timings compared to the Currency Derivatives
                              segment. To ascertain the trading hours for each
                              segment and instrument, it's essential to refer to
                              the BSE’s official website or seek guidance from a
                              financial advisor.
                            </p>
                          </div>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            Is it possible to buy or sell stocks on BSE
                            holidays?
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <div className="accordiancont">
                            <p>
                              No, you cannot, as the BSE remains closed on these
                              days.
                            </p>
                          </div>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            Where can I access the BSE holiday list?
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <div className="accordiancont">
                            <p>
                              The BSE holiday list is available on their
                              official website. Additionally, it can be found on
                              trading platforms, financial news websites, and
                              through market regulators.
                            </p>
                          </div>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            What is the significance of understanding the BSE
                            holiday list?
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <div className="accordiancont">
                            <p>
                              Understanding the BSE holiday list is crucial as
                              it enables investors, traders, and market
                              participants to effectively plan their trading
                              activities.
                            </p>
                          </div>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            What is the opening and closing times for the BSE?
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <div className="accordiancont">
                            <p>
                              The trading time in India runs from 9.15 am to
                              3.30 pm. Traders can buy and sell shares without
                              any restrictions during this time. A bilateral
                              order matching system is carried out during this
                              session, i.e., a sell order gets matched with a
                              buy order placed at the same price. The reverse
                              happens as well.
                            </p>
                          </div>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            Is trading available on weekends on the BSE?
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <div className="accordiancont">
                            <p>
                              No, trading is not available on weekends, which
                              include Saturday and Sunday, on the BSE.
                            </p>
                          </div>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            Do special trading hours occur during holidays?
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <div className="accordiancont">
                            <p>
                              Yes, on certain holidays, the BSE may implement
                              special trading hours or conduct half-day trading
                              sessions.
                            </p>
                          </div>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            What constitutes a settlement holiday?
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <div className="accordiancont">
                            <p>
                              A settlement holiday refers to a day or days when
                              clearing and settlement activities are not
                              conducted by the clearing corporations and stock
                              exchanges. Trades on the BSE settle on a T+1
                              basis, meaning the settlement occurs one working
                              day after the trade execution. Within the
                              settlement timeframe, clearing corporations and
                              stock exchanges verify trade details and allocate
                              funds to respective participants. Investors and
                              traders should consider settlement holidays when
                              planning trades to ensure they meet important
                              settlement dates and settle trades promptly.
                            </p>
                          </div>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            Does the BSE close every 2nd Saturday?
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <div className="accordiancont">
                            <p>
                              Yes, the BSE is closed on all Saturdays, including
                              the 2nd Saturday.
                            </p>
                          </div>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            Does the BSE observe closures for all bank holidays?
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <div className="accordiancont">
                            <p>
                              The BSE maintains its own holiday schedule,
                              announced well in advance. Therefore, it's
                              advisable to consult the official holiday list
                              released by the BSE to ascertain specific trading
                              holidays.
                            </p>
                          </div>
                        </AccordionItemPanel>
                      </AccordionItem>
                    </Accordion>
                    <p className="mt-3 d-block col-12 pull-left">
                      *Please note that the BSE Holiday List may change from
                      year to year and it is recommended to check for the most
                      up-to-date information from reliable sources before making
                      any trading decisions or planning your investment
                      activities. Always consult with a financial advisor for
                      personalised investment advice.
                    </p>
                    <br />
                    <p>Happy investing!</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
};

export default InvestHolidaysBSEHolidays;
