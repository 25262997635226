import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Footer from "../../../../components/Footer";
import { Form } from "../../../../components/Form";
import Header from "../../../../components/Header";
import CommonDialog from "../../../../components/common/CommonDialog";
import { dataExpense, dataRankings } from "../../../../utils/Data";
import { Context } from "../../../../context";
import { useContext } from "react";
import DematForm from "../../../../components/DematForm";
import MFHoldings from "./MFHoldings";
import AdvancedRatios from "./AdvancedRatios";
import MinInvAmt from "./MinInvAmt";
import PeerComparison from "./PeerComparison";
import ExpenseRatio from "./ExpenseRatio";
import FundManagement from "./FundManagement";
import ReturnsAndRankings from "./ReturnsAndRankings";
import MfGraphData from "./MfGraphData";
import MfDetails from "./MfDetails";
import FundName from "./FundName";
import HoldingAnalysis from "./HoldingAnalysis";
import MfEquitySectorAllocation from "./MfEquitySectorAllocation";
import MfReturnCalculator from "./MfReturnCalculator";
import MfNavDetails from "./MfNavDetails";
import MutualFaq from "./MutualFaq";
import MutualFundSEO from "../mutualFundSeo";
import { useLocation } from "react-router-dom";
import {
  getFundAssetAllocationData,
  getFundPlanBasicDetailsData,
  getFundReturnsData,
} from "../../../../utils/APICollection";

export const MFDetail = () => {
  const { id } = useParams();
  const [sidebarTop, setSidebarTop] = useState();
  const [shouldAddClass, setShouldAddClass] = useState(false);
  const [sidebarWidth, setSidebarWidth] = useState();
  const [currentTenure, setCurrentTenure] = useState("1m");
  const [assetAllocationDetailsData, setAssetAllocationDetailsData] = useState(
    []
  );
  const [fundReturnsData, setFundReturnsData] = useState({});
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const { fundDetails, setFundDetails } = useContext(Context);
  const location = useLocation();

  const isMobile = window.innerWidth <= 1040;

  const fetchFundPlanDetails = async () => {
    // setIsLoading(true);
    try {
      const res = await getFundPlanBasicDetailsData(
        window.location.pathname.split("/")[4]
      );
      setFundDetails(res?.data[0]);
      //setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchAssetAllocationDetails = async () => {
    try {
      const res = await getFundAssetAllocationData(
        window.location.pathname.split("/")[4]
      );

      setAssetAllocationDetailsData(res?.data);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchFundsReturns = async () => {
    try {
      const res = await getFundReturnsData(
        window.location.pathname.split("/")[4]
      );
      setFundReturnsData(res?.data[0]);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchFundPlanDetails();
    fetchAssetAllocationDetails();
    fetchFundsReturns();
  }, [location.pathname]);

  useEffect(() => {
    const sidebarEl = document.querySelector(".opendemat");

    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const shouldAdd = scrollTop >= sidebarTop - 10;

      setShouldAddClass(shouldAdd);
    };

    if (sidebarEl) {
      const sidebarRect = sidebarEl.getBoundingClientRect();
      setSidebarWidth(sidebarRect.width);
      setSidebarTop(sidebarRect.top);

      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, [sidebarTop]);

  useEffect(() => {
    if (!sidebarTop) return;

    const handleScroll = () => {
      const sidebarEl = document.querySelector(".opendemat");
      const sectionEl = document.querySelector(".ipodashboard");
      const faq_cnt = document.querySelector(".faqcont");

      const iporow = document.querySelector(".ipodashboard .row");
      const scrollTop = window.scrollY;
      const shouldAdd = scrollTop >= sidebarTop - 10;

      setShouldAddClass(shouldAdd);

      if (shouldAdd) {
        sidebarEl.classList.add("is-sticky");
      } else {
        sidebarEl.classList.remove("is-sticky");
      }

      if (
        scrollTop >=
        sectionEl.offsetTop +
          sectionEl.offsetHeight -
          faq_cnt.offsetHeight / 1.8
      ) {
        sidebarEl.classList.remove("is-sticky");
        iporow.classList.add("flex-end");
      } else {
        iporow.classList.remove("flex-end");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [sidebarTop]);

  const [showAll, setShowAll] = useState(false);

  // const executeScroll = () => myRef.current.scrollIntoView();
  // const closetoggleRows = () => {
  //   setShowAll(!showAll);
  //   executeScroll();
  // };

  return (
    <>
      <Header />

      <MutualFundSEO id={id} />

      <section id="banner">
        <div className="banner internal bauto">
          <div className="container">
            <div className="bredcrum">
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/invest">Invest</Link>
                </li>
                <li>
                  <Link to="/invest/mutual-fund">Mutual Funds</Link>
                </li>
                <li>{fundDetails?.basic_name}</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <main>
        <section id="main">
          <div className="ipoportfolio">
            <div className="container">
              <div className="web-container">
                <div className="ipodashboard">
                  <div className="row">
                    <div className="ipoleft ipo8box">
                      <div className="ipospace">
                        <div className="webcent">
                          <div className="ipostable">
                            <div className="tablisthead ipotable">
                              <ul>
                                <li>
                                  <div className="ipologo">
                                    <span>
                                      <img src={fundDetails?.amc_logo} alt="" />
                                    </span>
                                  </div>
                                </li>
                              </ul>
                            </div>
                            <div className="mutualfundoverview">
                              <FundName fundDetails={fundDetails} />
                              <div className="mfgraphchart">
                                <MfDetails fundDetails={fundDetails} />
                                <MfGraphData
                                  fundReturnsData={fundReturnsData}
                                  currentTenure={currentTenure}
                                  setCurrentTenure={setCurrentTenure}
                                />
                                {/* nav details */}
                                <MfNavDetails fundDetails={fundDetails} />
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* return calculator */}
                        <MfReturnCalculator fundReturnsData={fundReturnsData} />
                        {/* holdings */}
                        <MFHoldings />
                        <div className="webcent">
                          <div className="piecharts">
                            <HoldingAnalysis
                              fundDetails={fundDetails}
                              assetAllocationDetailsData={
                                assetAllocationDetailsData
                              }
                            />
                            {/* mf equity sector allocation */}
                            <MfEquitySectorAllocation
                              assetAllocationDetailsData={
                                assetAllocationDetailsData
                              }
                              fundDetails={fundDetails}
                            />
                            <AdvancedRatios />
                            <MinInvAmt fundDetails={fundDetails} />
                            {/* returns and rakings */}
                            <ReturnsAndRankings
                              fundDetails={fundDetails}
                              fundReturnsData={fundReturnsData}
                              setOpen={setOpen}
                            />
                            <PeerComparison fundDetails={fundDetails} />
                          </div>
                        </div>
                        <ExpenseRatio setOpen2={setOpen2} />
                        {/* fund management */}
                        <FundManagement />
                        {isMobile && (
                          <div style={{ width: "100%" }}>
                            <Form catID="MF" />
                          </div>
                        )}
                        <MutualFaq />
                      </div>
                    </div>

                    {!isMobile && (
                      <div className="ipoleft ipo4box">
                        <DematForm
                          addclassName={shouldAddClass}
                          sidebarWidth={sidebarWidth}
                          catID="MF"
                        />
                        {/* <Form catID="MF" /> */}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <CommonDialog
          open={open}
          setOpen={setOpen}
          data={dataRankings}
          dotShow={false}
        />
        <CommonDialog
          open={open2}
          setOpen={setOpen2}
          data={dataExpense}
          dotShow={true}
        />
      </main>
      <Footer />
    </>
  );
};
