import { getStockRatio } from "../../../../utils/APICollection";
import { useState, useEffect } from "react";
const StocksRatioOverview = ({ stockExchange }) => {
    const [ratiosData, setRatiosData] = useState({});
    const fetchStockRatioData = async () => {
        try {
            const data = await getStockRatio(window.location.pathname.split("/")[3]);
            setRatiosData(data?.data?.data[0]);
        } catch (err) {
            console.log(err);
        }
    };
    useEffect(() => {
        fetchStockRatioData();
    }, [window.location.pathname.split("/")[3]]);

    return (
        <div className="coprofilecont">
            <div className="tablisthead">
                <ul>
                    <li>{stockExchange?.company_name} Quick Overview</li>
                </ul>
            </div>
            <div className="ipoapplyfrm">
                <ul>
                    <li>
                        <span>P/E Ratio</span>
                        <span>
                            {ratiosData?.PE_TTM || "NA"}
                        </span>
                    </li>
                    <li>
                        <span>P/B Ratio</span>
                        <span>
                            {ratiosData?.PB_TTM?.toFixed(2) || "NA"}
                        </span>
                    </li>
                    <li>
                        <span>ROE</span>
                        <span>
                            {ratiosData?.ROE_TTM?.toFixed(2) || "NA"}
                        </span>
                    </li>
                    <li>
                        <span>ROCE</span>
                        <span>
                            {ratiosData?.ROCE_TTM?.toFixed(2) || "NA"}
                        </span>
                    </li>
                    <li>
                        <span>Dividend Yield</span>
                        <span>{ratiosData?.DividendYield?.toFixed(2) || "NA"}</span>
                    </li>
                    <li>
                        <span>Debt to equity</span>
                        <span>{ratiosData?.DebttoEquity?.toFixed(2) || "NA"}</span>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default StocksRatioOverview;