import React, { useContext, useEffect, useState, useRef } from "react";
import { NavLink, Link } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
import { Logo } from "./Logo";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { Login_Drop } from "../navbar/Login_Drop";
import { InvestDrop } from "../navbar/InvestDrop";
import { Tradepro_Drop } from "../navbar/Tradepro_Drop";
import { Product_Drop } from "../navbar/Product_Drop";
import { Research_Drop } from "../navbar/Research_Drop";
import { Learn_Drop } from "../navbar/Learn_Drop";
import { Partner_Drop } from "../navbar/Partner_Drop";
import { Searchbox } from "./Searchbox";
import { Context } from "../context";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

export const Header = () => {
  const [menus, setActive] = useState("false");
  const [header, setNavbar] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { setName, setNum, setEmail, setState, setCity } = useContext(Context);

  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    Aos.init({ duration: 1000 });
    window.addEventListener("scroll", changeBackground);

    return () => {
      window.removeEventListener("scroll", changeBackground);
    };
  }, []);

  const changeBackground = () => {
    if (window.scrollY >= 80) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  const handleToggle = () => {
    setActive(!menus);
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const hoverClass = isHovered ? "hover" : "";

  const emptyField = () => {
    setCity("");
    setEmail("");
    setName("");
    setState("");
    setNum("");
    setActive(!menus);
    setIsOpen(false);
  };

  const isEdgeBrowser = navigator.userAgent.indexOf("Edg") !== -1;
  return (
    <>
      <div>
        <div class="notice">
          <a
            href=" https://images.smctradeonline.com:6443/img/testing-page/smc-telegram-channel-advisory-for-investors-2/"
            target="_blank"
            style={{
              color: "white",
              marginTop: ".2rem",
            }}
          >
            Online Fraud Public Notice
          </a>
          <ArrowForwardIosIcon
            style={{ width: ".9rem", marginLeft: "0.5rem" }}
          />
        </div>
      </div>

      <header className={`${hoverClass}`}>
        <div className={header ? "header fixed" : "header"}>
          <div className="container">
            <div className="row">
              <div className="headp">
                <div className={menus ? "logo" : "logo active"}>
                  <NavLink
                    to="/"
                    onClick={() => {
                      setCity("");
                      setEmail("");
                      setName("");
                      setState("");
                      setNum("");
                    }}
                  >
                    <Logo />
                  </NavLink>
                </div>
                <div className="navbar">
                  <div className={menus ? "menus" : "menus active"}>
                    <div
                      className="menu1 desktop-show"
                      style={{ marginRight: isEdgeBrowser ? "-45px" : "0px" }}
                    >
                      <ul>
                        <li
                          onMouseEnter={handleMouseEnter}
                          onMouseLeave={handleMouseLeave}
                        >
                          <NavLink
                            to="/invest"
                            // onClick={() => {
                            //   setIsHovered(false);
                            // }}
                          >
                            Invest
                          </NavLink>
                          <div className="dropdown">
                            <div className="submenu">
                              <div className="container">
                                <div className="web-container">
                                  <InvestDrop
                                    setActive={setActive}
                                    setIsOpen={setIsOpen}
                                    menus={menus}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li
                          onMouseEnter={handleMouseEnter}
                          onMouseLeave={handleMouseLeave}
                        >
                          <NavLink to="/trade" onClick={emptyField}>
                            Trade
                          </NavLink>

                          <div className="dropdown">
                            <div className="submenu">
                              <div className="container">
                                <div className="web-container">
                                  <Tradepro_Drop
                                    // setActive={setActive}
                                    setIsOpen={setIsOpen}
                                    menus={menus}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li
                          onMouseEnter={handleMouseEnter}
                          onMouseLeave={handleMouseLeave}
                        >
                          <Link style={{ cursor: "context-menu" }}>
                            Products
                          </Link>
                          <div className="dropdown">
                            <div className="submenu">
                              <div className="container">
                                <div className="web-container">
                                  <Product_Drop
                                    setActive={setActive}
                                    setIsOpen={setIsOpen}
                                    menus={menus}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li
                          onMouseEnter={handleMouseEnter}
                          onMouseLeave={handleMouseLeave}
                        >
                          <NavLink to="/research" onClick={emptyField}>
                            Research
                          </NavLink>
                          <div className="dropdown">
                            <div className="submenu">
                              <div className="container">
                                <div className="web-container">
                                  <Research_Drop
                                    setActive={setActive}
                                    setIsOpen={setIsOpen}
                                    menus={menus}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li
                          onMouseEnter={handleMouseEnter}
                          onMouseLeave={handleMouseLeave}
                        >
                          <Link style={{ cursor: "context-menu" }}>Learn</Link>

                          <div className="dropdown">
                            <div className="submenu">
                              <div className="container">
                                <div className="web-container">
                                  <Learn_Drop
                                    setActive={setActive}
                                    setIsOpen={setIsOpen}
                                    menus={menus}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li
                          onMouseEnter={handleMouseEnter}
                          onMouseLeave={handleMouseLeave}
                        >
                          <Link style={{ cursor: "context-menu" }}>
                            Partner Us
                          </Link>

                          <div className="dropdown">
                            <div className="submenu">
                              <div className="container">
                                <div className="web-container">
                                  <Partner_Drop
                                    setActive={setActive}
                                    setIsOpen={setIsOpen}
                                    menus={menus}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li>
                          <NavLink to="/contact-us" onClick={emptyField}>
                            Contact Us
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                    <div className="menu2 menu1  desktop-show">
                      <ul>
                        <li>
                          <Searchbox />
                        </li>
                        <li>
                          <NavLink to="/demat-account" onClick={emptyField}>
                            open demat account
                          </NavLink>
                        </li>
                        <li
                          className="login_dropdown desktop-show"
                          onMouseEnter={handleMouseEnter}
                          onMouseLeave={handleMouseLeave}
                        >
                          <a style={{ cursor: "context-menu" }}>LOGIN </a>
                          <div className="dropdown">
                            <div className="submenu">
                              <div className="container">
                                <div className="web-container">
                                  <Login_Drop
                                    setActive={setActive}
                                    setIsOpen={setIsOpen}
                                    menus={menus}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                    {/* Mobile menus */}
                    <div className="mobilemenus_accordian mobile-show">
                      <Accordion allowZeroExpanded="true">
                        <AccordionItem>
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              <NavLink to="/invest" onClick={emptyField}>
                                Invest
                              </NavLink>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <InvestDrop
                              setActive={setActive}
                              setIsOpen={setIsOpen}
                              menus={menus}
                            />
                          </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              <NavLink to="/trade" onClick={emptyField}>
                                Trade
                              </NavLink>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <Tradepro_Drop
                              setActive={setActive}
                              setIsOpen={setIsOpen}
                              menus={menus}
                            />
                          </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                          <AccordionItemHeading>
                            <AccordionItemButton>Products</AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <Product_Drop
                              setActive={setActive}
                              setIsOpen={setIsOpen}
                              menus={menus}
                            />
                          </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              <NavLink to="/research" onClick={emptyField}>
                                Research
                              </NavLink>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <Research_Drop
                              setActive={setActive}
                              setIsOpen={setIsOpen}
                              menus={menus}
                            />
                          </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                          <AccordionItemHeading>
                            <AccordionItemButton>Learn</AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <Learn_Drop
                              setActive={setActive}
                              setIsOpen={setIsOpen}
                              menus={menus}
                            />
                          </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              Partner Us
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <Partner_Drop
                              setActive={setActive}
                              setIsOpen={setIsOpen}
                              menus={menus}
                            />
                          </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                          <AccordionItemHeading>
                            <AccordionItemButton>Login</AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <Login_Drop
                              setActive={setActive}
                              setIsOpen={setIsOpen}
                              menus={menus}
                            />
                          </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem className="noicons">
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              <NavLink to="/demat-account" onClick={emptyField}>
                                Open Demat Account
                              </NavLink>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                        </AccordionItem>
                        <AccordionItem className="noicons">
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              <NavLink to="/contact-us" onClick={emptyField}>
                                Contact Us
                              </NavLink>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                        </AccordionItem>
                      </Accordion>
                    </div>
                    {/* End */}
                  </div>

                  <div
                    className={menus ? "hemburgurstn" : "hemburgurstn active"}
                  >
                    <div className=" menu1 mobile-show">
                      <div
                        // onClick={() => setIsOpen(true)}
                        className={
                          isOpen
                            ? " mobilesearchlist active"
                            : "mobilesearchlist"
                        }
                      >
                        <Searchbox setIsOpen={setIsOpen} isOpen={isOpen} />
                      </div>
                    </div>
                    <button
                      onClick={handleToggle}
                      className={menus ? "hemburgur" : "hemburgur active"}
                    >
                      <span></span>
                      <span></span>
                      <span></span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      {/* ipo button */}
      <div
        className="fixbutton"
        // onClick={handleIpo}
        style={{ cursor: "pointer" }}
      >
        <div
          className="mbtn"
          onClick={() => window.open(`https://ipo.smcindiaonline.org:8327/ipo`)}
        >
          <div className="btnn">
            <span>Apply Ipo</span>
          </div>
        </div>
      </div>
      {/* ipo button */}
    </>
  );
};
export default Header;
