import { useState } from "react";
import { formatNumber } from "../../../../components/common";
const StockSimilar = ({ marketData }) => {
    const [showAllStock, setShowAllStock] = useState(false);
    const toggleStock = () => {
        setShowAllStock(!showAllStock);
        // setShowAll(false);
    };
    return (
        <div className="webcent">
            <div className="returncalulator similarstock">
                <div className="calheading">
                    <h3>Similar Stocks</h3>
                </div>
                <div className="tablist">
                    <div className="ipostn">
                        {(Array.isArray(marketData) && marketData.length > 0) ? (
                            <div className="ipotable Similar_stock_cnt">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">COMPANY</th>
                                            {/* <th scope="col">52 WEEK</th> */}
                                            <th scope="col">MARKET PRICE</th>
                                            <th scope="col">P/E RATIO </th>
                                            {/* <th scope="col"></th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {marketData
                                            ?.slice(0, 5)
                                            ?.map((data) => (
                                                <tr>
                                                    <td>{data?.CompanyName}</td>
                                                    {/* 52 week slider dat will be used in future */}
                                                    {/* <td> */}
                                                    {/* <SliderPN value="0.96" /> */}
                                                    {/* </td> */}
                                                    <td>
                                                        <i className="finr"></i>
                                                        {formatNumber(data?.LTP)}

                                                        {/* <span className="positive">
                                                  {" "}
                                                  NA
                                                </span> */}
                                                    </td>
                                                    <td>{data?.PE}</td>
                                                    {/* <td>
                                          <i className="fa fa-bookmark-o"></i>
                                        </td> */}
                                                </tr>
                                            ))}

                                        {showAllStock &&
                                            marketData
                                                ?.slice(6, marketData?.length)
                                                ?.map((data) => (
                                                    <tr>
                                                        <td>{data?.CompanyName}</td>
                                                        {/* 52 week slider dat will be used in future */}
                                                        {/* <td> */}
                                                        {/* <SliderPN value="0.96" /> */}
                                                        {/* </td> */}
                                                        <td>
                                                            <i className="finr">
                                                                {formatNumber(data?.LTP)}
                                                            </i>
                                                            {/* <span className="positive">
                                                  {" "}
                                                  NA
                                                </span> */}
                                                        </td>
                                                        <td>{data?.PE}</td>
                                                        {/* <td>
                                          <i className="fa fa-bookmark-o"></i>
                                        </td> */}
                                                    </tr>
                                                ))}
                                        {false && (
                                            <>
                                                <tr>
                                                    <td>LTIMindtree</td>
                                                    <td>
                                                        {/* <SliderPN value="70" /> */}
                                                    </td>
                                                    <td>
                                                        4,419.45
                                                        <span className="positive">
                                                            (+2.30%)
                                                        </span>
                                                    </td>
                                                    <td>29.29</td>
                                                    <td>
                                                        <i
                                                            className="fa fa-bookmark-o"
                                                            style={{
                                                                color: "rgb(147 192 109)",
                                                                fontSize: "20px",
                                                            }}
                                                            aria-hidden="true"
                                                        ></i>
                                                    </td>
                                                </tr>
                                            </>
                                        )}
                                    </tbody>
                                </table>

                                <button
                                    className="showallcont"
                                    onClick={toggleStock}
                                >
                                    {!showAllStock ? "See All" : "See Less"}
                                </button>
                            </div>
                        ) :
                            (
                                <div className="ipotable Similar_stock_cnt">Similar stock Not available</div>
                            )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StockSimilar;