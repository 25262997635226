import { ThreeDots } from "react-loader-spinner";
import { formatNumber } from "../../../../components/common";
const IpoOngoing = ({ isLoading, openIpoData, subscriptionDetails, ipoTimeline, isMobile }) => {
    return (
        <>
            {isLoading ? (
                <ThreeDots
                    height="90"
                    width="90"
                    radius="10"
                    color="#3AA29F"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                    // wrapperClassName=""
                    visible={true}
                />
            ) : (
                <div className="ipostn">
                    <div className="ipotitle">
                        <h4>Open Now</h4>
                    </div>
                    <div className="ipotitle align-items-end">
                        <div className="titleleft">
                            <h5>Ongoing IPOs</h5>
                            {/* <p>
                                      New to IPO investing? Learn more about
                                      applying to IPO
                                    </p> */}
                        </div>
                        <div className="shareprice">
                            <h2>
                                {subscriptionDetails?.IssueSizeTo ===
                                    0 ? (
                                    <strong>
                                        <em className="finr"></em>
                                        {subscriptionDetails?.PriceFixed}
                                    </strong>
                                ) : (
                                    <strong>
                                        <em className="finr"></em>
                                        {/* {openIPOStockDeatils[0].priceRange} */}
                                        {
                                            subscriptionDetails?.PriceRangeFrom
                                        }
                                        -<em className="finr"></em>
                                        {subscriptionDetails?.PriceRangeTo}
                                    </strong>
                                )}

                                <p>per share</p>
                            </h2>
                        </div>
                    </div>
                    <br />
                    <div
                        className={
                            isMobile
                                ? "ipotable defaulttable"
                                : "ipotable"
                        }
                    >
                        <table
                            class="table"
                            style={{ width: isMobile && "700px" }}
                        >
                            <thead>
                                <tr>
                                    <th scope="col">Bidding Dates</th>
                                    <th scope="col">Min. Investment</th>
                                    <th scope="col">Lot size</th>
                                    <th scope="col">Issue Size</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        {/* <strong className="mobile-show">
                                              Bidding Dates
                                            </strong> */}
                                        {/* {openIPOStockDeatils[0].date} */}
                                        {ipoTimeline?.ipostartdate +
                                            "-" +
                                            ipoTimeline?.ippenddate}
                                        {/* {getDate(registarInfo?.OPENDATE) + "-" + getDate(registarInfo?.CLOSDATE) + " " + getMonth(registarInfo?.OPENDATE)} */}
                                    </td>
                                    <td>
                                        {/* <strong className="mobile-show">
                                              Min. Investment
                                            </strong> */}
                                        <i className="finr"></i>{" "}
                                        {formatNumber(
                                            openIpoData?.mininvestment
                                        )}
                                        {/* {formatNumber(
                                            openIPOStockDeatils[0].minAmount
                                          )} */}
                                    </td>
                                    <td>
                                        {/* <strong className="mobile-show">
                                              Lot size
                                            </strong> */}
                                        {/* {formatNumber(
                                            openIPOStockDeatils[0].lotSize
                                          )}{" "} */}
                                        {formatNumber(
                                            openIpoData?.MIN_APPLN
                                        ) === "NaN"
                                            ? "NA"
                                            : formatNumber(
                                                openIpoData?.MIN_APPLN
                                            )}{" "}
                                        Shares
                                    </td>
                                    <td>
                                        {/* <strong className="mobile-show">
                                              Issue Size
                                            </strong> */}
                                        <i className="finr"></i>{" "}
                                        {
                                            openIpoData?.IssueSize?.split("-")[1] === undefined
                                                ? openIpoData?.IssueSize
                                                : formatNumber(openIpoData?.IssueSize?.split("-")[1]) === "NaN"
                                                    ? "NA"
                                                    : formatNumber(openIpoData?.IssueSize?.split("-")[1])
                                        }
                                        Cr
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            )}
        </>
    )
}

export default IpoOngoing;