import { ThreeDots } from "react-loader-spinner";
import { Avatar, Box } from "@mui/material";
import { stringAvatar } from "../../../components/common/avatar";
import { useContext } from "react";
import { Context } from "../../../context";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { getOpenIpo } from "../../../utils/APICollection";

const OpenIpo = () => {
  const [openIpoLoading, setOpenIpoLoading] = useState(false);
  const { exchange, openIpo, setOpenIpo } = useContext(Context);
  const isMobile = window.innerWidth <= 1040;
  const navigate = useNavigate();

  const getMonth = (date) => {
    const d = new Date(date);
    const monthList = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    return monthList[d.getMonth()];
  };

  const getFullDate = (date) => {
    const d = new Date(date);
    return d.getDate() + " " + getMonth(date) + " " + d.getFullYear();
  };

  const handleStockDetails = (id) => {
    if (id) {
      navigate(`/invest/ipo/ipodetails/${id}`);
    }
  };

  const fetchOpenIpo = async () => {
    setOpenIpoLoading(true);
    try {
      const data = await getOpenIpo(exchange);
      setOpenIpo(data?.data?.data);
    } catch (error) {
      console.log(error);
    } finally {
      setOpenIpoLoading(false);
    }
  };

  useEffect(() => {
    fetchOpenIpo();
  }, [exchange]);
  return (
    <div className="ipostn">
      <div className="ipotitle titleipo">
        <h4>Open</h4>
      </div>

      <div className={isMobile ? "ipotable defaulttable" : "ipotable"}>
        <table
          className="table"
          // style={{ width: isMobile && "550px" }}
        >
          <thead>
            <tr>
              <th scope="col" className={isMobile ? "mheadname" : ""}>
                Companies
              </th>
              <th scope="col" className={isMobile ? "mheadname" : ""}>
                Closes On{" "}
              </th>
              <th scope="col" className={isMobile ? "mheadname" : ""}>
                Price Range
              </th>
              {/* <th
                                            scope="col"
                                            className={
                                              isMobile ? "mheadname" : ""
                                            }
                                          ></th> */}
            </tr>
          </thead>

          <tbody>
            {openIpoLoading ? (
              <ThreeDots
                height="90"
                width="90"
                radius="10"
                color="#3AA29F"
                ariaLabel="three-dots-loading"
                wrapperStyle={{
                  display: "flex",
                  justifyContent: "center",
                }}
                // wrapperClassName=""
                visible={true}
              />
            ) : openIpo == null ? (
              <tr>
                <td scope="row">Currently There is No Ipo</td>
              </tr>
            ) : (
              openIpo?.map((stock) => {
                return (
                  <tr>
                    <td scope="row">
                      <Box>
                        <div className="ipologo">
                          <Avatar
                            sx={{
                              background: "#405cb2",
                              width: 36,
                              height: 36,
                              mr: 2,
                            }}
                            {...stringAvatar(stock.lname)}
                          />
                          <div
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={() => handleStockDetails(stock.CO_CODE)}
                          >
                            {/* <Tooltip
                                                      title={stock.Lname}
                                                      placement="right-start"
                                                      arrow
                                                    > */}
                            {/* <div className="ellipsis"> */}
                            {stock.lname}
                            {/* </div> */}
                            {/* </Tooltip> */}
                            <strong>{/* {stock.companySymbol} */}</strong>
                          </div>
                        </div>
                      </Box>
                    </td>
                    <td scope="row"> {getFullDate(stock?.CLOSDATE)}</td>
                    <td scope="row">
                      <i className="finr">{stock?.ISSUEPRICE}</i>
                      {" - "}
                      <i className="finr">
                        {stock?.ISSUEPRI2 === 0
                          ? stock?.ISSUEPRICE
                          : stock?.ISSUEPRI2}
                      </i>
                    </td>
                    {/* <td scope="row">
                                                  <div>
                                                    <div
                                                      onClick={() =>
                                                        handleApply(stock?.isin)
                                                      }
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                      className="btnn col-12 text-center"
                                                    >
                                                      Apply
                                                    </div>
                                                  </div>
                                                </td> */}
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default OpenIpo;
