import React, { useState, useEffect } from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { Link } from "react-router-dom";
import { Filter } from "./components/Filter";
import { Cross } from "./images/svg/Cross";
import { Mfilter } from "./components/Mfilter";
import { Back } from "./images/svg/Back";
import Aos from "aos";
import { Updown } from "./images/svg/Updown";
import { ThreeDots } from "react-loader-spinner";
import PaginationComponent from "./components/common/PaginationComponent";
import { NavLink } from "react-router-dom";
import { Context } from "./context";
import { useContext } from "react";
import {
  getAllAmc,
  getAllFunds,
  getAllRisk,
  getFundsCategory,
  getFundsPlanByCategory,
} from "./utils/APICollection";

export const CategoryFiter = () => {
  const [allAmcData, setAllAmcData] = useState([]);
  const [fundsCategory, setFundsCategory] = useState([]);

  const [allFundsData, setAllFundsData] = useState([]);
  const [allFundsDataCopy, setAllFundsDataCopy] = useState([]);
  const [amcFilters, setAmcFilters] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [investData, setInvestData] = useState([
    { name: "Sip", flag: "true" },
    { name: "One Time", flag: "false" },
  ]);
  const [sipFilters, setSipFilters] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [fundSize, setFundSize] = useState([
    { name: "Upto 1000 Cr", value: 1000 },
    { name: "Upto 2000 Cr", value: 2000 },
    { name: "Upto 3000 Cr", value: 3000 },
    { name: "Upto 5000 Cr", value: 5000 },
  ]);
  const [fundSizefilters, setFundSizeFilters] = useState([]);
  const [sortingOrder, setSortingOrder] = useState("");
  const [applyFilter, setApplyFilter] = useState(false);
  const sortingList = ["Ratings - High To Low", "Returns - High To Low"];

  const [isOpen, setIsOpen] = useState(false);
  const handleDropdownClick = (e) => {
    if (e.target.className === "dropdown-toggle") {
      setIsOpen(!isOpen);
    } else {
      setIsOpen(false);
    }
  };
  const [isClicked, setIsClicked] = useState(false);
  const handleButtonClick = () => {
    setIsClicked(true);
  };
  const handleClearClick = () => {
    setSelectedFilters([]);
    setAmcFilters([]);
    setRiskFilters([]);
    setFundSizeFilters([]);
    setSipFilters([]);
  };
  const goBack = () => {
    setIsClicked(false);
  };
  useEffect(() => {
    Aos.init({ duration: 1000 });
    window.addEventListener("scroll", changeBackground);

    return () => {
      window.removeEventListener("scroll", changeBackground);
    };
  }, []);
  const [fixfilter, setNavbar] = useState(false);
  const changeBackground = () => {
    if (window.scrollY >= 100) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  const riskMapping = {
    1: "Low Risk",
    2: "Medium Risk",
    3: "High Risk",
    4: "Low Risk",
    5: "Moderately Low Risk",
    6: "Moderate Risk",
    7: "Moderately High Risk",
    8: "High Risk",
    9: "Very High Risk",
    10: "Low To Moderate Risk",
  };
  const {
    selectedFilters,
    setSelectedFilters,
    allRiskData,
    setAllRiskData,
    riskFilters,
    setRiskFilters,
  } = useContext(Context);

  const fetchAllAmc = async () => {
    try {
      const res = await getAllAmc();
      setAllAmcData(res?.data);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchFundsCategory = async () => {
    try {
      const res = await getFundsCategory();
      const result = res?.data?.data.map((category) => ({
        ...category,
        checked: false,
      }));
      setFundsCategory(result);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchPlansByCategory = async (name) => {
    try {
      const res = await getFundsPlanByCategory(name);
      const result = fundsCategory.map((category) =>
        category?.primary_category_name === name
          ? { ...category, holdings: res?.data.map((item) => item?.basic_name) }
          : category
      );
      setFundsCategory(result);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchAllFunds = async () => {
    setIsLoading(true);
    try {
      const res = await getAllFunds();
      const result = res?.data.map((item) => ({
        ...item,
        sip: item.sip.toString(),
      }));
      setAllFundsData(result);
      setAllFundsDataCopy(result);
      setApplyFilter(true);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchAllRisks = async () => {
    try {
      const res = await getAllRisk();
      const frequencyMap = {};
      res?.data.forEach((item) => {
        const { colour_id, colour_name } = item;
        if (!frequencyMap[colour_name]) {
          frequencyMap[colour_name] = [];
        }
        frequencyMap[colour_name].push(parseInt(colour_id));
      });

      const result = [];
      for (const [name, frequency] of Object.entries(frequencyMap)) {
        result.push({ name, frequency });
      }
      setAllRiskData(result);
    } catch (err) {
      console.log(err);
    }
  };

  const removeFilter = (name) => {
    const result = selectedFilters.filter((item) => item !== name);
    setSelectedFilters(result);
  };
  const removeAmcFilter = (amcId) => {
    const result = amcFilters.filter((item) => item !== amcId);
    setAmcFilters(result);
  };

  const removeFundSizeFilters = (fundSizeValue) => {
    const result = fundSizefilters.filter((item) => item !== fundSizeValue);
    setFundSizeFilters(result);
  };

  const removeSipFormat = (investmentFormat) => {
    const result = sipFilters.filter((item) => item !== investmentFormat);
    setSipFilters(result);
  };

  const removeRiskFilters = (filterName) => {
    if (Array.isArray(filterName)) {
      const filteredArray = riskFilters.filter((item) => {
        if (Array.isArray(item)) {
          return JSON.stringify(item) !== JSON.stringify(filterName);
        }
        return true;
      });
      setRiskFilters(filteredArray);
    } else {
      const filteredArray = riskFilters.filter((item) => item !== filterName);
      setRiskFilters(filteredArray);
    }
  };

  function findNameByFrequency(frequencyToFind) {
    for (const item of allRiskData) {
      if (JSON.stringify(item.frequency) === JSON.stringify(frequencyToFind)) {
        return item.name;
      }
      if (item.frequency.includes(frequencyToFind)) {
        return item.name;
      }
    }
    return null;
  }

  useEffect(() => {
    if (applyFilter) {
      let filterData = [...allFundsData];
      if (selectedFilters.length > 0) {
        filterData = filterData.filter((item) =>
          selectedFilters.includes(item?.asset_class)
        );
      }
      if (amcFilters.length > 0) {
        filterData = filterData.filter((item) =>
          amcFilters.includes(item?.amc_id)
        );
      }
      if (riskFilters.length > 0) {
        filterData = filterData.filter((item) =>
          riskFilters.flat().includes(item?.colour)
        );
      }
      if (sipFilters.length > 0) {
        filterData = filterData.filter((item) =>
          sipFilters.includes(item?.sip)
        );
      }
      if (fundSizefilters.length > 0) {
        filterData = filterData.filter((item) =>
          fundSizefilters.some((value) => Number(item.aum) < value)
        );
      }
      if (sortingOrder.length > 0) {
        if (sortingOrder === "Ratings - High To Low") {
          filterData = filterData.sort((a, b) => {
            if (a.fund_rating === null || a.fund_rating === "Not Rated")
              return 1;
            if (b.fund_rating === null || b.fund_rating === "Not Rated")
              return -1;
            return parseInt(b.fund_rating) - parseInt(a.fund_rating);
          });
        } else if (sortingOrder === "Returns - High To Low") {
          filterData = filterData.sort((a, b) => {
            if (a.ret_1year === null || a.ret_1year === "Not Rated") return 1;
            if (b.ret_1year === null || b.ret_1year === "Not Rated") return -1;
            return parseInt(b.ret_1year) - parseInt(a.ret_1year);
          });
        }
      }
      setAllFundsDataCopy(filterData);
      setCurrentPage(1);
    }
  }, [
    selectedFilters,
    amcFilters,
    riskFilters,
    sipFilters,
    fundSizefilters,
    sortingOrder,
    applyFilter,
  ]);

  useEffect(() => {
    fetchAllAmc();
    fetchFundsCategory();
    fetchAllRisks();
    fetchAllFunds();
    setSortingOrder("Returns - High To Low");
  }, []);
  const isMobile = window.innerWidth <= 540;
  return (
    <>
      <Header />
      <section id="banner" onClick={handleDropdownClick}>
        <div className="banner internal bauto">
          <div className="container">
            <div className="bredcrum">
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/invest">Invest</Link>
                </li>
                <li>
                  <Link to="/invest/mutual-fund">Mutual Funds</Link>
                </li>
                <li>All Mutual Funds</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <main
        className={`${isClicked ? "zindex" : ""}`}
        onClick={handleDropdownClick}
      >
        <section id="main">
          <div className="webcent">
            <div className="container">
              <div className="row">
                <div className="web-container">
                  <div className="title justify-content-start">
                    <h3>All Mutual Funds</h3>
                  </div>
                  <div className="mobile-filter mobile-show">
                    <div
                      className={fixfilter ? "fixfilter fixed" : "fixfilter"}
                    >
                      <div className="filter d-flex justify-content-between align-items-center">
                        <h5
                          className="mobilefiltertag m-0"
                          onClick={handleButtonClick}
                        >
                          Filter-sort
                        </h5>
                        <h6 className="m-0">
                          <span>Returns 3Y</span> <Updown />
                        </h6>
                      </div>
                    </div>
                    <div
                      className={`mobilefilter ${
                        isClicked ? "Showfilter" : ""
                      }`}
                    >
                      <div className="filtertabs col-12 pull-left">
                        <div className="filterheader">
                          <div className="filterhead">
                            <h5 onClick={() => goBack()}>
                              {" "}
                              <span className="goback">
                                <i className="backarrow opacity-100 visible ml-0">
                                  <Back />
                                </i>
                              </span>
                              Filter
                            </h5>
                            <span onClick={handleClearClick}>Clear All</span>
                          </div>
                        </div>
                        <Mfilter
                          allAmcData={allAmcData}
                          fundsCategory={fundsCategory}
                          setFundsCategory={setFundsCategory}
                          fetchPlansByCategory={fetchPlansByCategory}
                          allFundsData={allFundsData}
                          setAllFundsData={setAllFundsData}
                          allFundsDataCopy={allFundsDataCopy}
                          setAllFundsDataCopy={setAllFundsDataCopy}
                          amcFilters={amcFilters}
                          setAmcFilters={setAmcFilters}
                          investData={investData}
                          setInvestData={setInvestData}
                          sipFilters={sipFilters}
                          setSipFilters={setSipFilters}
                          searchText={searchText}
                          setSearchText={setSearchText}
                          fundSize={fundSize}
                          setFundSize={setFundSize}
                          fundSizefilters={fundSizefilters}
                          setFundSizeFilters={setFundSizeFilters}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="ipodashboard">
                    <div className="row">
                      <div className="ipoleft ipo4box pl-0">
                        {!isMobile && (
                          <div className="filter">
                            <div className="filterhead">
                              <h5>Filter</h5>
                              <span
                                style={{ cursor: "pointer" }}
                                onClick={() => handleClearClick()}
                              >
                                Clear All
                              </span>
                            </div>
                            <div className="filterbody">
                              <Filter
                                allAmcData={allAmcData}
                                fundsCategory={fundsCategory}
                                setFundsCategory={setFundsCategory}
                                fetchPlansByCategory={fetchPlansByCategory}
                                allFundsData={allFundsData}
                                setAllFundsData={setAllFundsData}
                                allFundsDataCopy={allFundsDataCopy}
                                setAllFundsDataCopy={setAllFundsDataCopy}
                                amcFilters={amcFilters}
                                setAmcFilters={setAmcFilters}
                                investData={investData}
                                setInvestData={setInvestData}
                                sipFilters={sipFilters}
                                setSipFilters={setSipFilters}
                                searchText={searchText}
                                setSearchText={setSearchText}
                                fundSize={fundSize}
                                setFundSize={setFundSize}
                                fundSizefilters={fundSizefilters}
                                setFundSizeFilters={setFundSizeFilters}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="ipoleft ipo8box">
                        <div className="tablehead d-flex justify-content-between align-items-center">
                          <div className="result">
                            Search Results :{" "}
                            <strong>
                              {allFundsDataCopy.length} Mutual Funds
                            </strong>
                          </div>
                          <div className="expiryfilter">
                            <div className="dropdown-container">
                              <span
                                onClick={handleDropdownClick}
                                className="dropdown-toggle"
                              >
                                Sort by <span>{sortingOrder}</span>
                              </span>
                              {isOpen && (
                                <div className="dropdown-content">
                                  <div class="to-dos listing col-12 pull-left">
                                    {sortingList.map((order) => (
                                      <div class="to-dos-entry listingrow">
                                        <label
                                          for="Popularity"
                                          class="label-text"
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                            // handleDropdownClick();
                                            setSortingOrder(order);
                                          }}
                                        >
                                          {order}
                                        </label>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="tabletags d-flex gap-2 flex-wrap mt-2">
                          {selectedFilters.map((name) => (
                            <div className="ttag">
                              <a>
                                <span>
                                  {name}{" "}
                                  <Cross onClick={() => removeFilter(name)} />
                                </span>
                              </a>
                            </div>
                          ))}
                          {amcFilters.map((amcId) => (
                            <div className="ttag">
                              <a>
                                <span>
                                  {
                                    allAmcData.filter(
                                      (item) => item.amc_id === amcId
                                    )[0]?.amc_full_name
                                  }{" "}
                                  <Cross
                                    onClick={() => removeAmcFilter(amcId)}
                                  />
                                </span>
                              </a>
                            </div>
                          ))}
                          {fundSizefilters.map((fundSizeValue) => (
                            <div className="ttag">
                              <a>
                                <span>
                                  {
                                    fundSize.filter(
                                      (item) => item.value === fundSizeValue
                                    )[0]?.name
                                  }{" "}
                                  <Cross
                                    onClick={() =>
                                      removeFundSizeFilters(fundSizeValue)
                                    }
                                  />
                                </span>
                              </a>
                            </div>
                          ))}
                          {sipFilters.map((investmentFormat) => (
                            <div className="ttag">
                              <a>
                                <span>
                                  {
                                    investData.filter(
                                      (item) => item.flag === investmentFormat
                                    )[0].name
                                  }
                                  <Cross
                                    onClick={() =>
                                      removeSipFormat(investmentFormat)
                                    }
                                  />
                                </span>
                              </a>
                            </div>
                          ))}
                          {riskFilters.map((filterName) => (
                            <div className="ttag">
                              <a>
                                <span>
                                  {findNameByFrequency(filterName)} Risk
                                  <Cross
                                    onClick={() =>
                                      removeRiskFilters(filterName)
                                    }
                                  />
                                </span>
                              </a>
                            </div>
                          ))}
                        </div>
                        <div className="mutualtable col-12 pull-left">
                          <div className="ipostn">
                            <div className="ipotable defaulttable">
                              <table class="table">
                                <thead>
                                  <tr className="bg-white">
                                    <th scope="col">Funds</th>
                                    <th scope="col">1Y Return</th>
                                    <th scope="col">3Y Return</th>
                                    <th scope="col">5Y Return</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {isLoading ? (
                                    <ThreeDots
                                      height="100"
                                      width="100"
                                      radius="10"
                                      color="#3AA29F"
                                      ariaLabel="three-dots-loading"
                                      wrapperStyle={{
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                      visible={true}
                                    />
                                  ) : (
                                    allFundsDataCopy
                                      ?.slice(
                                        (currentPage - 1) * 10,
                                        currentPage * 10
                                      )
                                      .map((fund) => (
                                        <tr>
                                          <td scope="row">
                                            <div className="ipologo">
                                              <span>
                                                <img
                                                  src={fund?.amc_logo}
                                                  alt=""
                                                />
                                              </span>
                                              <div className="mfname">
                                                <NavLink
                                                  to={`/invest/mutual-fund/detail/${Number(
                                                    fund?.plan_id
                                                  )}`}
                                                >
                                                  <h5 className="mb-0">
                                                    <a>{fund?.basic_name}</a>
                                                  </h5>
                                                </NavLink>

                                                <div className="spoints d-flex gap-2">
                                                  <span className="fw600">
                                                    {fund?.asset_class}
                                                  </span>
                                                  <span>
                                                    {riskMapping[fund?.colour]}
                                                  </span>
                                                  <span className="gap-1">
                                                    {fund?.fund_rating}
                                                    <i className="fa fa-star"></i>
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          </td>
                                          <td>
                                            {" "}
                                            <div className="ipologo">
                                              <div className="mfname">
                                                <h5 className="mb-0">
                                                  {fund?.ret_1year === null
                                                    ? "NA"
                                                    : Number(
                                                        fund?.ret_1year
                                                      ).toFixed(2) + "%"}
                                                </h5>
                                              </div>
                                            </div>
                                          </td>
                                          <td>
                                            {" "}
                                            <div className="ipologo">
                                              <div className="mfname">
                                                <h5 className="mb-0">
                                                  {fund?.ret_3year === null
                                                    ? "NA"
                                                    : Number(
                                                        fund?.ret_3year
                                                      ).toFixed(2) + "%"}
                                                </h5>
                                              </div>
                                            </div>
                                          </td>
                                          <td>
                                            {" "}
                                            <div className="ipologo">
                                              <div className="mfname">
                                                <h5 className="mb-0">
                                                  {fund?.ret_5year === null
                                                    ? "NA"
                                                    : Number(
                                                        fund?.ret_5year
                                                      ).toFixed(2) + "%"}
                                                </h5>
                                              </div>
                                            </div>
                                          </td>
                                        </tr>
                                      ))
                                  )}
                                </tbody>
                                {allFundsDataCopy.length < 1 &&
                                  isLoading === false && (
                                    <h3
                                      style={{
                                        textAlign: "center",
                                        paddingTop: "30px",
                                      }}
                                    >
                                      No Results Found
                                    </h3>
                                  )}
                              </table>
                              {allFundsDataCopy.length > 0 && (
                                <PaginationComponent
                                  data={allFundsDataCopy}
                                  currentPage={currentPage}
                                  setCurrentPage={setCurrentPage}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
};
