import React, { useContext } from "react";
import Footer from "../../../components/Footer";
import { Link } from "react-router-dom";
import SEO from "../../../components/common/SEO";
import SMCNEWLOGO from "../../../images/SMCNEWLOGO.webp";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import Header from "../../../components/Header";
import { Helmet } from "react-helmet";
import { Context } from "../../../context";
import { SEO_URL } from "../../../utils/constants";
import { seoData } from "../../../utils/Data";

const InvestHolidayTradingHolidays = () => {
  const { showRoute, setShowRoute, showPath } = useContext(Context);
  const tradingHolidayList = [
    {
      name: "Special Holiday",
      date: "22-Jan-2024",
      day: "Monday",
      morning: "Closed",
      evening: "Closed",
    },
    {
      name: "Republic Day",
      date: "26-Jan-2024",
      day: "Friday",
      morning: "Closed",
      evening: "Closed",
    },
    {
      name: "Maha Shivaratri",
      date: "08-Mar-2024",
      day: "Friday",
      morning: "Closed",
      evening: "Open",
    },
    {
      name: "Holi",
      date: "25-Mar-2024",
      day: "Monday",
      morning: "Closed",
      evening: "Open",
    },
    {
      name: "Good Friday",
      date: "29-Mar-2024",
      day: "Friday",
      morning: "Closed",
      evening: "Closed",
    },
    {
      name: "Eid Al Fitr",
      date: "11-Apr-2024",
      day: "Thursday",
      morning: "Closed",
      evening: "Open",
    },
    {
      name: "Ram Navami",
      date: "17-Apr-2024",
      day: "Wednesday",
      morning: "Closed",
      evening: "Open",
    },
    {
      name: "Maharashtra Day",
      date: "01-May-2024",
      day: "Wednesday",
      morning: "Closed",
      evening: "Open",
    },
    {
      name: "Id-Ul-Adha (Bakri Id)",
      date: "17-Jun-2024",
      day: "Monday",
      morning: "Closed",
      evening: "Open",
    },
    {
      name: "Muharram",
      date: "17-Jul-2024",
      day: "Wednesday",
      morning: "Closed",
      evening: "Open",
    },
    {
      name: "Independence Day",
      date: "15-Aug-2024",
      day: "Thursday",
      morning: "Closed",
      evening: "Closed",
    },
    {
      name: "Mahatma Gandhi Jayanti",
      date: "02-Oct-2024",
      day: "Wednesday",
      morning: "Closed",
      evening: "Closed",
    },
    {
      name: "Dussehra",
      date: "12-Oct-2024",
      day: "Saturday",
      morning: "Closed",
      evening: "Open",
    },
    {
      name: "Diwali-Laxmi Pujan",
      date: "01-Nov-2024",
      day: "Friday",
      morning: "Closed",
      evening: "Open",
    },
    {
      name: "Gurunanak Jayanti",
      date: "15-Nov-2024",
      day: "Friday",
      morning: "Closed",
      evening: "Open",
    },
    {
      name: "Christmas",
      date: "25-Dec-2024",
      day: "Wednesday",
      morning: "Closed",
      evening: "Closed",
    },
  ];

  const mcxHolidayList = [
    {
      name: "Republic Day",
      date: "26-Jan-2024",
      day: "Friday",
      morning: "Closed",
      evening: "Closed",
    },
    {
      name: "Maha Shivaratri",
      date: "08-Mar-2024",
      day: "Friday",
      morning: "Closed",
      evening: "Open",
    },
    {
      name: "Holi",
      date: "25-Mar-2024",
      day: "Monday",
      morning: "Closed",
      evening: "Open",
    },
    {
      name: "Good Friday",
      date: "29-Mar-2024",
      day: "Friday",
      morning: "Closed",
      evening: "Closed",
    },
    {
      name: "Eid Al Fitr",
      date: "11-Apr-2024",
      day: "Thursday",
      morning: "Closed",
      evening: "Open",
    },
    {
      name: "Ram Navami",
      date: "17-Apr-2024",
      day: "Wednesday",
      morning: "Closed",
      evening: "Open",
    },
    {
      name: "Maharashtra Day",
      date: "01-May-2024",
      day: "Wednesday",
      morning: "Closed",
      evening: "Open",
    },
    {
      name: "Id-Ul-Adha (Bakri Id)",
      date: "17-Jun-2024",
      day: "Monday",
      morning: "Closed",
      evening: "Open",
    },
    {
      name: "Muharram",
      date: "17-Jul-2024",
      day: "Wednesday",
      morning: "Closed",
      evening: "Open",
    },
    {
      name: "Independence Day",
      date: "15-Aug-2024",
      day: "Thursday",
      morning: "Closed",
      evening: "Closed",
    },
    {
      name: "Mahatma Gandhi Jayanti",
      date: "02-Oct-2024",
      day: "Wednesday",
      morning: "Closed",
      evening: "Closed",
    },
    {
      name: "Diwali-Laxmi Pujan",
      date: "01-Nov-2024",
      day: "Friday",
      morning: "Closed",
      evening: "Open",
    },
    {
      name: "Gurunanak Jayanti",
      date: "15-Nov-2024",
      day: "Friday",
      morning: "Closed",
      evening: "Open",
    },
    {
      name: "Christmas",
      date: "25-Dec-2024",
      day: "Wednesday",
      morning: "Closed",
      evening: "Closed",
    },
  ];

  return (
    <>
      <Header />
      <SEO
        title={seoData.tradingHolidays.title}
        description={seoData.tradingHolidays.description}
        name="Company name."
        type="article"
        url={seoData.tradingHolidays.url}
        image={seoData.tradingHolidays.image}
      />

      <section id="banner">
        <div className="banner internal mpadding">
          <div className="container">
            <div className="bredcrum">
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                {showPath ? (
                  <li>
                    <Link to="/invest">Invest</Link>
                  </li>
                ) : (
                  <li style={{ color: "initial" }}>
                    <Link to="/contact-us">Contact Us</Link>
                  </li>
                )}

                <li>Trading Holidays</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <main>
        <section id="main">
          <div className="webcent">
            <div className="container">
              <div className="row">
                <div className="web-container">
                  <div className="col-lg-10 m-auto">
                    <div className="title">
                      <h3>Stock Market Holidays 2024</h3>
                    </div>
                    <div className="ipostable holidays">
                      <div className="tablist gap-sm-4 d-flex flex-wrap">
                        <div className="ipostn p-0">
                          <div className="ipotitle">
                            <h4>Stock Market Complete Holidays List</h4>
                          </div>
                          <div className="ipotable defaulttable">
                            <table class="table">
                              <thead>
                                <tr>
                                  <th scope="col">Holidays</th>
                                  <th scope="col">Date</th>
                                  <th scope="col">Day</th>
                                </tr>
                              </thead>
                              <tbody>
                                {tradingHolidayList.map((item) => (
                                  <tr>
                                    <td>{item?.name}</td>
                                    <td>{item?.date}</td>
                                    <td>{item?.day}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="ipostn">
                          <div className="ipotitle">
                            <h4>
                              Following public trading holidays are falling on
                              Saturday / Sunday:
                            </h4>
                          </div>
                          <div className="ipotable defaulttable">
                            <table class="table">
                              <thead>
                                <tr>
                                  <th scope="col">Holidays</th>
                                  <th scope="col">Date</th>
                                  <th scope="col">Day</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>Dr. Baba Saheb Ambedkar Jayanti</td>
                                  <td>14-Apr-2024</td>
                                  <td>Sunday</td>
                                </tr>
                                <tr>
                                  <td>Shri Mahavir Jayanti</td>
                                  <td>21-Apr-2024</td>
                                  <td>Sunday</td>
                                </tr>
                                <tr>
                                  <td>Ganesh Chaturthi</td>
                                  <td>07-Sep-2024</td>
                                  <td>Saturday</td>
                                </tr>
                                <tr>
                                  <td>Dussehra</td>
                                  <td>12-Oct-2024</td>
                                  <td>Saturday</td>
                                </tr>
                                <tr>
                                  <td>Diwali-Balipratipada</td>
                                  <td>02-Nov-2024</td>
                                  <td>Saturday</td>
                                </tr>
                              </tbody>
                            </table>
                            <small>
                              * The Muhurat Trading day timings will be notified
                              closer to Diwali by the Exchanges.
                              <br />
                              N.B. – The above holidays may be altered or
                              changed by the Exchanges. Circular will be issued
                              in advance for the same.
                            </small>
                          </div>
                        </div>
                        <div className="ipostn">
                          <div className="ipotitle">
                            <h4>MCX Trading Holidays</h4>
                          </div>
                          <div className="ipotable defaulttable">
                            <div className="overview w-100 mb-3">
                              <p>
                                The Multi Commodity Exchange of India Limited
                                (MCX), is the leading exchange for trading in
                                commodities.
                              </p>
                              <p>
                                MCX lets people trade commodity derivative
                                contracts in many different areas, such as
                                bullion, industrial metals, energy, and
                                agricultural commodities. These contracts can
                                also be used to make indices. It is the first
                                exchange in India to provide basic metals index
                                futures, bullion index futures, and commodity
                                options contracts.
                              </p>
                            </div>
                            <table class="table">
                              <thead>
                                <tr>
                                  <th scope="col">Holidays</th>
                                  <th scope="col">Date</th>
                                  <th scope="col">Day</th>
                                  <th scope="col">Morning session</th>
                                  <th scope="col">Evening session</th>
                                </tr>
                              </thead>
                              <tbody>
                                {mcxHolidayList.map((item) => (
                                  <tr>
                                    <td>{item?.name}</td>
                                    <td>{item?.date}</td>
                                    <td>{item?.day}</td>
                                    <td>{item?.morning}</td>
                                    <td>{item?.evening}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                            <p>Morning session: 09:00 AM to 05:00 PM</p>
                            <p>Evening session: 05:00 PM to 11:30/11:55 PM</p>
                            <p>
                              <small>
                                * The Muhurat Trading timings will be notified
                                closer to Diwali by the Exchanges.
                              </small>
                            </p>
                          </div>
                        </div>
                        <div className="ipostn">
                          <div className="ipotitle">
                            <h4>Stock Market Holidays In January 2024</h4>
                          </div>
                          <div className="ipotable defaulttable">
                            <table class="table">
                              <thead>
                                <tr>
                                  <th scope="col">Holidays</th>
                                  <th scope="col">Date</th>
                                  <th scope="col">Day</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>Republic Day</td>
                                  <td>26 January 2024</td>
                                  <td>Friday</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="ipostn">
                          <div className="ipotitle">
                            <h4>Stock Market Holidays In March 2024</h4>
                          </div>
                          <div className="ipotable defaulttable">
                            <table class="table">
                              <thead>
                                <tr>
                                  <th scope="col">Holidays</th>
                                  <th scope="col">Date</th>
                                  <th scope="col">Day</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>Maha Shivaratri</td>
                                  <td>08 Mar 2024</td>
                                  <td>Friday</td>
                                </tr>
                                <tr>
                                  <td>Holi</td>
                                  <td>25 Mar 2024</td>
                                  <td>Monday</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="ipostn">
                          <div className="ipotitle">
                            <h4>Share Market Holidays In April 2024</h4>
                          </div>
                          <div className="ipotable defaulttable">
                            <table class="table">
                              <thead>
                                <tr>
                                  <th scope="col">Holidays</th>
                                  <th scope="col">Date</th>
                                  <th scope="col">Day</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>Eid Al Fitr</td>
                                  <td>11 Apr 2024</td>
                                  <td>Thursday</td>
                                </tr>
                                <tr>
                                  <td>Ram Navami</td>
                                  <td>17 Apr 2024</td>
                                  <td>Wednesday</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="ipostn">
                          <div className="ipotitle">
                            <h4>Stock Exchange Holidays In May 2024</h4>
                          </div>
                          <div className="ipotable defaulttable">
                            <table class="table">
                              <thead>
                                <tr>
                                  <th scope="col">Holidays</th>
                                  <th scope="col">Date</th>
                                  <th scope="col">Day</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>Maharashtra Day</td>
                                  <td>01 May 2024</td>
                                  <td>Wednesday</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="ipostn">
                          <div className="ipotitle">
                            <h4>Stock Exchange Holidays In June 2024</h4>
                          </div>
                          <div className="ipotable defaulttable">
                            <table class="table">
                              <thead>
                                <tr>
                                  <th scope="col">Holidays</th>
                                  <th scope="col">Date</th>
                                  <th scope="col">Day</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>Id-Ul-Adha (Bakri Id) </td>
                                  <td>17 Jun 2024 </td>
                                  <td>Monday</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="ipostn">
                          <div className="ipotitle">
                            <h4>Stock Exchange Holidays In July 2024</h4>
                          </div>
                          <div className="ipotable defaulttable">
                            <table class="table">
                              <thead>
                                <tr>
                                  <th scope="col">Holidays</th>
                                  <th scope="col">Date</th>
                                  <th scope="col">Day</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>Muharram </td>
                                  <td>17 Jul 2024 </td>
                                  <td>Wednesday</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="ipostn">
                          <div className="ipotitle">
                            <h4>Stock Exchange Holidays In August 2024</h4>
                          </div>
                          <div className="ipotable defaulttable">
                            <table class="table">
                              <thead>
                                <tr>
                                  <th scope="col">Holidays</th>
                                  <th scope="col">Date</th>
                                  <th scope="col">Day</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>Independence Day</td>
                                  <td>15 August 2024 </td>
                                  <td>Thursday</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="ipostn">
                          <div className="ipotitle">
                            <h4>Stock Exchange Holidays In October 2024</h4>
                          </div>
                          <div className="ipotable defaulttable">
                            <table class="table">
                              <thead>
                                <tr>
                                  <th scope="col">Holidays</th>
                                  <th scope="col">Date</th>
                                  <th scope="col">Day</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>Mahatma Gandhi Jayanti </td>
                                  <td>02 October 2024 </td>
                                  <td>Wenesday</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="ipostn">
                          <div className="ipotitle">
                            <h4>Stock Exchange Holidays In November 2024</h4>
                          </div>
                          <div className="ipotable defaulttable">
                            <table class="table">
                              <thead>
                                <tr>
                                  <th scope="col">Holidays</th>
                                  <th scope="col">Date</th>
                                  <th scope="col">Day</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>Diwali-Laxmi Pujan</td>
                                  <td>01 Nov 2024</td>
                                  <td> Friday</td>
                                </tr>
                                <tr>
                                  <td>Gurunanak Jayanti </td>
                                  <td>15 November 2024 </td>
                                  <td>Friday</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="ipostn">
                          <div className="ipotitle">
                            <h4>Stock Exchange Holidays In December 2024</h4>
                          </div>
                          <div className="ipotable defaulttable">
                            <table class="table">
                              <thead>
                                <tr>
                                  <th scope="col">Holidays</th>
                                  <th scope="col">Date</th>
                                  <th scope="col">Day</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>Christmas</td>
                                  <td>25 December 2024 </td>
                                  <td>Wednesday</td>
                                </tr>
                              </tbody>
                            </table>
                            <p>
                              *Please note that the Indian Stock Market Holiday
                              list may change from year to year and it is always
                              recommended to check for the most up-to-date
                              information from reliable sources before making
                              any trading decisions or planning your investment
                              activities. Always consult with a financial
                              advisor for personalised investment advice. Happy
                              investing!
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="webcent">
            <div className="container">
              <div className="row">
                <div className="web-container">
                  <div className="title">
                    <h3>Frequently Asked Questions</h3>
                  </div>
                  <div className="faqcont">
                    <Accordion allowZeroExpanded="true">
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            What comprises the stock market holiday list?
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <div className="accordiancont">
                            <p>
                              The stock market holiday list delineates the days
                              when stock exchanges in India suspend trading
                              activities. This encompasses weekends, national
                              holidays, and other instances when the stock
                              market remains inactive.
                            </p>
                          </div>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            Is it possible to buy or sell stocks on share market
                            holidays?
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <div className="accordiancont">
                            <p>
                              No, trading stocks on share market holidays in
                              India is not feasible as the stock exchanges are
                              closed on these days. Trading activities cease
                              during these holidays, and you won't be able to
                              execute any buy or sell orders until the
                              subsequent trading day.
                            </p>
                          </div>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            Where can I access the Indian stock market holiday
                            schedule?
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <div className="accordiancont">
                            <p>
                              The Indian stock market holiday schedule is
                              typically posted by the stock exchanges on their
                              official websites. Additionally, it can be found
                              on financial news websites, trading platforms, and
                              disseminated by market regulators.
                            </p>
                          </div>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            What significance does understanding the stock
                            market holiday list hold?
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <div className="accordiancont">
                            <p>
                              Understanding the stock market holiday list is
                              crucial as it enables traders, investors, and
                              other market participants to organize their
                              trading activities effectively.
                            </p>
                          </div>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            What are the operating hours for the stock market?
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <div className="accordiancont">
                            <p>
                              In India, the standard trading hours for the stock
                              market span from 9:15 am to 3:30 pm, Monday to
                              Friday. At 9:15 am, the market initiates with a
                              15-minute pre-open session, enabling investors and
                              traders to place advance buy/sell orders. The
                              official trading session commences at 9:30 am and
                              concludes at 3:30 pm. It's important to
                              acknowledge that trading hours might vary based on
                              market conditions or regulatory stipulations.
                              Moreover, Indian stock exchanges conduct special
                              trading sessions like Muhurat trading and extended
                              trading sessions during earnings season, each with
                              distinct opening and closing times.
                            </p>
                          </div>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            Is it possible to trade on weekends in the Indian
                            stock market?
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <div className="accordiancont">
                            <p>
                              No, weekends, typically Saturdays and Sundays, are
                              designated as non-trading days in the Indian stock
                              market. The stock exchanges in India adhere to a
                              five-day trading week, operating from Monday to
                              Friday, with trading hours stipulated by the
                              exchanges.
                            </p>
                          </div>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            Do special trading hours or half-day sessions occur
                            during holidays?
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <div className="accordiancont">
                            <p>
                              Indeed, on certain holidays, stock exchanges may
                              implement special trading hours or conduct
                              half-day trading sessions. For instance, during
                              Diwali, also recognized as Muhurat trading, stock
                              exchanges organize a special trading session for a
                              limited duration. The exchanges announce these
                              special trading hours or half-day sessions in
                              advance.
                            </p>
                          </div>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            Is it possible to purchase or sell stocks during
                            Muhurat trading?
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <div className="accordiancont">
                            <p>
                              Certainly, you can engage in buying and selling
                              shares during Muhurat trading.
                            </p>
                          </div>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            What constitutes a settlement holiday?
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <div className="accordiancont">
                            <p>
                              A settlement holiday denotes a day when stock
                              exchanges and clearing corporations abstain from
                              conducting clearing and settlement activities. In
                              India, stock market trades settle on a T+1 or T+2
                              basis, meaning the settlement occurs two working
                              days following the trade execution. Throughout
                              this settlement timeframe, stock exchanges and
                              clearing corporations validate and reconcile trade
                              details furnished by brokers, and allocate shares
                              or funds to the relevant parties. Settlement
                              holidays typically coincide with bank holidays,
                              during which the clearing and settlement processes
                              are deferred to the subsequent working day. It's
                              imperative for investors and traders to
                              acknowledge settlement holidays when strategizing
                              their trades, ensuring they adhere to important
                              settlement dates for timely trade settlement.
                            </p>
                          </div>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            Does the stock market close every 2nd Saturday?
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <div className="accordiancont">
                            <p>
                              The Indian stock market operates on a five-day
                              trading week, spanning from Monday to Friday, and
                              remains closed on weekends, which comprise
                              Saturdays and Sundays.
                            </p>
                          </div>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            Does the stock market shut down for all bank
                            holidays?
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <div className="accordiancont">
                            <p>
                              Typically, the stock market in India adheres to
                              its unique holiday calendar, which is
                              pre-announced by the stock exchanges. Hence, it's
                              essential to consult the official holiday lists
                              provided by the stock exchanges to ascertain the
                              days when the stock market will cease trading
                              operations.
                            </p>
                          </div>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            Is it possible to engage in trading on a day not
                            designated as a holiday?
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <div className="accordiancont">
                            <p>
                              Certainly, you can participate in trading on days
                              not designated as holidays. Nonetheless, it's
                              crucial to acknowledge that the stock market may
                              be closed for trading on specific days.
                            </p>
                          </div>
                        </AccordionItemPanel>
                      </AccordionItem>
                    </Accordion>
                    <p className="mt-3 d-block col-12 pull-left">
                      *Please note that the Trading Holiday List may change from
                      year to year and it is recommended to check for the most
                      up-to-date information from reliable sources before making
                      any trading decisions or planning your investment
                      activities. Always consult with a financial advisor for
                      personalised investment advice.
                    </p>
                    <br />
                    <p>Happy investing!</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
};

export default InvestHolidayTradingHolidays;
