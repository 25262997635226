const IpoTimeline = ({ ipoTimeline, isMobile }) => {
    return (
        <div className="ipostn">
            <div className="ipotitle">
                <h5>IPO Timeline</h5>
            </div>
            <div
                className={
                    isMobile
                        ? "ipotable defaulttable"
                        : "ipotable"
                }
            >
                <table
                    class="table"
                    style={{ width: isMobile && "1000px" }}
                >
                    <thead>
                        <tr>
                            <th scope="col">Bidding starts</th>
                            <th scope="col">Bidding ends</th>
                            <th scope="col">
                                Allotment finalisation
                            </th>
                            <th scope="col">Refund Initation</th>
                            <th scope="col">Demat Transfer</th>
                            <th scope="col">Listing Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                {/* <strong className="mobile-show">
                                            Bidding starts
                                          </strong> */}
                                {/* {convertDate(
                                            subscriptionDetails
                                              ?.CommencementoftradingoftheEquitySharesontheStockExchanges
                                          )} */}
                                {ipoTimeline?.ipostartdate}
                            </td>
                            <td>
                                {/* <strong className="mobile-show">
                                            Bidding ends
                                          </strong> */}
                                {/* {convertDate(
                                            openIPOStockDeatils[0]?.CLOSDATE
                                          )} */}
                                {ipoTimeline?.ippenddate}
                            </td>
                            <td>
                                {/* <strong className="mobile-show">
                                            Allotment finalisation*
                                          </strong> */}
                                {/* {convertDate(
                                            subscriptionDetails?.CreditofEquitySharestodemataccountsofAllottees
                                          )} */}
                                {ipoTimeline?.AllotmentDate}
                            </td>
                            <td>
                                {/* <strong className="mobile-show">
                                            Refund Initation
                                          </strong> */}
                                {ipoTimeline?.RefundDate}
                            </td>
                            <td>
                                {/* <strong className="mobile-show">
                                            Demat Transfer
                                          </strong> */}
                                {
                                    ipoTimeline?.CreditofsharestoDemataccountDate
                                }
                            </td>
                            <td>
                                {/* <strong className="mobile-show">
                                            Listing
                                          </strong> */}
                                {/* {convertDate(
                                            openIPOStockDeatils[0]?.LISTDATE
                                          )} */}
                                {ipoTimeline?.ListingDate}
                            </td>
                        </tr>
                    </tbody>
                </table>
                {/* refund process may be used in future */}
                {/* <small>
                                    * Refund is a 7-10 days process. If you
                                    don’t get refund then please contact your
                                    bank.
                                  </small> */}
            </div>
        </div>
    )
}

export default IpoTimeline;