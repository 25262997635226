import React, { useState, useEffect } from "react";
import { DonutChart } from "../../../../charts/DonutChart";
import { useLocation } from "react-router-dom";
import { getSectorAllocationDetailsData } from "../../../../utils/APICollection";
const MfEquitySectorAllocation = ({
  assetAllocationDetailsData,
  fundDetails,
}) => {
  const [sectorAllocationData, setSectorAllocationData] = useState([]);
  const location = useLocation();
  const fetchSectorAllocation = async () => {
    try {
      const res = await getSectorAllocationDetailsData(
        window.location.pathname.split("/")[4]
      );
      setSectorAllocationData(res?.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchSectorAllocation();
  }, [location.pathname]);
  return (
    <div className="returncalulator">
      <div className="calheading">
        <p className="text-black">
          <strong>Equity Sector Allocation</strong>
        </p>
      </div>
      {/* donut chart */}
      {sectorAllocationData.length > 0 &&
      assetAllocationDetailsData.length > 0 ? (
        <DonutChart
          sectorAllocationData={sectorAllocationData}
          equityPercentage={
            assetAllocationDetailsData.filter(
              (item) => item.asset_class === "Equity"
            )[0]?.total_percentage
          }
          fundSize={Number(fundDetails?.latest_aum).toFixed(2)}
        />
      ) : (
        <h3>No Data Found</h3>
      )}
    </div>
  );
};
export default MfEquitySectorAllocation;
