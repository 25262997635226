import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import validator from "validator";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { leadApi, register, sendOtpApi } from "../utils/APICollection";
import { useContext } from "react";
import { Context } from "../context";
import axios from "axios";
import Loader from "./Loader";
import CitySearch from "./common/CitySearch";
import { color, style } from "@mui/system";
import { CAPTCHA_KEY } from "../utils/constants";
import { loadReCaptchaScript } from "./common/FormattedDate";

const ResearchForm = ({ catID }) => {
  const navigate = useNavigate();

  const {
    num,
    setNum,
    name,
    setName,
    email,
    setEmail,
    state,
    setState,
    city,
    setCity,
    setCategoryID,
    setUrlData,
    ipAddress,
    setIPAddress,
    urlData,
    categoryID,
    loading,
    setLoading,
    deviceID,
    setDeviceID,
    setLeadId,
  } = useContext(Context);
  const [emailError, setEmailError] = React.useState("");
  const [nameError, setNameError] = React.useState("");
  const [cityError, setCityError] = React.useState("");
  const [mobileError, setMobileError] = React.useState();
  const [searchParams, setSearchParams] = useSearchParams();
  searchParams.get("refcode");

  const limitChar = 10;
  const handleNumChange = (e) => {
    if (e.target.value.toString().length <= limitChar) {
      setNum(e.target.value);
      setMobileError("");
    }
  };
  const handleName = (e) => {
    setName(e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1));
    setNameError("");
  };

  const handleEmail = (e) => {
    setEmail(e.target.value);
    setEmailError("");
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (loading) {
      return;
    }
    setLoading(true);

    let emailErr = "";
    let mobErr = "";
    let namErr = "";
    let cityErr = "";

    if (!validator.isEmail(email)) {
      emailErr = "Enter a Valid Email ID";
    }

    if (!name || name.trim() === "" || name?.length < 3 || name?.length > 50) {
      namErr = "Please fill out this field";
    }

    if (num?.toString().length !== 10) {
      mobErr = "Please fill out this field";
    }

    if (city == "") {
      cityErr = "Please fill out this field";
    }

    if (emailErr || mobErr || namErr || cityErr) {
      setEmailError(emailErr);
      setMobileError(mobErr);
      setNameError(namErr);
      setCityError(cityErr);
      setLoading(false);
    } else {
      try {
        // const { data, error } = await register({ mobile_no: num });
        // if (data?.stutus == "success") {
        getIP();

        setCategoryID(catID);

        setUrlData({
          ref: searchParams.get("utm_refcode"),
          source: searchParams.get("utm_source"),
          medium: searchParams.get("utm_medium"),
          campaign: searchParams.get("utm_campaign"),
        });
        window.grecaptcha.ready(() => {
          window.grecaptcha
            .execute(String(CAPTCHA_KEY), { action: "submit" })
            .then(async (token) => {
              const { data: datal, messagel } = await leadApi({
                mobile_no: num,
                captchaToken: token,
                leadInfo: [
                  {
                    user_name: name,
                    contact_number: num,
                    email: email,
                    city: city,
                    state: state,
                    category_code: catID,
                    ref: searchParams.get("utm_refcode"),
                    source: searchParams.get("utm_source"),
                    medium: searchParams.get("utm_medium"),
                    campaign: searchParams.get("utm_campaign"),
                    country: "",
                    product: catID,
                    ip: ipAddress,
                    device: deviceID,
                    status: "Otp sent",
                    client_id: "",
                    sub_query: "",
                    existing_customer: "",
                    explain_query: "",
                  },
                ],
              });
              if (datal) {
                {
                  setLeadId(String(datal?.data[0]));
                  window.grecaptcha.ready(() => {
                    window.grecaptcha
                      .execute(String(CAPTCHA_KEY), { action: "submit" })
                      .then(async (token) => {
                        const { data: data2, message } = await sendOtpApi({
                          mobile_no: num,
                          sms_type: "sms_common",
                          user_name: name,
                          captchaToken: token,
                        });
                        if (data2) {
                          setLoading(false);
                          navigate("/validateotp");
                        } else {
                          setLoading(false);
                        }
                      });
                  });
                }
              } else {
                setLoading(false);
              }
            });
        });
        // } else {
        //   setLoading(false);
        // }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
      // finally {
      //   setLoading(false);
      // }
    }
  };
  const getSourceByCategory = (categoryID) => {
    const categorySourceMap = {
      Research: "SMC Website | Research",
    };

    return categorySourceMap[categoryID] || "SMC Website | Default";
  };

  const getIP = async () => {
    try {
      const userAgent = navigator.userAgent;
      const res = await axios.get("https://api.ipify.org?format=json");

      setIPAddress(res?.data?.ip);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    let userAgent = navigator.userAgent;

    if (/Android/i.test(userAgent)) {
      setDeviceID("Android device.");
    } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
      setDeviceID("iOS device.");
    } else if (/Macintosh/i.test(userAgent)) {
      setDeviceID("macOS device.");
    } else if (/Windows/i.test(userAgent)) {
      setDeviceID("Windows device.");
    } else {
      setDeviceID("Unknown Device");
    }
    getIP();
    loadReCaptchaScript();
  }, []);

  React.useEffect(() => {
    getIP();
  }, []);

  const ALPHA_REGEX = /^[a-zA-Z ]+$/;

  let emailErr = "";

  // if (loading) {
  //   return <Loader />;
  // }

  return (
    <>
      <div className="col-12 pull-left">
        <div className="title">
          <h3>Looking To Invest?</h3>
          <br />
          <p style={{ color: "#fff" }}>
            Open Demat account in just 5 minutes and start investing
          </p>
        </div>
        <div className="referralform">
          <Box
            component="form"
            autoComplete="off"
            className="dmatresearch"
            onSubmit={onSubmit}
          >
            <ul>
              <li>
                <TextField
                  id="filled-basic"
                  label="Name"
                  variant="standard"
                  value={name}
                  onChange={handleName}
                  error={!!nameError}
                  helperText={nameError}
                  inputProps={{
                    maxLength: 50,
                    type: "text",
                  }}
                  onKeyDown={(event) => {
                    if (!ALPHA_REGEX.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  disable={loading}
                />
              </li>
              <li>
                <TextField
                  id="filled-basic"
                  label="Email"
                  variant="standard"
                  type="text"
                  onChange={handleEmail}
                  error={!!emailError}
                  helperText={emailError}
                  // onKeyDown={(event) => {
                  //   if (!reg.test(event.key)) {
                  //     event.preventDefault();
                  //   }
                  // }}
                  disable={loading}
                />
              </li>
              <li>
                <TextField
                  id="filled-basic"
                  label="Mobile No."
                  variant="standard"
                  type="number"
                  onChange={handleNumChange}
                  value={num}
                  error={!!mobileError}
                  helperText={mobileError}
                  onInput={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, e.target.maxLength);
                  }}
                  inputProps={{
                    minLength: 10,
                    maxLength: 10,
                    type: "number",
                  }}
                  disable={loading}
                />
              </li>

              <li>
                <CitySearch
                  cityError={cityError}
                  setCityError={setCityError}
                  color={"white"}
                />
              </li>
            </ul>
            <div className="mbtn refformbtn gap-3">
              <div
                className="btnn"
                style={{
                  boxShadow:
                    "0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)",
                }}
              >
                <button type="sumit">INVEST NOW</button>
              </div>
            </div>
          </Box>
        </div>
      </div>
    </>
  );
};

export default ResearchForm;
