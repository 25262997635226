import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import DematForm from "../../../components/DematForm";
import { Form } from "../../../components/Form";
import { Tab, Tabs, TabList } from "react-tabs";
import Footer from "../../../components/Footer";
import { listData } from "../../../data";
import { Typography } from "@mui/material";
import { useContext } from "react";
import { Context } from "../../../context";
import Header from "../../../components/Header";
import Loader from "../../../components/Loader";
import StocksCommonTable from "./StocksCommonTable";
import SEO from "../../../components/common/SEO";
import StockFaq from "../../invest/investList/stockDetails/StockFaq";
import StockIntroductionSmc from "./StockIntroductionSmc";
import StockWhyInvestInSmc from "./StockWhyInvestInSmc";
import { getIndicesData, getStockData } from "../../../utils/APICollection";
import { SEO_URL } from "../../../utils/constants";

const Stocks = () => {
  const [sidebarTop, setSidebarTop] = useState();
  const [shouldAddClass, setShouldAddClass] = useState(false);
  const [sidebarWidth, setSidebarWidth] = useState();
  const [tabChange, setTabChange] = useState("Top Gainers");
  const [segment, setSegment] = useState(1);
  const [secIdxCode, setSecIdxCode] = useState(13);
  const [typeOfFlag, setTypeOfFlag] = useState("G");
  const [instrument, setInstrument] = useState("EQ");
  const [stockData, setStockData] = useState([]);
  const [ipoLoading, setIpoLoading] = useState(false);
  const [error, setError] = useState("");
  const [type, setType] = useState("Gain");
  const [group, setGroup] = useState(20559);
  const [indicesData, setIndicesData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const { exchange, setExchange, showPath, loading } = useContext(Context);

  const showBseGroup = [
    "BSE SENSEX 50",
    "BSE 250 SmallCap",
    "BSE 150 MidCap",
    "BSE Largecap",
  ];
  const showNseGroup = [
    "Nifty 50",
    "Nifty 100",
    "Nifty 200",
    "Nifty 500",
    "Nifty Smallcap 50",
    "Nifty Smallcap 100",
    "Nifty Smallcap 250",
    "Nifty Midcap 50",
    "Nifty Midcap 100",
    "Nifty Midcap 250",
    "Nifty Bank",
  ];

  const fetchIndicesData = async () => {
    try {
      const data = await getIndicesData();
      setIndicesData(data?.data?.data);
    } catch (err) {
      console.log(err);
    }
  };

  const nseDataList = indicesData
    .filter((item) => item?.Exchange === "NSE")
    .filter((item) =>
      showNseGroup.some((showNseGroup) => showNseGroup === item.aliasname)
    );
  const bseDataList = indicesData
    .filter((item) => item?.Exchange === "BSE")
    .filter((item) =>
      showBseGroup.some((showBseGroup) => showBseGroup === item.aliasname)
    );
  const fetchData = async () => {
    setIpoLoading(true);
    try {
      const data = await getStockData(type, exchange, group);
      setStockData(data?.data?.data);
      setIpoLoading(false);
      setCurrentPage(1);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    setExchange("NSE");
  }, []);

  useEffect(() => {
    fetchIndicesData();
  }, []);

  useEffect(() => {
    fetchData();
  }, [type, group]);

  useEffect(() => {
    const sidebarEl = document.querySelector(".opendemat");

    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const shouldAdd = scrollTop >= sidebarTop - 10;

      setShouldAddClass(shouldAdd);
    };

    if (sidebarEl) {
      const sidebarRect = sidebarEl.getBoundingClientRect();
      setSidebarWidth(sidebarRect.width);
      setSidebarTop(sidebarRect.top);

      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, [sidebarTop]);

  useEffect(() => {
    if (!sidebarTop) return;

    const handleScroll = () => {
      const sidebarEl = document.querySelector(".opendemat");
      const sectionEl = document.querySelector(".ipodashboard");
      const faq_cnt = document.querySelector(".faqcont");

      const iporow = document.querySelector(".ipodashboard .row");
      const scrollTop = window.scrollY;
      const shouldAdd = scrollTop >= sidebarTop - 10;

      setShouldAddClass(shouldAdd);

      if (shouldAdd) {
        sidebarEl.classList.add("is-sticky");
      } else {
        sidebarEl.classList.remove("is-sticky");
      }

      if (
        scrollTop >=
        sectionEl.offsetTop +
          sectionEl.offsetHeight -
          faq_cnt.offsetHeight / 1.8
      ) {
        sidebarEl.classList.remove("is-sticky");
        iporow.classList.add("flex-end");
      } else {
        iporow.classList.remove("flex-end");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [sidebarTop]);

  const [isOpen, setIsOpen] = useState(false);
  const handleDropdownClick = (e) => {
    if (e.target.className === "dropdown-toggle") {
      setIsOpen(!isOpen);
    } else {
      setIsOpen(false);
    }
  };

  React.useEffect(() => {
    // fetchData(tabChange);
  }, [tabChange, secIdxCode]);
  const isMobile = window.innerWidth <= 1040;
  const title = "Stocks";
  const image = "../../images/SMCNEWLOGO.webp";
  const url = `${SEO_URL}/invest/Equities`;

  return (
    <>
      <Header />
      <SEO
        title={title}
        name="Company name."
        type="article"
        url={url}
        image={image}
      />

      <section id="banner" onClick={handleDropdownClick}>
        <div className="banner internal bauto">
          <div className="container">
            <div className="bredcrum">
              {
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>

                  {showPath == "product" ? (
                    <li style={{ color: "initial" }}>Products</li>
                  ) : (
                    <li>
                      <Link to="/invest">Invest</Link>
                    </li>
                  )}

                  <li style={{ color: "black" }}>Equities</li>
                  <li>{tabChange}</li>
                </ul>
              }
            </div>
            {isMobile && (
              <>
                <div className="title">
                  <h3>{tabChange}</h3>
                </div>
                <div className="col-12 d-flex justify-content-between align-items-center mb-2 tablehead ">
                  <h6>
                    <span style={{ fontWeight: "600" }}>Selected Index </span>{" "}
                    <br />
                    {
                      indicesData.filter((item) => item?.co_code === group)[0]
                        ?.aliasname
                    }
                  </h6>

                  <div className="expiryfilter">
                    <div className="dropdown-container">
                      <span
                        onClick={handleDropdownClick}
                        className="dropdown-toggle"
                      >
                        {exchange}
                      </span>
                      {isOpen && (
                        <div className="dropdown-content">
                          <Typography
                            component="h6"
                            sx={{
                              color: "#4db0a8",
                              ml: "10px",
                              fontWeight: "600",
                            }}
                          >
                            NSE
                          </Typography>
                          {nseDataList?.map((it) => {
                            return (
                              <p
                                key={it.Indexcode}
                                onClick={() => {
                                  setIsOpen(false);
                                  setExchange(it?.Exchange);
                                  setGroup(it?.co_code);
                                }}
                              >
                                {it?.aliasname}
                              </p>
                            );
                          })}
                          <Typography
                            component="h6"
                            sx={{
                              color: "#4db0a8",
                              ml: "10px",
                              fontWeight: "600",
                            }}
                          >
                            BSE
                          </Typography>
                          {bseDataList?.map((it) => {
                            return (
                              <p
                                key={it.Group}
                                onClick={() => {
                                  setIsOpen(false);
                                  setExchange(it?.Exchange);
                                  setGroup(it?.co_code);
                                }}
                              >
                                {it?.aliasname}
                              </p>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </section>

      <main onClick={handleDropdownClick}>
        <section id="main">
          <div className="ipoportfolio">
            <div className="container">
              <div className="web-container">
                <div className="ipodashboard">
                  <div className="row">
                    <div className="ipoleft ipo8box">
                      <div className="ipospace">
                        <div className="webcent">
                          <div className="col-12 d-flex justify-content-between align-items-center mb-5 tablehead ">
                            <div className="title m-0 w-auto">
                              <h3>{tabChange}</h3>
                              <h6 style={{ paddingTop: "10px" }}>
                                Selected Index :{" "}
                                {
                                  indicesData.filter(
                                    (item) => item?.co_code === group
                                  )[0]?.aliasname
                                }
                              </h6>
                            </div>
                            <div></div>
                            <div className="expiryfilter">
                              <div className="dropdown-container">
                                <span
                                  onClick={handleDropdownClick}
                                  className="dropdown-toggle"
                                >
                                  {exchange}
                                </span>
                                {isOpen && (
                                  <div className="dropdown-content">
                                    <Typography
                                      component="h6"
                                      sx={{
                                        color: "#4db0a8",
                                        ml: "10px",
                                        fontWeight: "600",
                                      }}
                                    >
                                      NSE
                                    </Typography>
                                    {nseDataList?.map((it) => {
                                      return (
                                        <p
                                          key={it.Indexcode}
                                          onClick={() => {
                                            // setSegment(it.segment);
                                            // setExchange(it.exchange);
                                            // setSecIdxCode(it.secIdxCode);
                                            setIsOpen(false);
                                            setExchange(it?.Exchange);
                                            setGroup(it?.co_code);
                                          }}
                                        >
                                          {it?.aliasname}
                                        </p>
                                      );
                                    })}
                                    <Typography
                                      component="h6"
                                      sx={{
                                        color: "#4db0a8",
                                        ml: "10px",
                                        fontWeight: "600",
                                      }}
                                    >
                                      BSE
                                    </Typography>
                                    {bseDataList?.map((it) => {
                                      return (
                                        <p
                                          key={it.Group}
                                          onClick={() => {
                                            setIsOpen(false);
                                            setExchange(it?.Exchange);
                                            setGroup(it?.co_code);
                                          }}
                                        >
                                          {it?.aliasname}
                                        </p>
                                      );
                                    })}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="topgainer col-12 pull-left">
                            <Tabs variant="scrollable" scrollButtons="auto">
                              <div className="tradingben m-0">
                                <div className="iconrow noicon stocktabs">
                                  <TabList className="justify-content-start gap-2">
                                    {listData?.map((item, index) => (
                                      <Tab
                                        key={index}
                                        value={item.tabName}
                                        onClick={() => {
                                          // setExchange(item.exchange);
                                          setSecIdxCode(item.secIdxCode);
                                          setTypeOfFlag(item.typeOfFlag);
                                          setInstrument(item.instrument);
                                          setSegment(item.segment);
                                          setTabChange(item.tabName);
                                          setType(item.apiPayload);
                                        }}
                                      >
                                        <h4>{item.tabName}</h4>
                                      </Tab>
                                    ))}
                                  </TabList>
                                </div>
                              </div>
                            </Tabs>
                            <StocksCommonTable
                              stockData={stockData}
                              ipoLoading={ipoLoading}
                              error={error}
                              currentPage={currentPage}
                              setCurrentPage={setCurrentPage}
                              exchange={exchange}
                            />
                          </div>
                        </div>
                        <StockWhyInvestInSmc />
                        <StockIntroductionSmc />
                        {isMobile && (
                          <div style={{ width: "100%" }}>
                            <Form catID="Stock" />
                          </div>
                        )}
                        <StockFaq />
                      </div>
                    </div>
                    <div className="ipoleft ipo4box">
                      {/* <Form catID="Stock" /> */}
                      {!isMobile && (
                        <DematForm
                          addClass={shouldAddClass}
                          sidebarWidth={sidebarWidth}
                          catID="Stock"
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
      {loading && <Loader />}
    </>
  );
};

export default Stocks;
