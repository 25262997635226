import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  getExitLoadData,
  getExpenseRatioData,
} from "../../../../utils/APICollection";

const ExpenseRatio = ({ setOpen2 }) => {
  const [expenseRatioData, setExpenseRatioData] = useState({});
  const [exitLoad, setExitLoad] = useState({});
  const location = useLocation();
  const fetchExpenseRatio = async () => {
    //setIsLoading(true);
    try {
      const res = await getExpenseRatioData(
        window.location.pathname.split("/")[4]
      );
      setExpenseRatioData(res?.data[0]);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchExitLoad = async () => {
    //setIsLoading(true);
    try {
      const res = await getExitLoadData(window.location.pathname.split("/")[4]);
      setExitLoad(res?.data[0]);
      //setExpenseRatioData(res?.data[0]);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    fetchExpenseRatio();
    fetchExitLoad();
  }, [location.pathname]);
  return (
    <div className="webcent">
      <div className="calheading">
        <h3>Expense ratio, exit load and tax</h3>
      </div>
      <div className="expenses">
        <ul>
          <li>
            <span>
              <strong>
                Expense ratio:{" "}
                {Number(expenseRatioData?.expense_ratio).toFixed(2) === "NaN"
                  ? "NA"
                  : Number(expenseRatioData?.expense_ratio).toFixed(2)}
                %
              </strong>
            </span>
            <p>Inclusive of GST</p>
          </li>
          <li>
            <span>
              <strong>Exit load</strong>
            </span>
            <p>{exitLoad?.note}</p>
          </li>
          <li>
            <span>
              <strong>Stamp duty</strong>
            </span>
            <p>0.005% (from July 1st, 2020)</p>
          </li>
          <li>
            <span>
              <strong>Tax implication</strong>
            </span>
            <p>
              Returns are taxed at 15%, if you redeem before one year. After 1
              year, you are required to pay LTCG tax of 10% on returns of Rs 1
              lakh+ in a financial year.
            </p>
          </li>
        </ul>
        <div className="d-flex justify-content-between">
          <span>
            <strong>Understand terms</strong>{" "}
            <i
              className="question"
              onClick={() => setOpen2(true)}
              style={{ cursor: "pointer" }}
            >
              i
            </i>
          </span>
          {/* past data will be used in future */}
          {/* <span>
                                <a>Check past data</a>
                              </span> */}
        </div>
      </div>
    </div>
  );
};

export default ExpenseRatio;
