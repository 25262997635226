import React, { useContext } from "react";

import { NavLink } from "react-router-dom";
import Link from "@mui/material/Link";
import { border } from "@mui/system";
import { Context } from "../context";

export const Login_Drop = ({ setActive, setIsOpen, menus }) => {
  const {
    isDemat,
    num,
    setIsDemat,
    setName,
    setNum,
    setEmail,
    setState,
    setCity,
    name,
    email,
    state,
    city,
    categoryID,
    urlData,
    ipAddress,
  } = useContext(Context);

  const emptyField = () => {
    setCity("");
    setEmail("");
    setName("");
    setState("");
    setNum("");
    setActive(!menus);
    setIsOpen(false);
  };
  return (
    <>
      <div className="menubox">
        <div className="sitemapcol">
          <h4 style={{ color: "#fff" }}>
            <NavLink
              to="https://theautotrender.com/login"
              target="_blank"
              style={{ border: "none", color: "#FFF" }}
            >
              Autotrender login
            </NavLink>
          </h4>
        </div>
        <div className="sitemapcol">
          <h4>
            <NavLink
              to="https://smcrekyc.smcindiaonline.com"
              target="_blank"
              style={{ border: "none" }}
            >
              Dormant Ac Reactivation
            </NavLink>
          </h4>
        </div>
        <div className="sitemapcol">
          <h4>
            <NavLink
              to="https://info.smcindiaonline.org:9890/"
              target="_blank"
              style={{ border: "none" }}
            >
              Apply for Buyback/SGB/NCB
            </NavLink>
          </h4>
        </div>
        <div className="sitemapcol">
          <h4>
            <NavLink
              to="https://evoting.cdslindia.com/Evoting/EvotingLogin"
              target="_blank"
              style={{ border: "none" }}
            >
              E-Voting CDSL
            </NavLink>
          </h4>
        </div>
        <div className="sitemapcol">
          <h4>
            <NavLink
              to="https://www.evoting.nsdl.com/"
              target="_blank"
              style={{ border: "none" }}
            >
              E-Voting NSDL
            </NavLink>
          </h4>
        </div>

        <div className="sitemapcol">
          <h4 style={{ textTransform: "none" }}>
            <NavLink
              to="https://smconline.smcindiaonline.co.in/GOONLINE/login.aspx"
              target="_blank"
              style={{ border: "none" }}
            >
              My SMC A/c
            </NavLink>
          </h4>
        </div>
        <div className="sitemapcol">
          <h4>
            <NavLink
              to="https://smcddpi.smcindiaonline.org:2025/ "
              target="_blank"
              style={{ border: "none" }}
            >
              Demat Debit & Pledge(DDPI)
            </NavLink>
          </h4>
        </div>
        <div className="sitemapcol">
          <h4>
            <NavLink
              to="https://info.smcindiaonline.org/"
              target="_blank"
              style={{ border: "none" }}
            >
              Update your Details
            </NavLink>
          </h4>
        </div>
        <div className="sitemapcol">
          <h4>
            <NavLink
              to="https://trade.smcindiaonline.com/SMCACEWeb/login"
              target="_blank"
              style={{ border: "none" }}
            >
              Online Trading
            </NavLink>
          </h4>
        </div>
        <div className="sitemapcol">
          <h4>
            <NavLink
              to="https://smceasyinvest.com"
              target="_blank"
              style={{ border: "none" }}
            >
              Online MF
            </NavLink>
          </h4>
        </div>
        <div className="sitemapcol">
          <h4>
            <NavLink
              to="https://trade.smcindiaonline.com/Inetnet/OfflineFundTrans.aspx"
              target="_blank"
              style={{ border: "none" }}
            >
              Fund Transfer for Trading
            </NavLink>
          </h4>
        </div>
        <div className="sitemapcol">
          <h4>
            <NavLink
              to="https://portfolio.smcindiaonline.net:8089/UPIPAY/ClntLoginUPI"
              target="_blank"
              style={{ border: "none" }}
            >
              UPI Fund Transfer
            </NavLink>
          </h4>
        </div>
        <div className="sitemapcol">
          <h4>
            <NavLink
              to="https://www.international.smctradeonline.com/Login/?ReturnUrl=%2f"
              target="_blank"
              style={{ border: "none" }}
            >
              International Broking
            </NavLink>
          </h4>
        </div>
        <div className="sitemapcol">
          <h4 style={{ textTransform: "none" }}>
            <NavLink
              to="https://www.smcfinance.com/"
              target="_blank"
              style={{ border: "none" }}
            >
              My NBFC A/c
            </NavLink>
          </h4>
        </div>
        <div className="sitemapcol">
          <h4>
            <NavLink to="/research" style={{ border: "none" }}>
              Research
            </NavLink>
          </h4>
        </div>
        <div className="sitemapcol">
          <h4>
            <NavLink
              to="https://old.smctradeonline.com/PortfolioTracker/Portfoliologin.aspx"
              target="_blank"
              style={{ border: "none" }}
            >
              Portfolio Tracker
            </NavLink>
          </h4>
        </div>
        <div className="sitemapcol">
          <h4>
            <NavLink
              to="https://smcmf.com/jsp/home.jsp"
              target="_blank"
              style={{ border: "none" }}
            >
              Distribution
            </NavLink>
          </h4>
        </div>
        <div className="sitemapcol">
          <h4>
            <NavLink
              to="https://vt.smcindiaonline.org/demoeasytrade/"
              target="_blank"
              style={{ border: "none" }}
            >
              Demo Account Login
            </NavLink>
          </h4>
        </div>

        <div className="sitemapcol">
          <h4>
            <NavLink
              to="https://smconline.smcindiaonline.co.in/GOONLINE/login.aspx"
              target="_blank"
              style={{ border: "none" }}
            >
              Pay Demat Dues Online
            </NavLink>
          </h4>
        </div>
        <div className="sitemapcol">
          <h4>
            <NavLink
              to="https://smconline.smcindiaonline.co.in:1215/"
              target="_blank"
              style={{ border: "none" }}
            >
              Online Account Closure
            </NavLink>
          </h4>
        </div>
        <div className="sitemapcol">
          <h4>
            <NavLink
              to="https://www.smcconnect.in/(S(xxclaszlbnji2xkgeq1eo0ph))/Login/AppLogin.aspx"
              target="_blank"
              style={{ border: "none" }}
            >
              SMC Connect
            </NavLink>
          </h4>
        </div>
      </div>
    </>
  );
};
