import React, { useState, useEffect } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { useLocation } from "react-router-dom";
import {
  getFundManagerDetailsData,
  getFundManagerPortfolioData,
} from "../../../../utils/APICollection";

const FundManagement = () => {
  const [fundManagersData, setFundManagersData] = useState([]);
  const location = useLocation();
  const getFormattedDate = (currentDate) => {
    let d = new Date(currentDate);
    let monthList = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    return d.getDate() + " " + monthList[d.getMonth()] + " " + d.getFullYear();
  };
  const fetchFundManagers = async () => {
    try {
      const res = await getFundManagerDetailsData(
        window.location.pathname.split("/")[4]
      );
      setFundManagersData(
        res?.data.map((data) => ({ ...data, totalFunds: [] }))
      );
    } catch (err) {
      console.log(err);
    }
  };
  const fetchFundManagerPortfolio = async (person_id, idx) => {
    try {
      const res = await getFundManagerPortfolioData(person_id);
      const result = fundManagersData.map((manager, index) =>
        index === idx
          ? {
              ...manager,
              totalFunds: res?.data.map((item) => item?.basic_name),
            }
          : manager
      );
      setFundManagersData(result);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    fetchFundManagers();
  }, [location.pathname]);
  return (
    <div className="webcent">
      <div className="faqcont accordiandesign">
        <div className="calheading">
          <h3>Fund Management</h3>
        </div>
        <Accordion allowZeroExpanded="true">
          {fundManagersData.length > 0 ? (
            fundManagersData.map((fundManagers, index) => (
              <AccordionItem
                onClick={() =>
                  fetchFundManagerPortfolio(fundManagers?.person_id, index)
                }
              >
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <div className="accordiantitle">
                      <div className="accordianleft">
                        <p>
                          <strong>{fundManagers?.person_name}</strong>
                        </p>
                        <p>
                          <strong>
                            {getFormattedDate(fundManagers?.date_from)}
                          </strong>{" "}
                          - Present
                        </p>
                      </div>
                    </div>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <div className="accordiancont">
                    <p>
                      <strong>Education</strong>
                      <br />
                      {fundManagers?.education}
                    </p>
                    <p>
                      <strong>Experience</strong>
                      <br />
                      {fundManagers?.experience}
                    </p>
                    <div>
                      <h4>
                        <strong className="text-black">
                          {" "}
                          Also manages these schemes
                        </strong>
                      </h4>
                      <ul className="manager_ipo">
                        {fundManagers?.totalFunds?.map((funds) => (
                          <li>{funds}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </AccordionItemPanel>
              </AccordionItem>
            ))
          ) : (
            <>No Data Found</>
          )}
        </Accordion>
      </div>
    </div>
  );
};

export default FundManagement;
