import React, { useState, useContext } from "react";
import { Tabs, Tab, Box } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FormGroup } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Context } from "../context";
import SquareOutlinedIcon from "@mui/icons-material/SquareOutlined";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
export const Mfilter = ({
  allAmcData,
  allFundsData,
  setAllFundsData,
  allFundsDataCopy,
  setAllFundsDataCopy,
  fundsCategory,
  setFundsCategory,
  fetchPlansByCategory,
  amcFilters,
  setAmcFilters,
  investData,
  setInvestData,
  sipFilters,
  setSipFilters,
  searchText,
  setSearchText,
  fundSize,
  setFundSize,
  fundSizefilters,
  setFundSizeFilters,
}) => {
  const [value, setValue] = useState(0);
  const [checkedItems, setCheckedItems] = useState({});
  const [checkedAmcItems, setCheckedAmcItems] = useState({});
  const [checkedRiskItems, setCheckedRiskItems] = useState({});
  const [checkedInvestItems, setcheckedInvestItems] = useState({});
  const [checkedFundSizeItems, setCheckedFundSizeItems] = useState({});

  const {
    selectedFilters,
    setSelectedFilters,
    allRiskData,
    setAllRiskData,
    riskFilters,
    setRiskFilters,
  } = useContext(Context);
  const handleChange = (event) => {
    if (event.target.checked) {
      setSelectedFilters((prev) => [...prev, event.target.name]);
    } else {
      setSelectedFilters(
        selectedFilters.filter((x) => x !== event.target.name)
      );
    }
    setCheckedItems({
      ...checkedItems,
      [event.target.name]: event.target.checked,
    });
  };

  const handleAmcFilters = (event) => {
    if (event.target.checked) {
      setAmcFilters((prev) => [...prev, event.target.name]);
    } else {
      setAmcFilters(amcFilters.filter((x) => x !== event.target.name));
    }
    setCheckedAmcItems({
      ...checkedAmcItems,
      [event.target.name]: event.target.checked,
    });
  };

  const handleRiskFilters = (event) => {
    if (event.target.checked) {
      if (event.target.name.length > 1) {
        setRiskFilters((prev) => [
          ...prev,
          event.target.name.split(",").map(Number),
        ]);
      } else {
        setRiskFilters((prev) => [...prev, Number(event.target.name)]);
      }
    } else {
      // const parts = event.target.name.split(',');
      // const filteredParts = parts.filter(item => item !== event.target.name);
      // const resultString = filteredParts.join(',');
      if (event.target.name.length > 1) {
        const elementsToRemove = event.target.name.split(",").map(Number);
        const filteredArray = riskFilters.filter((item) => {
          if (Array.isArray(item)) {
            return JSON.stringify(item) !== JSON.stringify(elementsToRemove);
          }
          return true;
        });
        setRiskFilters(filteredArray);
        // setRiskFilters(riskFilters.filter(num => event.target.name.split(",").map(Number).indexOf(num) === - 1))
      } else {
        setRiskFilters(
          riskFilters.filter((x) => x !== Number(event.target.name))
        );
      }

      //setRiskFilters(riskFilters.filter((x) => x !== event.target.name));
    }
    setCheckedRiskItems({
      ...checkedRiskItems,
      [event.target.name]: event.target.checked,
    });
  };

  const handleInvestFilters = (event) => {
    if (event.target.checked) {
      setSipFilters((prev) => [...prev, event.target.name]);
    } else {
      setSipFilters(sipFilters.filter((x) => x !== event.target.name));
    }
    setcheckedInvestItems({
      ...checkedInvestItems,
      [event.target.name]: event.target.checked,
    });
  };

  const handleSearchAmc = (event) => {
    console.log("search text", event.target.value);
    setSearchText(event.target.value);
  };

  const handleFundSizeFilters = (event) => {
    if (event.target.checked) {
      setFundSizeFilters((prev) => [...prev, Number(event.target.name)]);
    } else {
      setFundSizeFilters(
        fundSizefilters.filter((x) => x !== Number(event.target.name))
      );
    }
    setCheckedFundSizeItems({
      ...checkedFundSizeItems,
      [event.target.name]: event.target.checked,
    });
  };

  const containesElement = (risk) => {
    for (const item of riskFilters) {
      if (Array.isArray(item)) {
        if (JSON.stringify(item) === JSON.stringify(risk)) {
          return true;
        }
      } else {
        if (item === risk[0]) {
          return true;
        }
      }
    }
    return false;
  };

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };
  const theme = createTheme({
    components: {
      MuiTabs: {
        styleOverrides: {
          indicator: {
            backgroundColor: "#4cafa7",
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            "&.Mui-selected": {
              color: "#4cafa7",
            },
            fontFamily: "AvertaStd, Arial, sans-serif",
            fontWeight: "700",
            textAlign: "left",
            justifyContent: "flex-start",
            WebkitJustifyContent: "flex-start",
            alignItems: "baseline",
            borderBottom: "solid 1px #ededed",
          },
        },
      },
      typography: {
        fontFamily: "AvertaStd, Arial, sans-serif",
      },
    },
  });
  return (
    <>
      <Box sx={{ flexGrow: 1, display: "flex", height: 224 }}>
        <ThemeProvider theme={theme}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleTabChange}
            sx={{ borderRight: 1, borderColor: "divider", textAlign: "left" }}
            className="mobfilter"
          >
            <Tab label="AMC" />
            <Tab label="Category" />
            <Tab label="Risk" />
            <Tab label="Available To Invest" />
          </Tabs>
        </ThemeProvider>
        <Box sx={{ flexGrow: 1, p: 2 }} className="boxmanage">
          {value === 0 && (
            <>
              <div className="mfmtabs col-12 pull-left">
                <div className="searchoption">
                  <div className="inputsearch rounded-0 col-12 w-100">
                    <i className="fa fa-search"></i>
                    <input
                      type="text"
                      name="search"
                      placeholder="Search Mutual Fund Companies"
                      autoComplete="off"
                      value={searchText}
                      onChange={handleSearchAmc}
                      required
                    />
                  </div>
                </div>
                <div class="to-dos listing col-12 pull-left mt-3">
                  {allAmcData?.length > 0 ? (
                    searchText?.length < 3 ? (
                      allAmcData?.map((amc, index) => (
                        <div
                          class="to-dos-entry listingrow"
                          style={{ marginBottom: "-10px" }}
                        >
                          <FormGroup key={index}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  icon={
                                    <SquareOutlinedIcon sx={{ fontSize: 17 }} />
                                  }
                                  checkedIcon={
                                    <CheckBoxIcon sx={{ fontSize: 17 }} />
                                  }
                                  name={amc.amc_id}
                                  type="checkbox"
                                  // checked={checkedAmcItems[amc.amc_full_name]}
                                  checked={amcFilters.includes(amc.amc_id)}
                                  onChange={handleAmcFilters}
                                />
                              }
                              label={
                                <Typography
                                  variant="body1"
                                  sx={{ fontSize: 13 }}
                                >
                                  {amc.amc_full_name}
                                </Typography>
                              }
                            />
                          </FormGroup>
                        </div>
                      ))
                    ) : (
                      allAmcData
                        ?.filter((amc) =>
                          amc.amc_full_name
                            ?.toLowerCase()
                            ?.includes(searchText?.toLowerCase())
                        )
                        .map((amc, index) => (
                          <div
                            class="to-dos-entry listingrow"
                            style={{ marginBottom: "-10px" }}
                          >
                            <FormGroup key={index}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    icon={
                                      <SquareOutlinedIcon
                                        sx={{ fontSize: 17 }}
                                      />
                                    }
                                    checkedIcon={
                                      <CheckBoxIcon sx={{ fontSize: 17 }} />
                                    }
                                    name={amc.amc_id}
                                    type="checkbox"
                                    // checked={checkedAmcItems[amc.amc_full_name]}
                                    checked={amcFilters.includes(amc.amc_id)}
                                    onChange={handleAmcFilters}
                                  />
                                }
                                label={
                                  <Typography
                                    variant="body1"
                                    sx={{ fontSize: 13 }}
                                  >
                                    {amc.amc_full_name}
                                  </Typography>
                                }
                              />
                            </FormGroup>
                          </div>
                        ))
                    )
                  ) : (
                    <>No Data Found</>
                  )}
                </div>
              </div>
            </>
          )}
          {value === 1 && (
            <>
              <div className="mfmtabs col-12 pull-left">
                <div className="categorymanager col-12 pull-left">
                  <div class="to-dos listing col-12 pull-left">
                    {fundsCategory.length > 0 ? (
                      fundsCategory.map((categoryName, index) => (
                        <div
                          class="to-dos-entry listingrow"
                          style={{ marginBottom: "-10px" }}
                        >
                          <FormGroup key={index}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  icon={
                                    <SquareOutlinedIcon sx={{ fontSize: 17 }} />
                                  }
                                  checkedIcon={
                                    <CheckBoxIcon sx={{ fontSize: 17 }} />
                                  }
                                  name={categoryName.primary_category_name}
                                  type="checkbox"
                                  // checked={
                                  //   checkedItems[categoryName.primary_category_name]
                                  // }
                                  checked={selectedFilters.includes(
                                    categoryName.primary_category_name
                                  )}
                                  onChange={handleChange}
                                />
                              }
                              label={
                                <Typography
                                  variant="body1"
                                  sx={{ fontSize: 13 }}
                                >
                                  {categoryName.primary_category_name}
                                </Typography>
                              }
                            />
                          </FormGroup>
                        </div>
                      ))
                    ) : (
                      <>No Data Found</>
                    )}
                  </div>
                  {/* <AccordionDetails className="p-0">
                    <Typography>
                      <Accordion className="filteraccord">
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          className="h-auto p-0"
                        >
                          <Typography className="col-12">
                            <div class="to-dos align-items-start col-12 pull-left d-flex justify-content-between">
                              <div class="to-dos-entry listingrow">
                                <input
                                  type="checkbox"
                                  name="check"
                                  id="firsts"
                                />
                                <label for="firsts" class="label-text">
                                  Debt (17)
                                  <svg
                                    width="12px"
                                    height="10px"
                                    class="checkmark"
                                  >
                                    <polyline points="1,5 6,9 14,1" />
                                  </svg>
                                </label>
                              </div>
                            </div>
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails className="pt-0">
                          <Typography>
                            <div class="to-dos listing col-12 pull-left mt-3">
                              <div class="to-dos-entry listingrow">
                                <input
                                  type="checkbox"
                                  name="check"
                                  id="first"
                                />
                                <label for="first" class="label-text">
                                  <span>360 ONE Mutual Fund</span>
                                  <svg
                                    width="12px"
                                    height="10px"
                                    class="checkmark"
                                  >
                                    <polyline points="1,5 6,9 14,1" />
                                  </svg>
                                </label>
                              </div>
                              <div class="to-dos-entry listingrow">
                                <input
                                  type="checkbox"
                                  name="check"
                                  id="second"
                                />
                                <label for="second" class="label-text">
                                  <span>Aditya Birla Sun Life Mutual Fund</span>
                                  <svg
                                    width="12px"
                                    height="10px"
                                    class="checkmark"
                                  >
                                    <polyline points="1,5 6,9 14,1" />
                                  </svg>
                                </label>
                              </div>
                              <div class="to-dos-entry listingrow">
                                <input
                                  type="checkbox"
                                  name="check"
                                  id="third"
                                />
                                <label for="third" class="label-text">
                                  <span>Axis Mutual Fund</span>
                                  <svg
                                    width="12px"
                                    height="10px"
                                    class="checkmark"
                                  >
                                    <polyline points="1,5 6,9 14,1" />
                                  </svg>
                                </label>
                              </div>
                              <div class="to-dos-entry listingrow">
                                <input
                                  type="checkbox"
                                  name="check"
                                  id="forth"
                                />
                                <label for="forth" class="label-text">
                                  <span>BNP Paribas Mutual FundM</span>
                                  <svg
                                    width="12px"
                                    height="10px"
                                    class="checkmark"
                                  >
                                    <polyline points="1,5 6,9 14,1" />
                                  </svg>
                                </label>
                              </div>
                              <div class="to-dos-entry listingrow">
                                <input
                                  type="checkbox"
                                  name="check"
                                  id="fifth"
                                />
                                <label for="fifth" class="label-text">
                                  <span>Bajaj Finserv Mutual Fund</span>
                                  <svg
                                    width="12px"
                                    height="10px"
                                    class="checkmark"
                                  >
                                    <polyline points="1,5 6,9 14,1" />
                                  </svg>
                                </label>
                              </div>
                              <div class="to-dos-entry listingrow">
                                <input type="checkbox" name="check" id="six" />
                                <label for="six" class="label-text">
                                  <span>Bandhan Mutual Fund</span>
                                  <svg
                                    width="12px"
                                    height="10px"
                                    class="checkmark"
                                  >
                                    <polyline points="1,5 6,9 14,1" />
                                  </svg>
                                </label>
                              </div>
                              <div class="to-dos-entry listingrow">
                                <input
                                  type="checkbox"
                                  name="check"
                                  id="Seven"
                                />
                                <label for="Seven" class="label-text">
                                  <span>Bank of India Mutual Fund</span>
                                  <svg
                                    width="12px"
                                    height="10px"
                                    class="checkmark"
                                  >
                                    <polyline points="1,5 6,9 14,1" />
                                  </svg>
                                </label>
                              </div>
                              <div class="to-dos-entry listingrow">
                                <input
                                  type="checkbox"
                                  name="check"
                                  id="Seven"
                                />
                                <label for="Seven" class="label-text">
                                  <span>Bank of India Mutual Fund</span>
                                  <svg
                                    width="12px"
                                    height="10px"
                                    class="checkmark"
                                  >
                                    <polyline points="1,5 6,9 14,1" />
                                  </svg>
                                </label>
                              </div>
                              <div class="to-dos-entry listingrow">
                                <input
                                  type="checkbox"
                                  name="check"
                                  id="first"
                                />
                                <label for="first" class="label-text">
                                  <span>360 ONE Mutual Fund</span>
                                  <svg
                                    width="12px"
                                    height="10px"
                                    class="checkmark"
                                  >
                                    <polyline points="1,5 6,9 14,1" />
                                  </svg>
                                </label>
                              </div>
                              <div class="to-dos-entry listingrow">
                                <input
                                  type="checkbox"
                                  name="check"
                                  id="second"
                                />
                                <label for="second" class="label-text">
                                  <span>Aditya Birla Sun Life Mutual Fund</span>
                                  <svg
                                    width="12px"
                                    height="10px"
                                    class="checkmark"
                                  >
                                    <polyline points="1,5 6,9 14,1" />
                                  </svg>
                                </label>
                              </div>
                              <div class="to-dos-entry listingrow">
                                <input
                                  type="checkbox"
                                  name="check"
                                  id="third"
                                />
                                <label for="third" class="label-text">
                                  <span>Axis Mutual Fund</span>
                                  <svg
                                    width="12px"
                                    height="10px"
                                    class="checkmark"
                                  >
                                    <polyline points="1,5 6,9 14,1" />
                                  </svg>
                                </label>
                              </div>
                              <div class="to-dos-entry listingrow">
                                <input
                                  type="checkbox"
                                  name="check"
                                  id="forth"
                                />
                                <label for="forth" class="label-text">
                                  <span>BNP Paribas Mutual FundM</span>
                                  <svg
                                    width="12px"
                                    height="10px"
                                    class="checkmark"
                                  >
                                    <polyline points="1,5 6,9 14,1" />
                                  </svg>
                                </label>
                              </div>
                              <div class="to-dos-entry listingrow">
                                <input
                                  type="checkbox"
                                  name="check"
                                  id="fifth"
                                />
                                <label for="fifth" class="label-text">
                                  <span>Bajaj Finserv Mutual Fund</span>
                                  <svg
                                    width="12px"
                                    height="10px"
                                    class="checkmark"
                                  >
                                    <polyline points="1,5 6,9 14,1" />
                                  </svg>
                                </label>
                              </div>
                              <div class="to-dos-entry listingrow">
                                <input type="checkbox" name="check" id="six" />
                                <label for="six" class="label-text">
                                  <span>Bandhan Mutual Fund</span>
                                  <svg
                                    width="12px"
                                    height="10px"
                                    class="checkmark"
                                  >
                                    <polyline points="1,5 6,9 14,1" />
                                  </svg>
                                </label>
                              </div>
                              <div class="to-dos-entry listingrow">
                                <input
                                  type="checkbox"
                                  name="check"
                                  id="Seven"
                                />
                                <label for="Seven" class="label-text">
                                  <span>Bank of India Mutual Fund</span>
                                  <svg
                                    width="12px"
                                    height="10px"
                                    class="checkmark"
                                  >
                                    <polyline points="1,5 6,9 14,1" />
                                  </svg>
                                </label>
                              </div>
                              <div class="to-dos-entry listingrow">
                                <input
                                  type="checkbox"
                                  name="check"
                                  id="Seven"
                                />
                                <label for="Seven" class="label-text">
                                  <span>Bank of India Mutual Fund</span>
                                  <svg
                                    width="12px"
                                    height="10px"
                                    class="checkmark"
                                  >
                                    <polyline points="1,5 6,9 14,1" />
                                  </svg>
                                </label>
                              </div>
                            </div>
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    </Typography>
                  </AccordionDetails>
                  <AccordionDetails className="p-0">
                    <Typography>
                      <Accordion className="filteraccord">
                        <AccordionSummary
                          aria-controls="panel1a-content"
                          expandIcon={<ExpandMoreIcon />}
                          id="panel1a-header"
                          className="h-auto p-0"
                        >
                          <Typography className="col-12">
                            <div class="to-dos align-items-start col-12 pull-left d-flex justify-content-between">
                              <div class="to-dos-entry listingrow">
                                <input
                                  type="checkbox"
                                  name="check"
                                  id="seconds"
                                />
                                <label for="seconds" class="label-text">
                                  Equity (12)
                                  <svg
                                    width="12px"
                                    height="10px"
                                    class="checkmark"
                                  >
                                    <polyline points="1,5 6,9 14,1" />
                                  </svg>
                                </label>
                              </div>
                            </div>
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails className="pt-0">
                          <Typography>
                            <div class="to-dos listing col-12 pull-left mt-3">
                              <div class="to-dos-entry listingrow">
                                <input
                                  type="checkbox"
                                  name="check"
                                  id="first"
                                />
                                <label for="first" class="label-text">
                                  <span>360 ONE Mutual Fund</span>
                                  <svg
                                    width="12px"
                                    height="10px"
                                    class="checkmark"
                                  >
                                    <polyline points="1,5 6,9 14,1" />
                                  </svg>
                                </label>
                              </div>
                              <div class="to-dos-entry listingrow">
                                <input
                                  type="checkbox"
                                  name="check"
                                  id="second"
                                />
                                <label for="second" class="label-text">
                                  <span>Aditya Birla Sun Life Mutual Fund</span>
                                  <svg
                                    width="12px"
                                    height="10px"
                                    class="checkmark"
                                  >
                                    <polyline points="1,5 6,9 14,1" />
                                  </svg>
                                </label>
                              </div>
                              <div class="to-dos-entry listingrow">
                                <input
                                  type="checkbox"
                                  name="check"
                                  id="third"
                                />
                                <label for="third" class="label-text">
                                  <span>Axis Mutual Fund</span>
                                  <svg
                                    width="12px"
                                    height="10px"
                                    class="checkmark"
                                  >
                                    <polyline points="1,5 6,9 14,1" />
                                  </svg>
                                </label>
                              </div>
                              <div class="to-dos-entry listingrow">
                                <input
                                  type="checkbox"
                                  name="check"
                                  id="forth"
                                />
                                <label for="forth" class="label-text">
                                  <span>BNP Paribas Mutual FundM</span>
                                  <svg
                                    width="12px"
                                    height="10px"
                                    class="checkmark"
                                  >
                                    <polyline points="1,5 6,9 14,1" />
                                  </svg>
                                </label>
                              </div>
                              <div class="to-dos-entry listingrow">
                                <input
                                  type="checkbox"
                                  name="check"
                                  id="fifth"
                                />
                                <label for="fifth" class="label-text">
                                  <span>Bajaj Finserv Mutual Fund</span>
                                  <svg
                                    width="12px"
                                    height="10px"
                                    class="checkmark"
                                  >
                                    <polyline points="1,5 6,9 14,1" />
                                  </svg>
                                </label>
                              </div>
                              <div class="to-dos-entry listingrow">
                                <input type="checkbox" name="check" id="six" />
                                <label for="six" class="label-text">
                                  <span>Bandhan Mutual Fund</span>
                                  <svg
                                    width="12px"
                                    height="10px"
                                    class="checkmark"
                                  >
                                    <polyline points="1,5 6,9 14,1" />
                                  </svg>
                                </label>
                              </div>
                              <div class="to-dos-entry listingrow">
                                <input
                                  type="checkbox"
                                  name="check"
                                  id="Seven"
                                />
                                <label for="Seven" class="label-text">
                                  <span>Bank of India Mutual Fund</span>
                                  <svg
                                    width="12px"
                                    height="10px"
                                    class="checkmark"
                                  >
                                    <polyline points="1,5 6,9 14,1" />
                                  </svg>
                                </label>
                              </div>
                              <div class="to-dos-entry listingrow">
                                <input
                                  type="checkbox"
                                  name="check"
                                  id="Seven"
                                />
                                <label for="Seven" class="label-text">
                                  <span>Bank of India Mutual Fund</span>
                                  <svg
                                    width="12px"
                                    height="10px"
                                    class="checkmark"
                                  >
                                    <polyline points="1,5 6,9 14,1" />
                                  </svg>
                                </label>
                              </div>
                              <div class="to-dos-entry listingrow">
                                <input
                                  type="checkbox"
                                  name="check"
                                  id="first"
                                />
                                <label for="first" class="label-text">
                                  <span>360 ONE Mutual Fund</span>
                                  <svg
                                    width="12px"
                                    height="10px"
                                    class="checkmark"
                                  >
                                    <polyline points="1,5 6,9 14,1" />
                                  </svg>
                                </label>
                              </div>
                              <div class="to-dos-entry listingrow">
                                <input
                                  type="checkbox"
                                  name="check"
                                  id="second"
                                />
                                <label for="second" class="label-text">
                                  <span>Aditya Birla Sun Life Mutual Fund</span>
                                  <svg
                                    width="12px"
                                    height="10px"
                                    class="checkmark"
                                  >
                                    <polyline points="1,5 6,9 14,1" />
                                  </svg>
                                </label>
                              </div>
                              <div class="to-dos-entry listingrow">
                                <input
                                  type="checkbox"
                                  name="check"
                                  id="third"
                                />
                                <label for="third" class="label-text">
                                  <span>Axis Mutual Fund</span>
                                  <svg
                                    width="12px"
                                    height="10px"
                                    class="checkmark"
                                  >
                                    <polyline points="1,5 6,9 14,1" />
                                  </svg>
                                </label>
                              </div>
                              <div class="to-dos-entry listingrow">
                                <input
                                  type="checkbox"
                                  name="check"
                                  id="forth"
                                />
                                <label for="forth" class="label-text">
                                  <span>BNP Paribas Mutual FundM</span>
                                  <svg
                                    width="12px"
                                    height="10px"
                                    class="checkmark"
                                  >
                                    <polyline points="1,5 6,9 14,1" />
                                  </svg>
                                </label>
                              </div>
                              <div class="to-dos-entry listingrow">
                                <input
                                  type="checkbox"
                                  name="check"
                                  id="fifth"
                                />
                                <label for="fifth" class="label-text">
                                  <span>Bajaj Finserv Mutual Fund</span>
                                  <svg
                                    width="12px"
                                    height="10px"
                                    class="checkmark"
                                  >
                                    <polyline points="1,5 6,9 14,1" />
                                  </svg>
                                </label>
                              </div>
                              <div class="to-dos-entry listingrow">
                                <input type="checkbox" name="check" id="six" />
                                <label for="six" class="label-text">
                                  <span>Bandhan Mutual Fund</span>
                                  <svg
                                    width="12px"
                                    height="10px"
                                    class="checkmark"
                                  >
                                    <polyline points="1,5 6,9 14,1" />
                                  </svg>
                                </label>
                              </div>
                              <div class="to-dos-entry listingrow">
                                <input
                                  type="checkbox"
                                  name="check"
                                  id="Seven"
                                />
                                <label for="Seven" class="label-text">
                                  <span>Bank of India Mutual Fund</span>
                                  <svg
                                    width="12px"
                                    height="10px"
                                    class="checkmark"
                                  >
                                    <polyline points="1,5 6,9 14,1" />
                                  </svg>
                                </label>
                              </div>
                              <div class="to-dos-entry listingrow">
                                <input
                                  type="checkbox"
                                  name="check"
                                  id="Seven"
                                />
                                <label for="Seven" class="label-text">
                                  <span>Bank of India Mutual Fund</span>
                                  <svg
                                    width="12px"
                                    height="10px"
                                    class="checkmark"
                                  >
                                    <polyline points="1,5 6,9 14,1" />
                                  </svg>
                                </label>
                              </div>
                            </div>
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    </Typography>
                  </AccordionDetails>
                  <AccordionDetails className="p-0">
                    <Typography>
                      <Accordion className="filteraccord">
                        <AccordionSummary
                          aria-controls="panel1a-content"
                          expandIcon={<ExpandMoreIcon />}
                          id="panel1a-header"
                          className="h-auto p-0"
                        >
                          <Typography className="col-12">
                            <div class="to-dos align-items-start col-12 pull-left d-flex justify-content-between">
                              <div class="to-dos-entry listingrow">
                                <input
                                  type="checkbox"
                                  name="check"
                                  id="thirds"
                                />
                                <label for="thirds" class="label-text">
                                  Hybrid (7)
                                  <svg
                                    width="12px"
                                    height="10px"
                                    class="checkmark"
                                  >
                                    <polyline points="1,5 6,9 14,1" />
                                  </svg>
                                </label>
                              </div>
                            </div>
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails className="pt-0">
                          <Typography>
                            <div class="to-dos listing col-12 pull-left mt-3">
                              <div class="to-dos-entry listingrow">
                                <input
                                  type="checkbox"
                                  name="check"
                                  id="first"
                                />
                                <label for="first" class="label-text">
                                  <span>360 ONE Mutual Fund</span>
                                  <svg
                                    width="12px"
                                    height="10px"
                                    class="checkmark"
                                  >
                                    <polyline points="1,5 6,9 14,1" />
                                  </svg>
                                </label>
                              </div>
                              <div class="to-dos-entry listingrow">
                                <input
                                  type="checkbox"
                                  name="check"
                                  id="second"
                                />
                                <label for="second" class="label-text">
                                  <span>Aditya Birla Sun Life Mutual Fund</span>
                                  <svg
                                    width="12px"
                                    height="10px"
                                    class="checkmark"
                                  >
                                    <polyline points="1,5 6,9 14,1" />
                                  </svg>
                                </label>
                              </div>
                              <div class="to-dos-entry listingrow">
                                <input
                                  type="checkbox"
                                  name="check"
                                  id="third"
                                />
                                <label for="third" class="label-text">
                                  <span>Axis Mutual Fund</span>
                                  <svg
                                    width="12px"
                                    height="10px"
                                    class="checkmark"
                                  >
                                    <polyline points="1,5 6,9 14,1" />
                                  </svg>
                                </label>
                              </div>
                              <div class="to-dos-entry listingrow">
                                <input
                                  type="checkbox"
                                  name="check"
                                  id="forth"
                                />
                                <label for="forth" class="label-text">
                                  <span>BNP Paribas Mutual FundM</span>
                                  <svg
                                    width="12px"
                                    height="10px"
                                    class="checkmark"
                                  >
                                    <polyline points="1,5 6,9 14,1" />
                                  </svg>
                                </label>
                              </div>
                              <div class="to-dos-entry listingrow">
                                <input
                                  type="checkbox"
                                  name="check"
                                  id="fifth"
                                />
                                <label for="fifth" class="label-text">
                                  <span>Bajaj Finserv Mutual Fund</span>
                                  <svg
                                    width="12px"
                                    height="10px"
                                    class="checkmark"
                                  >
                                    <polyline points="1,5 6,9 14,1" />
                                  </svg>
                                </label>
                              </div>
                              <div class="to-dos-entry listingrow">
                                <input type="checkbox" name="check" id="six" />
                                <label for="six" class="label-text">
                                  <span>Bandhan Mutual Fund</span>
                                  <svg
                                    width="12px"
                                    height="10px"
                                    class="checkmark"
                                  >
                                    <polyline points="1,5 6,9 14,1" />
                                  </svg>
                                </label>
                              </div>
                              <div class="to-dos-entry listingrow">
                                <input
                                  type="checkbox"
                                  name="check"
                                  id="Seven"
                                />
                                <label for="Seven" class="label-text">
                                  <span>Bank of India Mutual Fund</span>
                                  <svg
                                    width="12px"
                                    height="10px"
                                    class="checkmark"
                                  >
                                    <polyline points="1,5 6,9 14,1" />
                                  </svg>
                                </label>
                              </div>
                              <div class="to-dos-entry listingrow">
                                <input
                                  type="checkbox"
                                  name="check"
                                  id="Seven"
                                />
                                <label for="Seven" class="label-text">
                                  <span>Bank of India Mutual Fund</span>
                                  <svg
                                    width="12px"
                                    height="10px"
                                    class="checkmark"
                                  >
                                    <polyline points="1,5 6,9 14,1" />
                                  </svg>
                                </label>
                              </div>
                              <div class="to-dos-entry listingrow">
                                <input
                                  type="checkbox"
                                  name="check"
                                  id="first"
                                />
                                <label for="first" class="label-text">
                                  <span>360 ONE Mutual Fund</span>
                                  <svg
                                    width="12px"
                                    height="10px"
                                    class="checkmark"
                                  >
                                    <polyline points="1,5 6,9 14,1" />
                                  </svg>
                                </label>
                              </div>
                              <div class="to-dos-entry listingrow">
                                <input
                                  type="checkbox"
                                  name="check"
                                  id="second"
                                />
                                <label for="second" class="label-text">
                                  <span>Aditya Birla Sun Life Mutual Fund</span>
                                  <svg
                                    width="12px"
                                    height="10px"
                                    class="checkmark"
                                  >
                                    <polyline points="1,5 6,9 14,1" />
                                  </svg>
                                </label>
                              </div>
                              <div class="to-dos-entry listingrow">
                                <input
                                  type="checkbox"
                                  name="check"
                                  id="third"
                                />
                                <label for="third" class="label-text">
                                  <span>Axis Mutual Fund</span>
                                  <svg
                                    width="12px"
                                    height="10px"
                                    class="checkmark"
                                  >
                                    <polyline points="1,5 6,9 14,1" />
                                  </svg>
                                </label>
                              </div>
                              <div class="to-dos-entry listingrow">
                                <input
                                  type="checkbox"
                                  name="check"
                                  id="forth"
                                />
                                <label for="forth" class="label-text">
                                  <span>BNP Paribas Mutual FundM</span>
                                  <svg
                                    width="12px"
                                    height="10px"
                                    class="checkmark"
                                  >
                                    <polyline points="1,5 6,9 14,1" />
                                  </svg>
                                </label>
                              </div>
                              <div class="to-dos-entry listingrow">
                                <input
                                  type="checkbox"
                                  name="check"
                                  id="fifth"
                                />
                                <label for="fifth" class="label-text">
                                  <span>Bajaj Finserv Mutual Fund</span>
                                  <svg
                                    width="12px"
                                    height="10px"
                                    class="checkmark"
                                  >
                                    <polyline points="1,5 6,9 14,1" />
                                  </svg>
                                </label>
                              </div>
                              <div class="to-dos-entry listingrow">
                                <input type="checkbox" name="check" id="six" />
                                <label for="six" class="label-text">
                                  <span>Bandhan Mutual Fund</span>
                                  <svg
                                    width="12px"
                                    height="10px"
                                    class="checkmark"
                                  >
                                    <polyline points="1,5 6,9 14,1" />
                                  </svg>
                                </label>
                              </div>
                              <div class="to-dos-entry listingrow">
                                <input
                                  type="checkbox"
                                  name="check"
                                  id="Seven"
                                />
                                <label for="Seven" class="label-text">
                                  <span>Bank of India Mutual Fund</span>
                                  <svg
                                    width="12px"
                                    height="10px"
                                    class="checkmark"
                                  >
                                    <polyline points="1,5 6,9 14,1" />
                                  </svg>
                                </label>
                              </div>
                              <div class="to-dos-entry listingrow">
                                <input
                                  type="checkbox"
                                  name="check"
                                  id="Seven"
                                />
                                <label for="Seven" class="label-text">
                                  <span>Bank of India Mutual Fund</span>
                                  <svg
                                    width="12px"
                                    height="10px"
                                    class="checkmark"
                                  >
                                    <polyline points="1,5 6,9 14,1" />
                                  </svg>
                                </label>
                              </div>
                            </div>
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    </Typography>
                  </AccordionDetails> */}
                </div>
              </div>
            </>
          )}

          {value === 2 && (
            <>
              <div className="mftabs col-12 pull-left">
                <div class="to-dos listing col-12 pull-left">
                  {allRiskData.length > 0 ? (
                    allRiskData.map((risk, index) => (
                      <div
                        class="to-dos-entry listingrow"
                        style={{ marginBottom: "-10px" }}
                      >
                        <FormGroup key={index}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                icon={
                                  <SquareOutlinedIcon sx={{ fontSize: 17 }} />
                                }
                                checkedIcon={
                                  <CheckBoxIcon sx={{ fontSize: 17 }} />
                                }
                                name={risk.frequency}
                                type="checkbox"
                                // checked={
                                //   checkedRiskItems[risk.name]
                                // }
                                checked={containesElement(risk.frequency)}
                                onChange={handleRiskFilters}
                              />
                            }
                            label={
                              <Typography variant="body1" sx={{ fontSize: 13 }}>
                                {risk.name}
                              </Typography>
                            }
                          />
                        </FormGroup>
                      </div>
                    ))
                  ) : (
                    <>No Data Found</>
                  )}
                </div>
              </div>
            </>
          )}
          {value === 3 && (
            <>
              <div className="mfmtabs col-12 pull-left">
                <div class="to-dos listing col-12 pull-left">
                  {investData.length > 0 ? (
                    investData.map((invest, index) => (
                      <div
                        class="to-dos-entry listingrow"
                        style={{ marginBottom: "-10px" }}
                      >
                        <FormGroup key={index}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                icon={
                                  <SquareOutlinedIcon sx={{ fontSize: 17 }} />
                                }
                                checkedIcon={
                                  <CheckBoxIcon sx={{ fontSize: 17 }} />
                                }
                                name={invest.flag}
                                type="checkbox"
                                // checked={checkedInvestItems[invest.name]}
                                checked={sipFilters.includes(invest?.flag)}
                                onChange={handleInvestFilters}
                              />
                            }
                            label={
                              <Typography variant="body1" sx={{ fontSize: 13 }}>
                                {invest.name}
                              </Typography>
                            }
                          />
                        </FormGroup>
                      </div>
                    ))
                  ) : (
                    <>No Data Found</>
                  )}
                </div>
              </div>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};
