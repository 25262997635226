import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../../../../components/Footer";
import {
  convertDate,
  formatNumber,
  validatePAN,
} from "../../../../components/common";
import { useState } from "react";
import { FormHelperText, TextField } from "@mui/material";
import axios from "axios";
import { UpiIDs } from "../../../../components/common/upiHandle";
import { errorMessages } from "../../../../utils/constants";
import { toast } from "react-toastify";
import { validatePattern, ValidateVPA } from "bhimupijs";
import { useContext } from "react";
import { leadApi, sendOtpApi } from "../../../../utils/APICollection";
import { Context } from "../../../../context";
import Header from "../../../../components/Header";

export const ApplyIpo = () => {
  const openIPOStock = JSON.parse(sessionStorage.getItem("stock"));
  const navigate = useNavigate();

  let discount = 0;

  const [panClientCode, setPanClientCode] = useState("");
  const [mobile, setMobile] = useState("");
  const [clientName, setClientName] = useState("");
  const [dematBeneficieryId, setDematBeneficieryId] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [price, setPrice] = useState("");
  const [price1, setPrice1] = useState("");
  const [price2, setPrice2] = useState("");
  const [data, setData] = useState([]);
  const [error, setError] = useState("");
  const [upiData, setUPIData] = useState("");
  const [checked, setChecked] = useState(true);
  const { exchange, name, num, setMobileError } = useContext(Context);

  const userDetails = async () => {
    try {
      const res = await axios.post(
        "https://ipo.smcindiaonline.org:8327/api/client/info",
        {
          code: panClientCode,
        }
      );
      setData(res?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const onPanClientCodeChange = (e) => {
    setPanClientCode(e.target.value.toUpperCase());
    setError("");
  };

  const handleKeyDown = (event) => {
    const panValue = validatePAN(panClientCode);
    if (!panValue) {
      setError("Invalid pan ");
    } else if (event.key === "Enter") {
      userDetails();
    }
  };

  const onMobileChange = (e) => {
    setMobile(e.target.value);
    setError("");
  };

  const onClientNameChange = (e) => {
    setClientName(e.target.value);
    setError("");
  };

  const onDematBeneficieryId = (e) => {
    setDematBeneficieryId(e.target.value);
    setError("");
  };

  const onQuantityChange = (e) => {
    setQuantity(e.target.value);
    setError("");
  };

  function validateUPI(vpa) {
    const { isQueryPatternValid } = validatePattern(vpa);
    return isQueryPatternValid;
  }

  const verifyUPI = () => {
    const isValid = validateUPI(upiData);
    if (!isValid) {
      toast.error("Invalid Upi");
    } else {
      toast.success("Verified");
    }
  };

  const bid1Details = {
    activityType: "new",
    atCutOff: checked,
    quantity: "",
    price: "",
    amount: 0,
  };

  const bid2Details = {
    activityType: "new",
    atCutOff: checked,
    quantity: "",
    price: "",
    amount: 0,
  };

  const bid3Details = {
    activityType: "new",
    atCutOff: checked,
    quantity: "",
    price: "",
    amount: 0,
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      symbol: openIPOStock[0].companySymbol,
      category: "IND",
      isin: openIPOStock[0].isIn,
      mobile: mobile.toString(),
      clientName: clientName,
      upi: upiData,
      pan: panClientCode,
      referenceNumber: "1234567887654321",
      exchange: exchange,
    };

    if (data?.clientBenId.slice(0, 2).toLowerCase() === "in") {
      payload.depository = "NSDL";
      payload.clientBenId = data?.clientBenId.slice(8, 16);
      payload.dpid = data?.clientBenId.slice(0, 8);
    } else {
      payload.depository = "CDSL";
      payload.dpid = "";
      payload.clientBenId = data?.clientBenId;
    }

    bid1Details.amount = price * openIPOStock[0].lotSize;
    bid2Details.amount = price1 * openIPOStock[0].lotSize;
    bid3Details.amount = price2 * openIPOStock[0].lotSize;

    const bids = [];
    if (price) {
      const finalBid1Details = { ...bid1Details };
      finalBid1Details.quantity = (
        Number(finalBid1Details.quantity) * openIPOStock[0].lotSize
      ).toString();

      //handling requests type for both exchange(NSE/BSE)
      if (exchange == "NSE") {
        //cutoff is chekced then no need to send price field
        if (finalBid1Details.atCutOff) delete finalBid1Details.price;
        bids.push(finalBid1Details);
      }
    }
    if (price1) {
      const finalBid2Details = { ...bid2Details };
      finalBid2Details.quantity = (
        Number(bid2Details.quantity) * openIPOStock[0].lotSize
      ).toString();
      if (exchange == "NSE") {
        if (finalBid2Details.atCutOff) delete finalBid2Details.price;
        bids.push(finalBid2Details);
      }
    }

    if (price2) {
      const finalBid3Details = { ...bid3Details };
      finalBid3Details.quantity = (
        Number(bid3Details.quantity) * openIPOStock[0].lotSize
      ).toString();
      if (exchange == "NSE") {
        if (finalBid3Details.atCutOff) delete finalBid3Details.price;
        bids.push(finalBid3Details);
      }
    }
    if (bids.length > 0) payload.bids = bids;
    payload.ipoTotalAmount =
      Math.max(price, price1, price2) * openIPOStock[0].lotSize;
    try {
      const res = await axios.post(
        "https://ipo.smcindiaonline.org:8327/api/ipomaster/apply",
        payload
      );
    } catch (error) {
      console.log(error);
    }
    //   depository: "CDSL",
    //   dpid: "",
    //   clientBenId: data?.clientBenId,
    //   party: "", //if exist then send
    //   email: "", //only for registered client which will be getting from client api
    //   bids: [
    //     {
    //       activityType: "new",
    //       atCutOff: false,
    //       quantity: "2000",
    //       price: "61",
    //       amount: 122000,
    //     },
    //   ],
    //   ipoTotalAmount: 122000,
    // };
  };
  // React.useEffect(() => {
  //   StateComponent("IN");
  //   CityComponent("IN");
  // }, []);

  return (
    <>
      <Header />

      <section id="banner">
        <div className="banner internal bauto">
          <div className="container">
            <div className="bredcrum">
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/invest/ipo">Upcoming Ipo</Link>
                </li>
                <li>Apply Ipo</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <main className="p-100">
        <section id="main">
          <div
            className="webcent"
            id="Account"
            data-aos="fade-in"
            data-aos-offset="200"
            data-aos-duration="600"
            data-aos-once="true"
            data-aos-easing="ease-in-sine"
          >
            <div className="container">
              <div className="row">
                <div className="web-container">
                  <div className="ipoapplyfrm">
                    <div className="leftcross">
                      <div className="ipoapplycont">
                        <h3>{openIPOStock[0].companySymbol}</h3>
                        <p>
                          <strong>{openIPOStock[0].companyName}</strong>
                        </p>
                        <ul>
                          <li>
                            <span>Issue Date</span>
                            <span>{openIPOStock[0].date}</span>
                          </li>
                          <li>
                            <span>Issue Size(no of shares)</span>
                            <span>
                              {formatNumber(openIPOStock[0].issueSize)}
                            </span>
                          </li>
                          <li>
                            <span>Issue Price Range </span>
                            <span>₹{openIPOStock[0].priceRange}</span>
                          </li>
                          <li>
                            <span>Lot Size </span>
                            <span>{formatNumber(openIPOStock[0].lotSize)}</span>
                          </li>
                          <li>
                            <span>Offer Start Date </span>
                            <span>
                              {convertDate(openIPOStock[0].offerStartDate)}
                            </span>
                          </li>
                          <li>
                            <span>Offer End Date </span>
                            <span>
                              {convertDate(openIPOStock[0].offerEndDate)}
                            </span>
                          </li>
                          <li>
                            <span>Allotment Date </span>
                            <span>
                              {convertDate(openIPOStock[0].allotmentDate)}
                            </span>
                          </li>
                          <li>
                            <span>Listing Date </span>
                            <span>
                              {convertDate(openIPOStock[0].listingDate)}
                            </span>
                          </li>
                          <li>
                            <input type="checkbox" />
                            <p>
                              I hereby undertake that I have read the Red
                              Herring Prospectus and I am an eligible UPI bidder
                              as per the applicable provisions of the SEBI
                              (Issue of Capital and Disclosure Requirement)
                              Regulation, 2018.
                            </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="rightsection">
                      <form onSubmit={handleSubmit}>
                        <div className="ipoapplication">
                          <div className="form p-0">
                            <div className="formbg  bg-white rounded-0 p-0">
                              <h4>IPO Application</h4>
                              <ul>
                                <li>
                                  <TextField
                                    id="standard-basic"
                                    placeholder="PAN/Client Code"
                                    variant="standard"
                                    value={panClientCode?.slice(0, 10)}
                                    error={error ? true : false}
                                    onChange={onPanClientCodeChange}
                                    helperText={error}
                                    required
                                    onKeyDown={handleKeyDown}
                                  />
                                </li>
                                <li>
                                  <TextField
                                    type="number"
                                    id="standard-basic"
                                    name="mobile"
                                    placeholder="Mobile Number"
                                    variant="standard"
                                    value={mobile ? mobile : data?.mobile}
                                    onChange={onMobileChange}
                                    required
                                  />
                                </li>
                                <li>
                                  <TextField
                                    type="text"
                                    id="standard-basic"
                                    name="clientName"
                                    placeholder="Client Name"
                                    variant="standard"
                                    value={
                                      clientName ? clientName : data?.clientName
                                    }
                                    onChange={onClientNameChange}
                                    required
                                  />
                                </li>
                                <li>
                                  <TextField
                                    type="text"
                                    id="standard-basic"
                                    name="dematBeneficieryId"
                                    placeholder="Demat/Beneficiary Id"
                                    variant="standard"
                                    value={
                                      dematBeneficieryId
                                        ? dematBeneficieryId
                                        : data?.clientBenId
                                    }
                                    onChange={onDematBeneficieryId}
                                    required
                                  />
                                </li>
                                <li>
                                  <select>
                                    <option>Individual/Retail</option>
                                  </select>
                                </li>
                                <li>
                                  <ul>
                                    <li>
                                      <TextField
                                        type="number"
                                        id="standard-basic"
                                        name="quantity"
                                        label="Quantity"
                                        value={quantity}
                                        variant="standard"
                                        onChange={onQuantityChange}
                                        required
                                      />

                                      <span>
                                        <p>
                                          1 Lot = {openIPOStock[0].lotSize}{" "}
                                          Shares
                                        </p>
                                      </span>
                                    </li>
                                    <li>
                                      <TextField
                                        type="number"
                                        id="standard-basic"
                                        name="price"
                                        label="Price"
                                        variant="standard"
                                        onChange={(e) =>
                                          setPrice(e.target.value)
                                        }
                                        value={
                                          checked
                                            ? openIPOStock[0].minPrice
                                            : price
                                        }
                                        disabled={checked}
                                      />

                                      <span>
                                        <input
                                          type="checkbox"
                                          style={{
                                            width: "20px",
                                            height: "13px",
                                          }}
                                          checked={checked}
                                          onChange={() => setChecked(!checked)}
                                        />
                                        <p
                                          style={{
                                            position: "relative",
                                            top: "-4px",
                                          }}
                                        >
                                          At CutOff Price
                                        </p>
                                      </span>
                                    </li>

                                    {!checked ? (
                                      <li>
                                        <ul>
                                          <li>
                                            <TextField
                                              type="number"
                                              id="standard-basic"
                                              name="quantity"
                                              label="Quantity"
                                              value={quantity}
                                              variant="standard"
                                              onChange={onQuantityChange}
                                              required
                                            />

                                            <span>
                                              <p>
                                                1 Lot ={" "}
                                                {openIPOStock[0].lotSize} Shares
                                              </p>
                                            </span>
                                          </li>
                                          <li>
                                            <TextField
                                              type="number"
                                              id="standard-basic"
                                              name="price"
                                              label="Price"
                                              variant="standard"
                                              onChange={(e) =>
                                                setPrice1(e.target.value)
                                              }
                                              value={price1}
                                            />

                                            <span>
                                              <input
                                                type="checkbox"
                                                checked={checked}
                                                onChange={() =>
                                                  setChecked(!checked)
                                                }
                                              />
                                              <p>At CutOff Price</p>
                                            </span>
                                          </li>
                                        </ul>
                                        <ul>
                                          <li>
                                            <TextField
                                              type="number"
                                              id="standard-basic"
                                              name="quantity"
                                              label="Quantity"
                                              value={quantity}
                                              variant="standard"
                                              onChange={onQuantityChange}
                                              required
                                            />

                                            <span>
                                              <p>
                                                1 Lot ={" "}
                                                {openIPOStock[0].lotSize} Shares
                                              </p>
                                            </span>
                                          </li>
                                          <li>
                                            <TextField
                                              type="number"
                                              id="standard-basic"
                                              name="price"
                                              label="Price"
                                              variant="standard"
                                              onChange={(e) =>
                                                setPrice2(e.target.value)
                                              }
                                              value={price2}
                                            />

                                            <span>
                                              <input
                                                type="checkbox"
                                                checked={checked}
                                                onChange={() =>
                                                  setChecked(!checked)
                                                }
                                              />
                                              <p>At CutOff Price</p>
                                            </span>
                                          </li>
                                        </ul>
                                      </li>
                                    ) : null}
                                  </ul>
                                </li>
                                <li>
                                  <ul>
                                    <li>
                                      <input
                                        type="text"
                                        placeholder="Eg- Abc@oksbi"
                                        value={upiData}
                                        onChange={(e) =>
                                          setUPIData(e.target.value)
                                        }
                                      />
                                      <span className="btnn">
                                        <span onClick={verifyUPI}>Verify</span>
                                      </span>
                                    </li>
                                  </ul>
                                </li>
                                <li>
                                  <ul>
                                    <li>
                                      <span>Total Amount</span>
                                      <span>
                                        <i className="finr"></i>{" "}
                                        {quantity * openIPOStock[0].minAmount}
                                      </span>
                                    </li>
                                    <li>
                                      <span>Discount</span>
                                      <span>
                                        <i className="finr"></i> {discount}
                                      </span>
                                    </li>
                                    <li>
                                      <span>Amount Payable</span>
                                      <span>
                                        <i className="finr"></i>{" "}
                                        {quantity * openIPOStock[0].minAmount -
                                          discount}
                                      </span>
                                    </li>
                                  </ul>
                                </li>
                                <li>
                                  <button type="submit">Start Now</button>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
};
