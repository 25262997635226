import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Quant from "../images/quant.webp";
import Footer from "./Footer";
import DematForm from "./DematForm";
import { Form } from "./Form";
import Header from "./Header";
import axios from "axios";
import { ThreeDots } from "react-loader-spinner";
import { Context } from "../context";
import { useContext } from "react";
import {
  getBioData,
  getExpiryDateData,
  getOptionChainData,
  getQuatesData,
  getQuoteDerivativesData,
} from "../utils/APICollection";

export const Optionchain = () => {
  const [sidebarTop, setSidebarTop] = useState();
  const [shouldAddClass, setShouldAddClass] = useState(false);
  const [sidebarWidth, setSidebarWidth] = useState();
  const [optionChainData, setOptionChainData] = useState([]);
  const [expiryDates, setExpiryDates] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");
  const [bioData, setBioData] = useState([]);
  const [derivativesData, setDerivativesData] = useState([]);
  const [ipoLoading, setIpoLoading] = useState(false);
  const [quatesData, setQuatesData] = useState({});
  const [nearestStrikePrice, setNearestStrikePrice] = useState(0);
  const { exchange } = useContext(Context);

  const fetchOptionChainData = async () => {
    setIpoLoading(true);
    try {
      const res = await getOptionChainData(
        window.location.pathname.split("/")[4],
        selectedDate
      );
      setOptionChainData(res?.data?.data);
      setIpoLoading(false);
      const result = res?.data?.data.reduce((prev, curr) => {
        return Math.abs(curr.strikeprice - quatesData?.Price) <
          Math.abs(prev.strikeprice - quatesData?.Price)
          ? curr
          : prev;
      });
      setNearestStrikePrice(result?.strikeprice);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchExpiryDate = async () => {
    try {
      const res = await getExpiryDateData(
        window.location.pathname.split("/")[4]
      );
      setSelectedDate(res?.data?.data[0]?.expdate.split("T")[0]);
      setExpiryDates(res?.data?.data);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchBioData = async () => {
    try {
      const res = await getBioData(window.location.pathname.split("/")[5]);
      setBioData(res?.data?.data[0]);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchDerivativesData = async () => {
    try {
      const res = await getQuoteDerivativesData(
        window.location.pathname.split("/")[4]
      );
      setDerivativesData(res?.data?.data);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchQuatesData = async () => {
    try {
      const res = await getQuatesData(
        window.location.pathname.split("/")[5],
        exchange
      );
      setQuatesData(res?.data?.data[0]);
    } catch (err) {
      console.log(err);
    }
  };

  // const nearestStrikePrice = optionChainData.reduce((prev, curr) => {
  //   return Math.abs(curr.strikeprice - quatesData?.Price) < Math.abs(prev.strikeprice - quatesData?.Price) ? curr : prev;
  // });

  useEffect(() => {
    fetchExpiryDate();
    fetchBioData();
    fetchDerivativesData();
    fetchQuatesData();
  }, []);

  useEffect(() => {
    if (selectedDate.length > 0 && quatesData?.Price > 0) {
      fetchOptionChainData();
    }
  }, [selectedDate, quatesData]);

  useEffect(() => {
    const sidebarEl = document.querySelector(".opendemat");

    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const shouldAdd = scrollTop >= sidebarTop - 10;

      setShouldAddClass(shouldAdd);
    };

    if (sidebarEl) {
      const sidebarRect = sidebarEl.getBoundingClientRect();
      setSidebarWidth(sidebarRect.width);
      setSidebarTop(sidebarRect.top);

      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, [sidebarTop]);

  useEffect(() => {
    if (!sidebarTop) return;

    const handleScroll = () => {
      const sidebarEl = document.querySelector(".opendemat");
      const sectionEl = document.querySelector(".ipodashboard");
      const faq_cnt = document.querySelector(".tablist");

      const iporow = document.querySelector(".ipodashboard .row");
      const scrollTop = window.scrollY;
      const shouldAdd = scrollTop >= sidebarTop - 10;

      setShouldAddClass(shouldAdd);

      if (shouldAdd) {
        sidebarEl.classList.add("is-sticky");
      } else {
        sidebarEl.classList.remove("is-sticky");
      }

      if (
        scrollTop >=
        sectionEl.offsetTop +
          sectionEl.offsetHeight -
          faq_cnt.offsetHeight / 5.8
      ) {
        sidebarEl.classList.remove("is-sticky");
        iporow.classList.add("flex-end");
      } else {
        iporow.classList.remove("flex-end");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [sidebarTop]);

  const [isOpen, setIsOpen] = useState(false);

  const handleDropdownClick = (e) => {
    if (e.target.className === "dropdown-toggle") {
      setIsOpen(!isOpen);
    } else {
      setIsOpen(false);
    }
  };
  const locationId = window.location.pathname.split("/")[5];

  const filterDateFormat = (date) => {
    let d = new Date(date);
    let monthList = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "July",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return d.getDate() + " " + monthList[d.getMonth()];
  };

  return (
    <>
      <Header />

      <section id="banner" onClick={handleDropdownClick}>
        <div className="banner internal bauto">
          <div className="container">
            <div className="bredcrum">
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/invest">Invest</Link>
                </li>
                <li>
                  <Link to="/invest/Equities">Equity</Link>
                </li>
                <li>
                  <Link to={`/invest/Equities/${locationId}`}>
                    {bioData.LNAME}
                  </Link>
                </li>
                <li>Option Chain</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <main onClick={handleDropdownClick}>
        <section id="main">
          <div className="ipoportfolio">
            <div className="container">
              <div className="web-container">
                <div className="ipodashboard">
                  <div className="row">
                    <div className="ipoleft ipo8box">
                      <div className="ipospace">
                        <div className="webcent">
                          <div className="ipostable">
                            <div className="tablisthead ipotable">
                              <ul>
                                <li className="d-flex align-items-center justify-content-between">
                                  <div className="ipologo w-auto">
                                    {/* logo will be used in future */}
                                    {/* <span>
                                      <img src={Quant} alt="" />
                                    </span> */}
                                    <div className="mdoverviewheading  w-auto">
                                      <h2 className="m-0 text-white">
                                        {bioData?.LNAME}
                                      </h2>
                                    </div>
                                  </div>
                                  <div className="expiryfilter">
                                    <div className="dropdown-container">
                                      <span
                                        onClick={handleDropdownClick}
                                        className="dropdown-toggle"
                                      >
                                        Expiry{" "}
                                        {selectedDate === ""
                                          ? "NA"
                                          : filterDateFormat(selectedDate)}
                                      </span>
                                      {isOpen && (
                                        <div className="dropdown-content">
                                          {/* <p className="active">2023-03-28</p> */}
                                          {expiryDates?.map((date) => (
                                            <p
                                              className={
                                                selectedDate ===
                                                date?.expdate.split("T")[0]
                                                  ? "active"
                                                  : ""
                                              }
                                              onClick={() => {
                                                // handleDropdownClick();
                                                setSelectedDate(
                                                  date?.expdate.split("T")[0]
                                                );
                                              }}
                                            >
                                              {/* {date?.expdate.split("T")[0]} */}
                                              {filterDateFormat(date?.expdate)}
                                            </p>
                                          ))}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                            <div className="tablechain mt-3">
                              <div className="table-container">
                                <table className="table-fixed col-12 table">
                                  <thead
                                    className="grey-background"
                                    style={{ background: "#fafafa" }}
                                  >
                                    <tr>
                                      <th>OI (lots)</th>
                                      <th>CALL PRICE</th>
                                      <th>STRIKE PRICE</th>
                                      <th>PUT PRICE</th>
                                      <th>OI (lots)</th>
                                    </tr>
                                  </thead>
                                  <tbody className="tablebody">
                                    {ipoLoading ? (
                                      <ThreeDots
                                        height="450"
                                        width="90"
                                        radius="10"
                                        color="#3AA29F"
                                        ariaLabel="three-dots-loading"
                                        wrapperStyle={{
                                          display: "flex",
                                          justifyContent: "center",
                                        }}
                                        // wrapperClassName=""
                                        visible={true}
                                      />
                                    ) : Array.isArray(optionChainData) ? (
                                      optionChainData.map((item) => (
                                        <tr>
                                          <td>
                                            <div className="tablevalue">
                                              {item?.CallOI === null
                                                ? "-"
                                                : item?.CallOI}
                                            </div>
                                            <div className="tablevalue">
                                              {item?.CallOIChange}(
                                              {item?.CallOIPerChg === null
                                                ? "-"
                                                : item?.CallOIPerChg?.toFixed(
                                                    2
                                                  )}
                                              )%
                                            </div>
                                          </td>
                                          <td>
                                            <div className="tablevalue">
                                              <i className="finr"></i>
                                              {item?.CallLTP === null
                                                ? "-"
                                                : item?.CallLTP?.toFixed(2)}
                                            </div>
                                            <div
                                              className={
                                                Number(
                                                  item?.Callnetchange
                                                )?.toFixed(2) > 0
                                                  ? "tablevalue priceup"
                                                  : "tablevalue pricedown"
                                              }
                                            >
                                              {Number(
                                                item?.Callnetchange
                                              )?.toFixed(2)}
                                              (
                                              {Number(
                                                item?.CallPricePerChange
                                              )?.toFixed(2)}
                                              %)
                                            </div>
                                          </td>
                                          <td
                                            style={{
                                              textAlign: "center",
                                              position: "relative",
                                            }}
                                          >
                                            <div className="tablevalue">
                                              <strong>
                                                <i className="finr"></i>
                                                {item?.strikeprice}
                                              </strong>
                                              {item?.strikeprice ===
                                                nearestStrikePrice && (
                                                <div
                                                  style={{
                                                    width: "100%",
                                                    borderTop: "3px solid grey",
                                                    top: "10px",
                                                    marginTop: "25px",
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      width: "90px",
                                                      height: "30px",
                                                      backgroundColor: "grey",
                                                      borderRadius: "20px",
                                                      fontWeight: "500",
                                                      textAlign: "center",
                                                      padding: "4px 9px",
                                                      position: "absolute",
                                                      top: "50%",
                                                      left: "50%",
                                                      transform:
                                                        "translate(-50%, -50%)",
                                                      zIndex: "1",
                                                      marginTop: "20px",
                                                      color: "#FFFFFF",
                                                    }}
                                                  >
                                                    <i className="finr"></i>
                                                    {quatesData?.Price}
                                                  </div>
                                                </div>
                                              )}
                                            </div>
                                          </td>
                                          <td>
                                            <div className="tablevalue">
                                              <i className="finr"></i>
                                              {item?.PutLTP}
                                            </div>
                                            <div
                                              className={
                                                item?.Putnetchange?.toFixed(2) >
                                                0
                                                  ? "tablevalue priceup"
                                                  : "tablevalue pricedown"
                                              }
                                            >
                                              {item?.Putnetchange?.toFixed(2)}(
                                              {item?.PutPriceperChange?.toFixed(
                                                2
                                              )}
                                              %)
                                            </div>
                                          </td>
                                          <td>
                                            <div className="tablevalue">
                                              <i className="finr"></i>
                                              {item?.PutOI}
                                            </div>
                                            <div>
                                              {item?.PutOIChange}(
                                              {item?.PutOIPerChg?.toFixed(2)}%)
                                            </div>
                                          </td>
                                        </tr>
                                      ))
                                    ) : (
                                      <>No Data Found</>
                                    )}

                                    {/* <tr>
                                      <td>
                                        <div className="tablevalue">12</div>
                                        <div className="tablevalue">0.00%</div>
                                      </td>
                                      <td>
                                        <div className="tablevalue">
                                          <i className="finr"></i>202.85
                                        </div>
                                        <div className="tablevalue priceup">
                                          +15.95(8.53%)
                                        </div>
                                      </td>
                                      <td>
                                        <div className="tablevalue">
                                          <strong>
                                            <i className="finr"></i>410.00
                                          </strong>
                                        </div>
                                      </td>
                                      <td>
                                        <div className="tablevalue">
                                          <i className="finr"></i>0.05
                                        </div>
                                        <div className="tablevalue">
                                          0.00(0.00%)
                                        </div>
                                      </td>
                                      <td>
                                        <div className="tablevalue">
                                          <i className="finr"></i>43
                                        </div>
                                        <div className="tablevalue">
                                          0.43(0.00%)
                                        </div>
                                      </td>
                                    </tr> */}
                                    {/* <tr>
                                      <td>
                                        <div className="tablevalue">12</div>
                                        <div className="tablevalue">0.00%</div>
                                      </td>
                                      <td>
                                        <div className="tablevalue">
                                          <i className="finr"></i>202.85
                                        </div>
                                        <div className="tablevalue priceup">
                                          +15.95(8.53%)
                                        </div>
                                      </td>
                                      <td>
                                        <div className="tablevalue">
                                          <strong>
                                            <i className="finr"></i>410.00
                                          </strong>
                                        </div>
                                      </td>
                                      <td>
                                        <div className="tablevalue">
                                          <i className="finr"></i>0.05
                                        </div>
                                        <div className="tablevalue">
                                          0.00(0.00%)
                                        </div>
                                      </td>
                                      <td>
                                        <div className="tablevalue">
                                          <i className="finr"></i>43
                                        </div>
                                        <div className="tablevalue">
                                          0.43(0.00%)
                                        </div>
                                      </td>
                                    </tr> */}
                                    {/* <tr>
                                      <td>
                                        <div className="tablevalue">12</div>
                                        <div className="tablevalue">0.00%</div>
                                      </td>
                                      <td>
                                        <div className="tablevalue">
                                          <i className="finr"></i>202.85
                                        </div>
                                        <div className="tablevalue priceup">
                                          +15.95(8.53%)
                                        </div>
                                      </td>
                                      <td>
                                        <div className="tablevalue">
                                          <strong>
                                            <i className="finr"></i>410.00
                                          </strong>
                                        </div>
                                      </td>
                                      <td>
                                        <div className="tablevalue">
                                          <i className="finr"></i>0.05
                                        </div>
                                        <div className="tablevalue">
                                          0.00(0.00%)
                                        </div>
                                      </td>
                                      <td>
                                        <div className="tablevalue">
                                          <i className="finr"></i>43
                                        </div>
                                        <div className="tablevalue">
                                          0.43(0.00%)
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div className="tablevalue">12</div>
                                        <div className="tablevalue">0.00%</div>
                                      </td>
                                      <td>
                                        <div className="tablevalue">
                                          <i className="finr"></i>202.85
                                        </div>
                                        <div className="tablevalue priceup">
                                          +15.95(8.53%)
                                        </div>
                                      </td>
                                      <td>
                                        <div className="tablevalue">
                                          <strong>
                                            <i className="finr"></i>410.00
                                          </strong>
                                        </div>
                                      </td>
                                      <td>
                                        <div className="tablevalue">
                                          <i className="finr"></i>0.05
                                        </div>
                                        <div className="tablevalue">
                                          0.00(0.00%)
                                        </div>
                                      </td>
                                      <td>
                                        <div className="tablevalue">
                                          <i className="finr"></i>43
                                        </div>
                                        <div className="tablevalue">
                                          0.43(0.00%)
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div className="tablevalue">12</div>
                                        <div className="tablevalue">0.00%</div>
                                      </td>
                                      <td>
                                        <div className="tablevalue">
                                          <i className="finr"></i>202.85
                                        </div>
                                        <div className="tablevalue priceup">
                                          +15.95(8.53%)
                                        </div>
                                      </td>
                                      <td>
                                        <div className="tablevalue">
                                          <strong>
                                            <i className="finr"></i>410.00
                                          </strong>
                                        </div>
                                      </td>
                                      <td>
                                        <div className="tablevalue">
                                          <i className="finr"></i>0.05
                                        </div>
                                        <div className="tablevalue">
                                          0.00(0.00%)
                                        </div>
                                      </td>
                                      <td>
                                        <div className="tablevalue">
                                          <i className="finr"></i>43
                                        </div>
                                        <div className="tablevalue">
                                          0.43(0.00%)
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div className="tablevalue">12</div>
                                        <div className="tablevalue">0.00%</div>
                                      </td>
                                      <td>
                                        <div className="tablevalue">
                                          <i className="finr"></i>202.85
                                        </div>
                                        <div className="tablevalue priceup">
                                          +15.95(8.53%)
                                        </div>
                                      </td>
                                      <td>
                                        <div className="tablevalue">
                                          <strong>
                                            <i className="finr"></i>410.00
                                          </strong>
                                        </div>
                                      </td>
                                      <td>
                                        <div className="tablevalue">
                                          <i className="finr"></i>0.05
                                        </div>
                                        <div className="tablevalue">
                                          0.00(0.00%)
                                        </div>
                                      </td>
                                      <td>
                                        <div className="tablevalue">
                                          <i className="finr"></i>43
                                        </div>
                                        <div className="tablevalue">
                                          0.43(0.00%)
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div className="tablevalue">12</div>
                                        <div className="tablevalue">0.00%</div>
                                      </td>
                                      <td>
                                        <div className="tablevalue">
                                          <i className="finr"></i>202.85
                                        </div>
                                        <div className="tablevalue priceup">
                                          +15.95(8.53%)
                                        </div>
                                      </td>
                                      <td>
                                        <div className="tablevalue">
                                          <strong>
                                            <i className="finr"></i>410.00
                                          </strong>
                                        </div>
                                      </td>
                                      <td>
                                        <div className="tablevalue">
                                          <i className="finr"></i>0.05
                                        </div>
                                        <div className="tablevalue">
                                          0.00(0.00%)
                                        </div>
                                      </td>
                                      <td>
                                        <div className="tablevalue">
                                          <i className="finr"></i>43
                                        </div>
                                        <div className="tablevalue">
                                          0.43(0.00%)
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div className="tablevalue">12</div>
                                        <div className="tablevalue">0.00%</div>
                                      </td>
                                      <td>
                                        <div className="tablevalue">
                                          <i className="finr"></i>202.85
                                        </div>
                                        <div className="tablevalue priceup">
                                          +15.95(8.53%)
                                        </div>
                                      </td>
                                      <td>
                                        <div className="tablevalue">
                                          <strong>
                                            <i className="finr"></i>410.00
                                          </strong>
                                        </div>
                                      </td>
                                      <td>
                                        <div className="tablevalue">
                                          <i className="finr"></i>0.05
                                        </div>
                                        <div className="tablevalue">
                                          0.00(0.00%)
                                        </div>
                                      </td>
                                      <td>
                                        <div className="tablevalue">
                                          <i className="finr"></i>43
                                        </div>
                                        <div className="tablevalue">
                                          0.43(0.00%)
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div className="tablevalue">12</div>
                                        <div className="tablevalue">0.00%</div>
                                      </td>
                                      <td>
                                        <div className="tablevalue">
                                          <i className="finr"></i>202.85
                                        </div>
                                        <div className="tablevalue priceup">
                                          +15.95(8.53%)
                                        </div>
                                      </td>
                                      <td>
                                        <div className="tablevalue">
                                          <strong>
                                            <i className="finr"></i>410.00
                                          </strong>
                                        </div>
                                      </td>
                                      <td>
                                        <div className="tablevalue">
                                          <i className="finr"></i>0.05
                                        </div>
                                        <div className="tablevalue">
                                          0.00(0.00%)
                                        </div>
                                      </td>
                                      <td>
                                        <div className="tablevalue">
                                          <i className="finr"></i>43
                                        </div>
                                        <div className="tablevalue">
                                          0.43(0.00%)
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div className="tablevalue">12</div>
                                        <div className="tablevalue">0.00%</div>
                                      </td>
                                      <td>
                                        <div className="tablevalue">
                                          <i className="finr"></i>202.85
                                        </div>
                                        <div className="tablevalue priceup">
                                          +15.95(8.53%)
                                        </div>
                                      </td>
                                      <td>
                                        <div className="tablevalue">
                                          <strong>
                                            <i className="finr"></i>410.00
                                          </strong>
                                        </div>
                                      </td>
                                      <td>
                                        <div className="tablevalue">
                                          <i className="finr"></i>0.05
                                        </div>
                                        <div className="tablevalue">
                                          0.00(0.00%)
                                        </div>
                                      </td>
                                      <td>
                                        <div className="tablevalue">
                                          <i className="finr"></i>43
                                        </div>
                                        <div className="tablevalue">
                                          0.43(0.00%)
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div className="tablevalue">12</div>
                                        <div className="tablevalue">0.00%</div>
                                      </td>
                                      <td>
                                        <div className="tablevalue">
                                          <i className="finr"></i>202.85
                                        </div>
                                        <div className="tablevalue priceup">
                                          +15.95(8.53%)
                                        </div>
                                      </td>
                                      <td>
                                        <div className="tablevalue">
                                          <strong>
                                            <i className="finr"></i>410.00
                                          </strong>
                                        </div>
                                      </td>
                                      <td>
                                        <div className="tablevalue">
                                          <i className="finr"></i>0.05
                                        </div>
                                        <div className="tablevalue">
                                          0.00(0.00%)
                                        </div>
                                      </td>
                                      <td>
                                        <div className="tablevalue">
                                          <i className="finr"></i>43
                                        </div>
                                        <div className="tablevalue">
                                          0.43(0.00%)
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div className="tablevalue">12</div>
                                        <div className="tablevalue">0.00%</div>
                                      </td>
                                      <td>
                                        <div className="tablevalue">
                                          <i className="finr"></i>202.85
                                        </div>
                                        <div className="tablevalue priceup">
                                          +15.95(8.53%)
                                        </div>
                                      </td>
                                      <td>
                                        <div className="tablevalue">
                                          <strong>
                                            <i className="finr"></i>410.00
                                          </strong>
                                        </div>
                                      </td>
                                      <td>
                                        <div className="tablevalue">
                                          <i className="finr"></i>0.05
                                        </div>
                                        <div className="tablevalue">
                                          0.00(0.00%)
                                        </div>
                                      </td>
                                      <td>
                                        <div className="tablevalue">
                                          <i className="finr"></i>43
                                        </div>
                                        <div className="tablevalue">
                                          0.43(0.00%)
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div className="tablevalue">12</div>
                                        <div className="tablevalue">0.00%</div>
                                      </td>
                                      <td>
                                        <div className="tablevalue">
                                          <i className="finr"></i>202.85
                                        </div>
                                        <div className="tablevalue priceup">
                                          +15.95(8.53%)
                                        </div>
                                      </td>
                                      <td>
                                        <div className="tablevalue">
                                          <strong>
                                            <i className="finr"></i>410.00
                                          </strong>
                                        </div>
                                      </td>
                                      <td>
                                        <div className="tablevalue">
                                          <i className="finr"></i>0.05
                                        </div>
                                        <div className="tablevalue">
                                          0.00(0.00%)
                                        </div>
                                      </td>
                                      <td>
                                        <div className="tablevalue">
                                          <i className="finr"></i>43
                                        </div>
                                        <div className="tablevalue">
                                          0.43(0.00%)
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div className="tablevalue">12</div>
                                        <div className="tablevalue">0.00%</div>
                                      </td>
                                      <td>
                                        <div className="tablevalue">
                                          <i className="finr"></i>202.85
                                        </div>
                                        <div className="tablevalue priceup">
                                          +15.95(8.53%)
                                        </div>
                                      </td>
                                      <td>
                                        <div className="tablevalue">
                                          <strong>
                                            <i className="finr"></i>410.00
                                          </strong>
                                        </div>
                                      </td>
                                      <td>
                                        <div className="tablevalue">
                                          <i className="finr"></i>0.05
                                        </div>
                                        <div className="tablevalue">
                                          0.00(0.00%)
                                        </div>
                                      </td>
                                      <td>
                                        <div className="tablevalue">
                                          <i className="finr"></i>43
                                        </div>
                                        <div className="tablevalue">
                                          0.43(0.00%)
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div className="tablevalue">12</div>
                                        <div className="tablevalue">0.00%</div>
                                      </td>
                                      <td>
                                        <div className="tablevalue">
                                          <i className="finr"></i>202.85
                                        </div>
                                        <div className="tablevalue priceup">
                                          +15.95(8.53%)
                                        </div>
                                      </td>
                                      <td>
                                        <div className="tablevalue">
                                          <strong>
                                            <i className="finr"></i>410.00
                                          </strong>
                                        </div>
                                      </td>
                                      <td>
                                        <div className="tablevalue">
                                          <i className="finr"></i>0.05
                                        </div>
                                        <div className="tablevalue">
                                          0.00(0.00%)
                                        </div>
                                      </td>
                                      <td>
                                        <div className="tablevalue">
                                          <i className="finr"></i>43
                                        </div>
                                        <div className="tablevalue">
                                          0.43(0.00%)
                                        </div>
                                      </td>
                                    </tr> */}
                                  </tbody>
                                </table>
                                {/* <div style={{ width: "100%", borderTop: "3px solid grey", position: "absolute", top: "215px" }}>
                                  <div style={{
                                    width: "21%",
                                    height: "26px",
                                    left: "39%",
                                    backgroundColor: "grey",
                                    borderRadius: "20px",
                                    fontWeight: "500",
                                    textAlign: "center",
                                    padding: "4px 9px",
                                    position: "absolute",
                                    top: "-12px"
                                  }}>{strikePriceValue}</div>
                                </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="webcent">
                          <div className="tablist">
                            <div className="calheading">
                              <h3>{bioData?.LNAME} Futures</h3>
                            </div>
                            <div className="opcfeature d-flex col-12 gap-5 mt-2">
                              {Array.isArray(derivativesData) ? (
                                derivativesData?.map((item) => (
                                  <div className="opcbox col-4 d-flex  flex-wrap">
                                    <div className="opcboxrow">
                                      <span>
                                        {item?.Symbol} <strong>FUT</strong>
                                      </span>
                                      <span>
                                        <i className="finr"></i> {item?.LTP}
                                      </span>
                                    </div>
                                    <div className="opcboxrow">
                                      <span>
                                        {filterDateFormat(item?.ExpDate)}
                                      </span>
                                      <span
                                        className={
                                          item?.FAOPerChange.toFixed(2) > 0
                                            ? "priceup"
                                            : "pricedown"
                                        }
                                      >
                                        {item?.FAOChange.toFixed(2)}(
                                        {item?.FAOPerChange.toFixed(2)}%)
                                      </span>
                                    </div>
                                  </div>
                                ))
                              ) : (
                                <>No Data Found</>
                              )}

                              {/* <div className="opcbox col-4 d-flex flex-wrap">
                                <div className="opcboxrow">
                                  <span>
                                    Tatamotors <strong>Fut</strong>
                                  </span>
                                  <span>
                                    <i className="finr"></i> 622.35
                                  </span>
                                </div>
                                <div className="opcboxrow">
                                  <span>27 Jul</span>
                                  <span className="priceup">9.20(1.50%)</span>
                                </div>
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="ipoleft ipo4box">
                      <Form catID="Stock" />
                      {/* <DematForm
                        addClass={shouldAddClass}
                        sidebarWidth={sidebarWidth}
                        catID="Stock"
                      /> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
};
