import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import { getCityImport } from "../../utils/APICollection";
import { useContext, useEffect, useState } from "react";
import { Context } from "../../context";
import { fontSize } from "@mui/system";

export default function CitySearch({ cityError, setCityError, color }) {
  const { city, setCity } = useContext(Context);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const [citySelected, setCitySelected] = useState(false);

  useEffect(() => {
    let active = true;
    if (!open || city.length < 3) {
      return;
    }

    setLoading(true);

    (async () => {
      try {
        const { data } = await getCityImport(city);

        if (data?.data.length > 0) {
          setOptions(data.data);
        } else {
          setOptions(["Other"]);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    })();

    return () => {
      active = false;
    };
  }, [city]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Autocomplete
      id="City"
      sx={{
        ".MuiAutocomplete-input": {
          color: { color },
          fontSize: "0.813rem",
        },
      }}
      open={open}
      // onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      isOptionEqualToValue={(option, value) => option === value}
      getOptionLabel={(option) => option}
      options={options}
      loading={loading}
      onInputChange={(event, newInputValue) => {
        setCity(newInputValue);
        if (newInputValue) {
          setOpen(true);
        } else {
          setOpen(false);
        }
        if (newInputValue !== "") {
          setCityError("");
        }
      }}
      filterOptions={(x) => x}
      renderInput={(params) => (
        <TextField
          {...params}
          label="City"
          variant="standard"
          error={!!cityError}
          helperText={cityError}
          InputLabelProps={{
            style: { fontSize: "13px" },
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}
