import { useState, useEffect } from "react";
import { formatNumber } from "../../../../components/common";
import { getCompetitivePeers } from "../../../../utils/APICollection";

const IpoCompetitivePeers = ({ isMobile }) => {
  const [peerData, setPeerData] = useState([]);

  const fetchPeerData = async () => {
    try {
      const data = await getCompetitivePeers(
        window.location.pathname.split("/")[4],
        5
      );
      setPeerData(data?.data?.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchPeerData();
  }, []);
  return (
    <>
      <h3>Competitive Peers</h3>
      <div className="tablist">
        <div className="ipostn">
          <div className={isMobile ? "ipotable defaulttable" : "ipotable"}>
            <table class="table" style={{ width: isMobile && "550px" }}>
              <thead>
                <tr>
                  <th scope="col">Company</th>
                  <th scope="col">Market Cap </th>
                  <th scope="col">LTP </th>
                </tr>
              </thead>
              <tbody>
                {peerData?.slice(1, peerData.length).map((item) => (
                  <tr>
                    <td>{item?.CompanyName}</td>
                    <td>
                      <i className="finr"></i>
                      {formatNumber(item?.MCAP)}
                    </td>
                    <td>
                      <i className="finr"></i>
                      {formatNumber(item?.LTP)}
                    </td>
                  </tr>
                ))}

                {/* <tr>
                                        <td>
                                          <strong className="mobile-show">
                                            Inventory Category
                                          </strong>
                                          Qualified Institutional Buyer
                                        </td>
                                        <td>
                                          <strong className="mobile-show">
                                            Subscription (Times)
                                          </strong>
                                          NA
                                        </td>
                                      </tr> */}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default IpoCompetitivePeers;
