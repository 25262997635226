import React from "react";
import Footer from "../../../../components/Footer";
import { Link, useParams } from "react-router-dom";
import { ResearchLink } from "../../../../components/ResearchLink";
import { PDF } from "../../../../images/svg/PDF";
import nodata from "../../../../images/nodata.webp";
import { HTML } from "../../../../images/svg/HTML";
import Newsletter from "../../../../components/Newsletter";
import {
  getFileUrl,
  getReportByCategory,
  getThumbnailFileUrl,
} from "../../../../utils/APICollection";
import { Context } from "../../../../context";
import NoImg from "../../../../images/noimage.jpg";
import { AWS_S3_RESEARCH_REPORT_FOLDER } from "../../../../utils/constants";
import Loader from "../../../../components/Loader";
import { Tooltip } from "@mui/material";
import Header from "../../../../components/Header";
import { Helmet } from "react-helmet";
import { formatDate } from "../../../../components/common/FormattedDate";

export const WiseMoney = () => {
  const { id } = useParams();
  const { researchData, setResearchData } = React.useContext(Context);
  const [wiseMoneyData, setWiseMoneyData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [thumbnailImg, setThumnailImg] = React.useState([]);

  const getResearchReportByCategory = async () => {
    setLoading(true);
    const { data } = await getReportByCategory(id);
    if (data?.status == "success") {
      let thumbnailFiles = await data?.data
        ?.flatMap((obj) => obj.files)
        ?.filter((item) => item.thumbnail_file !== null)
        ?.map((item) => item.thumbnail_file);
      setResearchData(data?.data);
      setWiseMoneyData(thumbnailFiles);
      setLoading(false);
    }
  };
  React.useEffect(() => {
    getResearchReportByCategory();
  }, [id]);

  let descriptions = researchData?.flatMap((obj) =>
    obj.files.map((file) => file.description)
  );

  let flatArr = researchData?.flatMap((obj) => obj.files);
  let result = flatArr?.map((item) => {
    let found = thumbnailImg?.find(
      (element) => element.file_name === item.thumbnail_file
    );
    if (found) {
      item.thumbnail_file = found.file_url;
    }
    return item;
  });

  const getUrl = async (fileName) => {
    setLoading(true);
    try {
      const { data } = await getFileUrl({
        file_name: fileName,
        folder_name: AWS_S3_RESEARCH_REPORT_FOLDER,
      });
      if (data?.statusCode === 200) {
        window.open(data?.data[0]?.file_url);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getThumbnailFile = async () => {
    setLoading(true);
    try {
      const { data } = await getThumbnailFileUrl({
        file_names: wiseMoneyData,
        folder_name: AWS_S3_RESEARCH_REPORT_FOLDER,
      });
      setThumnailImg(data?.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    getThumbnailFile();
  }, [wiseMoneyData]);

  const title = "Subscribe to Wise Money Newsletter - SMC Global Securities";
  const description =
    "Its a weekly newsletter includes all the segments like Equity, Commodity, Mutual Fund, Currency and Fixed Deposits which helps each and every investor to multiply their wealth.";

  return (
    <>
      <Header />
      <Helmet>
        <title>{title}</title>
        <link
          rel="canonical"
          href="https://uat-app.smctradeonline.com/research/wise-money/45"
        />

        <meta property="og:title" content={title} data-react-helmet="true" />
        <meta
          property="og:site_name"
          content="SMC Global Securities"
          data-react-helmet="true"
        />
        <meta
          property="og:url"
          content="https://uat-app.smctradeonline.com/research/wise-money/45"
          data-react-helmet="true"
        />
        <meta property="og:description" content={description} />
        <meta property="og:image" content="@website_new_logo_url" />
        <meta
          name="twitter:url"
          content="https://uat-app.smctradeonline.com/research/wise-money/45"
        />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content="https://example.com/image.jpg" />
        <meta name="twitter:card" content="summary_large_image" />
        <h1>News Letters</h1>
      </Helmet>
      <section id="banner">
        <div className="banner internal nobanner">
          <div className="container">
            <div className="bredcrum">
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/Research">Research</Link>
                </li>
                <li>Wise Money</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <main>
        <section id="main">
          <div
            className="webcent"
            data-aos="fade-up"
            data-aos-offset="80"
            data-aos-duration="600"
            data-aos-once="true"
            data-aos-easing="ease-in-sine"
          >
            <div className="container">
              <div className="row">
                <div className="web-container">
                  <div
                    className="reportpage m-auto col-lg-12 col-12 d-flex justify-content-between"
                    id="wisemoney"
                  >
                    <div className="reportmenu gradient">
                      <ResearchLink />
                    </div>
                    {researchData?.length < 1 && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100vw",
                          flexDirection: "column",
                        }}
                      >
                        <img
                          src={nodata}
                          style={{ width: "300px", alignSelf: "center" }}
                        />
                        <h3>No Data Found</h3>
                      </div>
                    )}
                    <div className="reportcontain">
                      {researchData?.length > 0 && (
                        <div className="boxsetup col-12 pull-left">
                          <div className="title justify-content-start text-start m-0">
                            <h3>Wise Money</h3>
                          </div>
                          {descriptions ? (
                            descriptions[0]
                          ) : (
                            <p className="col-12 pull-left">
                              It is a weekly newsletter, which is an concise on
                              investment opportunities escorted by sound
                              technical and fundamental analysis of existing and
                              forthcoming stocks that keep investors at the
                              height of market developments. It includes all the
                              segments say Equity, Commodity, Mutual Fund,
                              Currency and Fixed Deposits. It is a complete
                              handy guide, which helps each and every investor
                              to multiply their wealth.
                            </p>
                          )}
                        </div>
                      )}
                      <div className="clearfix"></div>
                      <div
                        className="col-lg-12 col-12 pull-left scrollusewise"
                        style={{ marginTop: 10 }}
                      >
                        <div className="reportfiles pull-left col-12">
                          {result?.map((item, i) => {
                            return (
                              <div className="rfiles">
                                <div className="rfimg">
                                  {item?.thumbnail_file ? (
                                    <img
                                      src={item?.thumbnail_file}
                                      alt={item.title}
                                      width={150}
                                      height={150}
                                    />
                                  ) : (
                                    <img
                                      src={NoImg}
                                      alt={i}
                                      width={150}
                                      height={150}
                                    />
                                  )}
                                </div>
                                <div className="rftitle">
                                  <p>
                                    <strong>{item.title}</strong>
                                  </p>
                                  <span>{formatDate(item.date)} </span>
                                </div>
                                <div className="rfbottom">
                                  <span>
                                    {item.english_pdf_file && (
                                      <Tooltip title="English PDF" arrow>
                                        <span style={{ cursor: "pointer" }}>
                                          <PDF
                                            onClick={() =>
                                              getUrl(item.english_pdf_file)
                                            }
                                          />
                                        </span>
                                      </Tooltip>
                                    )}
                                    &nbsp;
                                    {item.hindi_pdf_file && (
                                      <Tooltip title="Hindi PDF" arrow>
                                        <span style={{ cursor: "pointer" }}>
                                          <PDF
                                            onClick={() =>
                                              getUrl(item.hindi_pdf_file)
                                            }
                                          />
                                        </span>
                                      </Tooltip>
                                    )}
                                    &nbsp;
                                    {item.html_hindi_file && (
                                      <Tooltip title="Hindi HTML" arrow>
                                        <span style={{ cursor: "pointer" }}>
                                          <HTML
                                            onClick={() =>
                                              getUrl(item.html_hindi_file)
                                            }
                                          />
                                        </span>
                                      </Tooltip>
                                    )}
                                    &nbsp;
                                    {item.html_english_file && (
                                      <Tooltip title="English HTML" arrow>
                                        <span style={{ cursor: "pointer" }}>
                                          <HTML
                                            onClick={() =>
                                              getUrl(item.html_english_file)
                                            }
                                          />
                                        </span>
                                      </Tooltip>
                                    )}
                                  </span>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Newsletter catID="Research" setFileLoading={setLoading} />
        </section>
      </main>
      <Footer />
      {loading && <Loader />}
    </>
  );
};
