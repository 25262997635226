import React, { useEffect, useState } from "react";
import Footer from "../../../components/Footer";
import { Link } from "react-router-dom";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import Header from "../../../components/Header";
import { ThreeDots } from "react-loader-spinner";
import SEO from "../../../components/common/SEO";
import { seoData } from "../../../utils/Data";
import { getNseHoliday } from "../../../utils/APICollection";

const InvestHolidaysNSEHolidays = () => {
  const [nseHolidaysData, setNseHolidaysData] = useState([]);
  const [ipoLoading, setIpoLoading] = useState(false);
  useEffect(() => {
    fetchNseHolidaysData();
  }, []);

  const fetchNseHolidaysData = async () => {
    setIpoLoading(true);
    try {
      const res = await getNseHoliday();
      setNseHolidaysData(res?.data?.data);
      setIpoLoading(false);
    } catch (err) {
      console.log(err);
    }
  };
  const monthList = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const getDate = (dateValue) => {
    let date = new Date(dateValue);
    return (
      date.getDate() +
      " " +
      monthList[date.getMonth()] +
      " " +
      date.getFullYear()
    );
  };
  const getMonthIndex = (dateValue) => {
    let date = new Date(dateValue);
    return date.getMonth();
  };

  return (
    <>
      <Header />
      <SEO
        title={seoData.nseHolidays.title}
        description={seoData.nseHolidays.description}
        name="Company name."
        type="article"
        url={seoData.nseHolidays.url}
        image={seoData.nseHolidays.image}
      />

      <section id="banner">
        <div className="banner internal mpadding">
          <div className="container">
            <div className="bredcrum">
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/invest">Invest</Link>
                </li>
                <li>NSE Holidays</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <main>
        <section id="main">
          <div className="webcent">
            <div className="container">
              <div className="row">
                <div className="web-container">
                  <div className="col-lg-10 m-auto">
                    <div className="title">
                      <h3>NSE Holidays 2024</h3>
                    </div>
                    {ipoLoading ? (
                      <ThreeDots
                        height="90"
                        width="90"
                        radius="10"
                        color="#3AA29F"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                        // wrapperClassName=""
                        visible={true}
                      />
                    ) : (
                      <div className="ipostable holidays">
                        <div className="tablist gap-sm-4 d-flex flex-wrap">
                          <div className="ipostn p-0">
                            <div className="ipotitle">
                              <h4>NSE Holidays 2024 list</h4>
                            </div>
                            <div className="ipotable defaulttable">
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th>Holidays</th>
                                    <th>Date</th>
                                    <th>Day</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {nseHolidaysData?.map((holiday) => (
                                    <tr>
                                      <td> {holiday?.Purpose}</td>
                                      <td>{getDate(holiday?.HolidayDate)}</td>
                                      <td>{holiday?.day}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div className="ipostn">
                            <div className="ipotitle">
                              <h4>
                                Following public holidays are falling on
                                Saturday / Sunday:
                              </h4>
                            </div>
                            {/* <div className="ipotable defaulttable">
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th>Holiday</th>
                                    <th>Date</th>
                                    <th>Day</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {nseHolidaysData
                                    ?.filter(
                                      (holiday) => holiday.day === "Sunday"
                                    )
                                    .map((holiday) => (
                                      <tr>
                                        <td>{holiday?.Purpose}</td>
                                        <td>{getDate(holiday?.HolidayDate)}</td>
                                        <td>{holiday?.day}</td>
                                      </tr>
                                    ))}
                                </tbody>
                              </table>
                            </div> */}
                            <div className="ipotable defaulttable">
                              <table class="table">
                                <thead>
                                  <tr>
                                    <th scope="col">Holidays</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Day</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>Dr. Baba Saheb Ambedkar Jayanti</td>
                                    <td>14-Apr-2024</td>
                                    <td>Sunday</td>
                                  </tr>
                                  <tr>
                                    <td>Shri Mahavir Jayanti</td>
                                    <td>21-Apr-2024</td>
                                    <td>Sunday</td>
                                  </tr>
                                  <tr>
                                    <td>Ganesh Chaturthi</td>
                                    <td>07-Sep-2024</td>
                                    <td>Saturday</td>
                                  </tr>
                                  <tr>
                                    <td>Dussehra</td>
                                    <td>12-Oct-2024</td>
                                    <td>Saturday</td>
                                  </tr>
                                  <tr>
                                    <td>Diwali-Balipratipada</td>
                                    <td>02-Nov-2024</td>
                                    <td>Saturday</td>
                                  </tr>
                                </tbody>
                              </table>
                              <small>
                                *The regular trading timings are from 9:15 am to
                                3:30 pm
                                <br />* The Muhurat Trading day timings will be
                                notified closer to Diwali by the Exchanges.
                              </small>
                            </div>
                          </div>
                          <div className="ipostn">
                            <div className="ipotitle">
                              <h4>NSE Holidays In January 2024</h4>
                            </div>
                            <div className="ipotable defaulttable">
                              <table class="table">
                                <thead>
                                  <tr>
                                    <th scope="col">Holidays</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Day</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {nseHolidaysData
                                    ?.filter(
                                      (holiday) =>
                                        getMonthIndex(holiday.HolidayDate) === 0
                                    )
                                    .map((holiday) => (
                                      <tr>
                                        <td>{holiday?.Purpose}</td>
                                        <td>{getDate(holiday?.HolidayDate)}</td>
                                        <td>{holiday?.day}</td>
                                      </tr>
                                    ))}
                                </tbody>
                              </table>{" "}
                            </div>
                          </div>
                          {/* <div className="ipostn">
                            <div className="ipotitle">
                              <h4>NSE Holidays In February 2024</h4>
                            </div>
                            <div className="ipotable defaulttable">
                              <table class="table">
                                <thead>
                                  <tr>
                                    <th scope="col">Holidays</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Day</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {nseHolidaysData
                                    ?.filter(
                                      (holiday) =>
                                        getMonthIndex(holiday.HolidayDate) === 1
                                    )
                                    .map((holiday) => (
                                      <tr>
                                        <td>{holiday?.Purpose}</td>
                                        <td>{getDate(holiday?.HolidayDate)}</td>
                                        <td>{holiday?.day}</td>
                                      </tr>
                                    ))}
                                </tbody>
                              </table>
                            </div>
                          </div> */}
                          <div className="ipostn">
                            <div className="ipotitle">
                              <h4>NSE Holidays In March 2024</h4>
                            </div>
                            <div className="ipotable defaulttable">
                              <table class="table">
                                <thead>
                                  <tr>
                                    <th scope="col">Holidays</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Day</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {nseHolidaysData
                                    ?.filter(
                                      (holiday) =>
                                        getMonthIndex(holiday.HolidayDate) === 2
                                    )
                                    .map((holiday) => (
                                      <tr>
                                        <td>{holiday?.Purpose}</td>
                                        <td>{getDate(holiday?.HolidayDate)}</td>
                                        <td>{holiday?.day}</td>
                                      </tr>
                                    ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div className="ipostn">
                            <div className="ipotitle">
                              <h4>NSE Holidays In April 2024</h4>
                            </div>
                            <div className="ipotable defaulttable">
                              <table class="table">
                                <thead>
                                  <tr>
                                    <th scope="col">Holidays</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Day</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {nseHolidaysData
                                    ?.filter(
                                      (holiday) =>
                                        getMonthIndex(holiday.HolidayDate) === 3
                                    )
                                    .map((holiday) => (
                                      <tr>
                                        <td>{holiday?.Purpose}</td>
                                        <td>{getDate(holiday?.HolidayDate)}</td>
                                        <td>{holiday?.day}</td>
                                      </tr>
                                    ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div className="ipostn">
                            <div className="ipotitle">
                              <h4>NSE Holidays In May 2024</h4>
                            </div>
                            <div className="ipotable defaulttable">
                              <table class="table">
                                <thead>
                                  <tr>
                                    <th scope="col">Holidays</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Day</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {nseHolidaysData
                                    ?.filter(
                                      (holiday) =>
                                        getMonthIndex(holiday.HolidayDate) === 4
                                    )
                                    .map((holiday) => (
                                      <tr>
                                        <td>{holiday?.Purpose}</td>
                                        <td>{getDate(holiday?.HolidayDate)}</td>
                                        <td>{holiday?.day}</td>
                                      </tr>
                                    ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div className="ipostn">
                            <div className="ipotitle">
                              <h4>NSE Holidays In June 2024</h4>
                            </div>
                            <div className="ipotable defaulttable">
                              <table class="table">
                                <thead>
                                  <tr>
                                    <th scope="col">Holidays</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Day</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {nseHolidaysData
                                    ?.filter(
                                      (holiday) =>
                                        getMonthIndex(holiday.HolidayDate) === 5
                                    )
                                    .map((holiday) => (
                                      <tr>
                                        <td>{holiday?.Purpose}</td>
                                        <td>{getDate(holiday?.HolidayDate)}</td>
                                        <td>{holiday?.day}</td>
                                      </tr>
                                    ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div className="ipostn">
                            <div className="ipotitle">
                              <h4>NSE Holidays In July 2024</h4>
                            </div>
                            <div className="ipotable defaulttable">
                              <table class="table">
                                <thead>
                                  <tr>
                                    <th scope="col">Holidays</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Day</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {nseHolidaysData
                                    ?.filter(
                                      (holiday) =>
                                        getMonthIndex(holiday.HolidayDate) === 6
                                    )
                                    .map((holiday) => (
                                      <tr>
                                        <td>{holiday?.Purpose}</td>
                                        <td>{getDate(holiday?.HolidayDate)}</td>
                                        <td>{holiday?.day}</td>
                                      </tr>
                                    ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div className="ipostn">
                            <div className="ipotitle">
                              <h4>NSE Holidays In August 2024</h4>
                            </div>
                            <div className="ipotable defaulttable">
                              <table class="table">
                                <thead>
                                  <tr>
                                    <th scope="col">Holidays</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Day</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {nseHolidaysData
                                    ?.filter(
                                      (holiday) =>
                                        getMonthIndex(holiday.HolidayDate) === 7
                                    )
                                    .map((holiday) => (
                                      <tr>
                                        <td>{holiday?.Purpose}</td>
                                        <td>{getDate(holiday?.HolidayDate)}</td>
                                        <td>{holiday?.day}</td>
                                      </tr>
                                    ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                          {/* <div className="ipostn">
                            <div className="ipotitle">
                              <h4>NSE Holidays In September 2024</h4>
                            </div>
                            <div className="ipotable defaulttable">
                              <table class="table">
                                <thead>
                                  <tr>
                                    <th scope="col">Holidays</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Day</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {nseHolidaysData
                                    ?.filter(
                                      (holiday) =>
                                        getMonthIndex(holiday.HolidayDate) === 8
                                    )
                                    .map((holiday) => (
                                      <tr>
                                        <td>{holiday?.Purpose}</td>
                                        <td>{getDate(holiday?.HolidayDate)}</td>
                                        <td>{holiday?.day}</td>
                                      </tr>
                                    ))}
                                </tbody>
                              </table>
                            </div>
                          </div> */}
                          <div className="ipostn">
                            <div className="ipotitle">
                              <h4>NSE Holidays In October 2024</h4>
                            </div>
                            <div className="ipotable defaulttable">
                              <table class="table">
                                <thead>
                                  <tr>
                                    <th scope="col">Holidays</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Day</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {nseHolidaysData
                                    ?.filter(
                                      (holiday) =>
                                        getMonthIndex(holiday.HolidayDate) === 9
                                    )
                                    .map((holiday) => (
                                      <tr>
                                        <td>{holiday?.Purpose}</td>
                                        <td>{getDate(holiday?.HolidayDate)}</td>
                                        <td>{holiday?.day}</td>
                                      </tr>
                                    ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div className="ipostn">
                            <div className="ipotitle">
                              <h4>NSE Holidays In November 2024</h4>
                            </div>
                            <div className="ipotable defaulttable">
                              <table class="table">
                                <thead>
                                  <tr>
                                    <th scope="col">Holidays</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Day</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {nseHolidaysData
                                    ?.filter(
                                      (holiday) =>
                                        getMonthIndex(holiday.HolidayDate) ===
                                        10
                                    )
                                    .map((holiday) => (
                                      <tr>
                                        <td>{holiday?.Purpose}</td>
                                        <td>{getDate(holiday?.HolidayDate)}</td>
                                        <td>{holiday?.day}</td>
                                      </tr>
                                    ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div className="ipostn">
                            <div className="ipotitle">
                              <h4>NSE Holidays In December 2024</h4>
                            </div>
                            <div className="ipotable defaulttable">
                              <table class="table">
                                <thead>
                                  <tr>
                                    <th scope="col">Holidays</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Day</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {nseHolidaysData
                                    ?.filter(
                                      (holiday) =>
                                        getMonthIndex(holiday.HolidayDate) ===
                                        11
                                    )
                                    .map((holiday) => (
                                      <tr>
                                        <td>{holiday?.Purpose}</td>
                                        <td>{getDate(holiday?.HolidayDate)}</td>
                                        <td>{holiday?.day}</td>
                                      </tr>
                                    ))}
                                </tbody>
                              </table>
                              <p>
                                *Additionally, it is important to keep in mind
                                that the NSE may declare additional holidays
                                throughout the year due to unforeseen
                                circumstances. In such cases, the exchange will
                                issue a circular notifying traders and investors
                                of the holiday.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="webcent">
            <div className="container">
              <div className="row">
                <div className="web-container">
                  <div className="title">
                    <h3>Frequently Asked Questions</h3>
                  </div>
                  <div className="faqcont">
                    <Accordion allowZeroExpanded="true">
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            What does the NSE holiday list entail?
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <div className="accordiancont">
                            <p>
                              The NSE holiday list comprises a schedule of days
                              during which the stock exchange in India, known as
                              the NSE, suspends trading operations.
                            </p>
                          </div>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            Is it possible to buy or sell stocks on NSE
                            holidays?
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <div className="accordiancont">
                            <p>
                              No, trading stocks on NSE holidays is not
                              permitted.
                            </p>
                          </div>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            Where can I locate the NSE holiday list?
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <div className="accordiancont">
                            <p>
                              The NSE holiday list is released by the stock
                              exchange and is accessible on their official
                              website. Additionally, it can be found on news
                              websites, through market regulators, and on
                              trading platforms.
                            </p>
                          </div>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            Why is understanding the NSE holiday list
                            significant?
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <div className="accordiancont">
                            <p>
                              Understanding the NSE holiday list aids in
                              planning trading activities effectively.
                            </p>
                          </div>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            What are the operating hours for the NSE?
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <div className="accordiancont">
                            <p>
                              In India, the regular trading hours for the NSE
                              are from 9:00 am to 4:00 pm, Monday to Friday.
                              It's essential to note that trading hours may be
                              adjusted based on regulatory requirements.
                            </p>
                          </div>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            Do special trading hours occur during holidays?
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <div className="accordiancont">
                            <p>
                              Yes, during certain holidays, the NSE may
                              implement special trading hours or conduct
                              half-day trading sessions. For instance, on
                              Diwali, also known as Muhurat trading, the NSE
                              organizes a special trading session for a limited
                              duration.
                            </p>
                          </div>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            Is it possible to trade stocks during Muhurat
                            trading?
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <div className="accordiancont">
                            <p>
                              Certainly, you can engage in buying and selling
                              stocks during Muhurat trading. Muhurat trading is
                              a special one-hour trading session conducted by
                              the NSE during the Diwali festival, as it is
                              commonly considered an auspicious time. It's
                              essential to note that the timings for Muhurat
                              trading differ from regular trading hours.
                            </p>
                          </div>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            What defines a settlement holiday?
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <div className="accordiancont">
                            <p>
                              A settlement holiday denotes a day when clearing
                              and settlement activities are not conducted by the
                              clearing corporations and NSE. In India, trades on
                              the NSE settle on a T+1 basis, indicating that
                              trade settlement occurs one working day after the
                              trade execution.
                            </p>
                          </div>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            Does the NSE operate on Saturdays?
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <div className="accordiancont">
                            <p>No, the NSE remains closed every Saturday.</p>
                          </div>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            Does the NSE observe closures for all bank holidays?
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <div className="accordiancont">
                            <p>
                              The NSE follows its own holiday schedule,
                              announced in advance by the stock exchange. Hence,
                              it's essential to consult the official holiday
                              list released by the NSE to determine the days
                              when the stock market will be closed for trading.
                            </p>
                          </div>
                        </AccordionItemPanel>
                      </AccordionItem>
                    </Accordion>
                    <p className="mt-3 d-block col-12 pull-left">
                      *Please note that the NSE Holiday List may change from
                      year to year and it is recommended to check for the most
                      up-to-date information from reliable sources before making
                      any trading decisions or planning your investment
                      activities. Always consult with a financial advisor for
                      personalised investment advice.
                    </p>
                    <br />
                    <p>Happy investing!</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
};

export default InvestHolidaysNSEHolidays;
