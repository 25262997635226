import { useEffect, useState } from "react";
import { getHalfYearlyResult } from "../../../utils/APICollection";
import { ThreeDots } from "react-loader-spinner";
import { formatNumber } from "../../../components/common";
const StockHalfYearly = ({ financeType }) => {
    const [stockHalfYearlyData, setStockHalfYearlyData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const fetchHalfYearlyResult = async () => {
        setIsLoading(true);
        try {
            const data = await getHalfYearlyResult(window.location.pathname.split("/")[3], financeType);
            setStockHalfYearlyData(data?.data?.data);
            setIsLoading(false);
        } catch (e) {
            console.log(e);
        }
    }
    useEffect(() => {
        fetchHalfYearlyResult();
    }, [window.location.pathname.split("/")[3], financeType])
    return (
        <div className="webcent financialsblock">
            {isLoading ? <ThreeDots
                height="100"
                width="100"
                radius="10"
                color="#3AA29F"
                ariaLabel="three-dots-loading"
                wrapperStyle={{
                    display: "flex",
                    justifyContent: "center",
                }}
                visible={true}
            />
                :
                stockHalfYearlyData.length > 0 ?
                    <div className="ipotable defaulttable">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">Column Name</th>
                                    <th scope="col">Mar 2024</th>
                                    <th scope="col">Sep 2023</th>
                                    <th scope="col">Mar 2023</th>
                                    <th scope="col">Sep 2022</th>
                                    <th scope="col">Mar 2022</th>
                                </tr>
                            </thead>
                            <tbody>
                                {stockHalfYearlyData?.map(item =>
                                    <tr>
                                        <td>{item?.COLUMNNAME}</td>
                                        <td>{formatNumber(item?.Y202403?.toFixed(2)) || "NA"}</td>
                                        <td>{formatNumber(item?.Y202309?.toFixed(2)) || "NA"}</td>
                                        <td>{formatNumber(item?.Y202303?.toFixed(2)) || "NA"}</td>
                                        <td>{formatNumber(item?.Y202209?.toFixed(2)) || "NA"}</td>
                                        <td>{formatNumber(item?.Y202203?.toFixed(2)) || "NA"}</td>
                                    </tr>
                                )}

                            </tbody>
                        </table>
                    </div>
                    :
                    <div>No Data Found</div>
            }
        </div>
    )
}

export default StockHalfYearly;