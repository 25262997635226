import React, { useContext } from "react";
import { Context } from "../../../../../context";
import { SEO_URL } from "../../../../../utils/constants";
import { Helmet } from "react-helmet-async";

const UpcomingIPOSeo = ({ id }) => {
  const { subscriptionDetails, setSubscriptionDetails } = useContext(Context);
  const image = "../../images/SMCNEWLOGO.webp";
  const url = `${SEO_URL}/invest/ipo/${id}/${subscriptionDetails?.CompanyName}`;
  const title = `${subscriptionDetails?.CompanyName} IPO  Allotment Date, Price, Lot Size, Review & Analysis at SMC`;
  const description = `${subscriptionDetails?.CompanyName} IPO : Get ${subscriptionDetails?.CompanyName} allotment date, Price, Loz Size, Live Subscriptions, Analysis & Review and Allotment Date`;
  // const isMobile = window.innerWidth <= 540;
  //   const isMobile = window.innerWidth <= 1040;
  function formatUrl(url) {
    let tempUrl = url.replace(/\s+/g, "-").toLowerCase();
    return tempUrl.replace(/-+/g, "-");
  }
  const formattedUrl = formatUrl(url);
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <link rel="canonical" href={`${SEO_URL}/invest/ipo/ipodetails/${id}`} />
        <meta property="og:title" content={title} data-react-helmet="true" />
        <meta property="og:site_name" content="SMC Global Securities" />
        <meta property="og:url" content={formattedUrl} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />

        <meta name="twitter:url" content={formattedUrl} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={image} />
        <meta name="twitter:card" content="summary" />
        <meta name="description" content={description}></meta>
        <meta name="robots" content="noindex, nofollow"></meta>
      </Helmet>
    </>
  );
};

export default UpcomingIPOSeo;
