import { useState, useEffect } from "react";
import { getRegistrarInfo } from "../../../../utils/APICollection";

const IpoRegistrarInfo = () => {
  const [registarInfo, setRegistarInfo] = useState([]);
  const fetchRegistrar = async () => {
    try {
      const data = await getRegistrarInfo(
        window.location.pathname.split("/")[4]
      );
      setRegistarInfo(data?.data?.data[0]);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchRegistrar();
  }, []);
  return (
    <div className="registrarinfo">
      {/* regisleft width was 45 % with red hearing prospects */}
      <div className="regisleft" style={{ width: "100%" }}>
        <div className="reginformation">
          <h4>Registrar Information</h4>
          <ul>
            <li>
              <span>Name</span>
              <p>{registarInfo?.lname}</p>
            </li>
            <li>
              <span>Phone Number</span>
              <p>{registarInfo?.tel1}</p>
            </li>
            <li>
              <span>Email ID</span>
              <p>
                <a href="mailto:udayshivakumarInfraipo@kfintech.com">
                  {registarInfo?.email}
                </a>
              </p>
            </li>
          </ul>
        </div>
      </div>
      {/* red hearing prospects will be used in future */}
      {/* <div className="regisright">
                                <div className="reginformation">
                                  <h4>Red Herring Prospectus</h4>
                                  <p>
                                    View documents submitted by company in
                                    filing to understand more details about
                                    them.
                                  </p>
                                  <div className="btnn mt-3">
                                    <span>View red heirring document</span>
                                  </div>
                                </div>
                              </div> */}
    </div>
  );
};

export default IpoRegistrarInfo;
