import React, { useContext } from "react";
import Footer from "../../components/Footer";
import { Link } from "react-router-dom";
import Banner from "../../images/contact-us.webp";
import { Faqs } from "../../images/svg/Faqs";
import { Download } from "../../images/svg/Download";
import { Holidays } from "../../images/svg/Holidays";
import { List } from "../../images/svg/List";
import ContactForm from "../../components/ContactForm";
import Loader from "../../components/Loader";
import { Context } from "../../context";
import Header from "../../components/Header";
import SEO from "../../components/common/SEO";
import { SEO_URL } from "../../utils/constants";
import NumberCount from "./NumberCount";
import ConnectWithUs from "./ConnectWithUs";
import { seoData } from "../../utils/Data";

export const Contactus = () => {
  const { loading, setShowPath } = useContext(Context);

  return (
    <>
      <Header />
      <SEO
        title={seoData.contactUs.title}
        description={seoData.contactUs.description}
        name="Company name."
        type="article"
        url={seoData.contactUs.url}
        image={seoData.contactUs.image}
      />
      <section id="banner">
        <div className="banner internal">
          <div className="container">
            <div className="bredcrum">
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>contact-us</li>
              </ul>
            </div>
            <div className="web-container">
              <div className="bannermg">
                <div className="bannerlt">
                  <h1>Have a query?</h1>
                </div>
                <div className="bannergt">
                  <img src={Banner} alt="Hassle-free investing" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <main>
        <section id="main">
          <div
            className="webcent"
            id="Account"
            data-aos="fade-in"
            data-aos-offset="10"
            data-aos-duration="600"
            data-aos-once="true"
            data-aos-easing="ease-in-sine"
          >
            <div className="container">
              <div className="row">
                <div className="web-container">
                  <div className="title">
                    <h3>Contact Us</h3>
                  </div>
                  <div className="iconrow">
                    <ul>
                      <li>
                        <Link
                          to="/invest/trading-holidays-2024"
                          onClick={() => setShowPath(false)}
                        >
                          <span>
                            <Holidays />
                          </span>
                          <p>
                            <strong>Trading Holiday</strong>
                          </p>
                        </Link>
                      </li>
                      <li>
                        <Link to="/contact-us/downloads">
                          <span>
                            <Download />
                          </span>
                          <p>
                            <strong>Downloads</strong>
                          </p>
                        </Link>
                      </li>
                      <li>
                        <Link to="/contact-us/faqs">
                          <span>
                            <Faqs />
                          </span>
                          <p>
                            <strong>FAQs</strong>
                          </p>
                        </Link>
                      </li>
                      <li>
                        <Link to="/contact-us/do-dont">
                          <span>
                            <List />
                          </span>
                          <p>
                            <strong>Dos & Don't</strong>
                          </p>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ConnectWithUs />
          <NumberCount />
          <div
            className="webcent"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-duration="600"
            data-aos-once="true"
            data-aos-easing="ease-in-sine"
          >
            <div className="container">
              <div className="row">
                <div className="web-container">
                  <ContactForm />
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
      {loading && <Loader />}
    </>
  );
};
