import React, { useContext, useEffect } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useNavigate, useSearchParams } from "react-router-dom";
import { State, City } from "country-state-city";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { leadApi, register, sendOtpApi } from "../utils/APICollection";
import { Context } from "../context";
import axios from "axios";
import CitySearch from "./common/CitySearch";
import validator from "validator";
import { CAPTCHA_KEY } from "../utils/constants";
import { loadReCaptchaScript } from "./common/FormattedDate";

const DematForm = ({ sidebarWidth, catID, smsType }) => {
  const containerStyle = {
    width: sidebarWidth ? `${sidebarWidth}px` : "",
  };

  const navigate = useNavigate();
  const isMobile = window.innerWidth <= 540;
  const {
    num,
    setNum,
    name,
    setName,
    email,
    setEmail,
    state,
    setState,
    city,
    setCity,
    setCategoryID,
    setUrlData,
    ipAddress,
    setIPAddress,
    urlData,
    categoryID,
    loading,
    setLoading,
    setLeadId,
    deviceID,
    setDeviceID,
  } = useContext(Context);

  const [mobileError, setMobileError] = React.useState("");
  const [emailError, setEmailError] = React.useState("");
  const [nameError, setNameError] = React.useState("");

  const [cityError, setCityError] = React.useState("");

  const [searchParams, setSearchParams] = useSearchParams();
  searchParams.get("refcode");

  const limitChar = 10;
  const handleNumChange = (e) => {
    setNum(e.target.value);
    setMobileError("");
  };

  const handleName = (e) => {
    setName(e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1));
    setNameError("");
  };

  const handleEmail = (e) => {
    setEmail(e.target.value);
    setEmailError("");
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (loading) {
      return;
    }
    setLoading(true);

    let mobErr = "";
    let namErr = "";
    let cityErr = "";
    let emailErr = "";

    if (!validator.isEmail(email)) {
      emailErr = "Enter a Valid Email ID";
    }

    if (!name || name.trim() === "" || name?.length < 3 || name?.length > 50) {
      namErr = "Please fill out this field";
    }

    if (num?.toString().length !== 10) {
      mobErr = "Please fill out this field";
    }

    if (city == "") {
      cityErr = "Please fill out this field";
    }

    if (emailErr || mobErr || namErr || cityErr) {
      setEmailError(emailErr);
      setMobileError(mobErr);
      setNameError(namErr);
      setCityError(cityErr);
      setLoading(false);
    } else {
      try {
        // const { data, error } = await register({ mobile_no: num });
        // if (data?.stutus == "success") {
        getIP();

        setCategoryID(catID);

        setUrlData({
          ref: searchParams.get("utm_refcode"),
          source: searchParams.get("utm_source"),
          medium: searchParams.get("utm_medium"),
          campaign: searchParams.get("utm_campaign"),
        });
        window.grecaptcha.ready(() => {
          window.grecaptcha
            .execute(String(CAPTCHA_KEY), { action: "submit" })
            .then(async (token) => {
              const { data: data1, message1 } = await leadApi({
                mobile_no: num,
                captchaToken: token,
                leadInfo: [
                  {
                    user_name: name,
                    contact_number: num,
                    email: email,
                    city: city,
                    state: state,
                    category_code: catID,
                    ref: searchParams.get("utm_refcode"),
                    source: searchParams.get("utm_source"),
                    medium: searchParams.get("utm_medium"),
                    campaign: searchParams.get("utm_campaign"),
                    country: "",
                    product: catID,
                    ip: ipAddress,
                    device: deviceID,
                    status: "Otp sent",
                    client_id: "",
                    sub_query: "",
                    existing_customer: "",
                    explain_query: "",
                  },
                ],
              });
              if (data1) {
                setLeadId(String(data1?.data[0]));
                window.grecaptcha.ready(() => {
                  window.grecaptcha
                    .execute(String(CAPTCHA_KEY), { action: "submit" })
                    .then(async (token) => {
                      const { data: data2, message } = await sendOtpApi({
                        mobile_no: num,
                        sms_type: smsType ? smsType : "sms_common",
                        user_name: name,
                        captchaToken: token,
                      });
                      if (data2) {
                        setLoading(false);
                        navigate("/validateotp");
                      } else {
                        setLoading(false);
                      }
                    });
                });
              } else {
                setLoading(false);
              }
            });
        });
        // } else {
        //   setLoading(false);
        // }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
      // finally {
      //   setLoading(false);
      // }
    }
  };
  const getSourceByCategory = (categoryID) => {
    const categorySourceMap = {
      Invest: "SMC Website | Invest",
      Trade: "SMC Website | Trade",
      Webinar: "SMC Website | Webinar",
      Research: "SMC Website | Research",
      Subbroker: "SMC Website | Subbroker",
      Distributor: "SMC Website | Distributor",
      Stock: "SMC Website | Stock",
      FI: "SMC Website | FI",
      USStocks: "SMC Website | USStocks",
      IPO: "SMC Website | IPO",
      MF: "SMC Website | MF",
      Contact: "SMC Website | Contact Us",
    };

    return categorySourceMap[categoryID] || "SMC Website|Default";
  };
  const getIP = async () => {
    try {
      const userAgent = navigator.userAgent;
      const res = await axios.get("https://api.ipify.org?format=json");

      setIPAddress(res?.data?.ip);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    let userAgent = navigator.userAgent;

    if (/Android/i.test(userAgent)) {
      setDeviceID("Android device.");
    } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
      setDeviceID("iOS device.");
    } else if (/Macintosh/i.test(userAgent)) {
      setDeviceID("macOS device.");
    } else if (/Windows/i.test(userAgent)) {
      setDeviceID("Windows device.");
    } else {
      setDeviceID("Unknown Device");
    }
    getIP();
    loadReCaptchaScript();
  }, []);

  const ariaLabel = { "aria-label": "description" };
  const ALPHA_REGEX = /^[a-zA-Z ]+$/;
  return (
    <div
      className="opendemat formbg bg-white rounded-0 "
      // style={containerStyle}
    >
      <h3 className="m-0">Fill Your Details</h3>
      <Box component="form" autoComplete="off" onSubmit={onSubmit}>
        <ul>
          <li>
            <TextField
              id="filled-basic"
              label="Name"
              variant="standard"
              error={!!nameError}
              helperText={nameError}
              value={name}
              onChange={handleName}
              inputProps={{
                maxLength: 50,
                type: "text",
              }}
              onKeyDown={(event) => {
                if (!ALPHA_REGEX.test(event.key)) {
                  event.preventDefault();
                }
              }}
              disabled={loading}
            />
          </li>
          <li>
            <TextField
              id="filled-basic"
              label="Email"
              variant="standard"
              value={email}
              type="text"
              error={!!emailError}
              helperText={emailError}
              onChange={handleEmail}
              disabled={loading}
            />
          </li>
          <li>
            <TextField
              id="filled-basic"
              label="Mobile No."
              variant="standard"
              type="number"
              onChange={handleNumChange}
              value={num}
              error={!!mobileError}
              helperText={mobileError}
              onInput={(e) => {
                e.target.value = Math.max(0, parseInt(e.target.value))
                  .toString()
                  .slice(0, e.target.maxLength);
              }}
              inputProps={{
                minLength: 10,
                maxLength: 10,
                type: "number",
              }}
              disabled={loading}
            />
          </li>

          <CitySearch
            cityError={cityError}
            setCityError={setCityError}
            color={"grey"}
          />
          <li>
            <button type="submit" style={{ marginTop: 40 }}>
              Start Now
            </button>
          </li>
        </ul>
      </Box>
    </div>
  );
};

export default DematForm;
