import React from "react";
import "../src/font/stylesheet.css";
import "../src/css/stylesheet.css";
import "../src/css/header.css";
import "../src/css/footer.css";
import "../src/css/responsive.css";
import Routing from "./routing";
import CacheBuster from "react-cache-buster";
import version from "../package.json";
import Loader from "./components/Loader";
import LanguageIcon from "@mui/icons-material/Language";
import { HelmetProvider } from "react-helmet-async";

function App() {
  const isProduction = process.env.NODE_ENV === "production";
  return (
    <CacheBuster
      currentVersion={version}
      isEnabled={isProduction} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
      loadingComponent={<Loader />} //If not pass, nothing appears at the time of new version check.
      metaFileDirectory={"."} //If public assets are hosted somewhere other than root on your server.
    >
      <HelmetProvider>
        <Routing />
      </HelmetProvider>

      <div class="wrap">
        <div class="wrapv">
          <div class="vertical">
            <a href="https://old.smctradeonline.com" target="_blank">
              Click Here for Old Website
              {/* <LanguageIcon sx={{ mr: 1, mt: "-4px" }} /> */}
            </a>
          </div>
        </div>
      </div>
    </CacheBuster>
  );
}

export default App;
