import React, { useContext, useEffect, useState } from "react";
import Footer from "../../../components/Footer";
import MutualBanner from "../../../images/mutual_fund.webp";
import { Link, useNavigate } from "react-router-dom";
import { Form } from "../../../components/Form";
import Icon01 from "../../../images/icon01.webp";
import Icon02 from "../../../images/icon02.webp";
import Icon03 from "../../../images/icon03.webp";
import Icon04 from "../../../images/icon04.webp";
import Icon05 from "../../../images/diversification.svg";
import Icon06 from "../../../images/proffessional management.svg";
import Icon07 from "../../../images/disciplined investment.svg";
import Icon08 from "../../../images/low trans cost.svg";
import Icon09 from "../../../images/liquidity.svg";
import Icon10 from "../../../images/tax benefits.svg";
import Esteps from "../../../images/4-steps.webp";
import MutualFaq from "../../invest/investList/mutualFundDetails/MutualFaq";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableSortLabel,
} from "@mui/material";
import { Context } from "../../../context";
import Header from "../../../components/Header";
import { ThreeDots } from "react-loader-spinner";
import SEO from "../../../components/common/SEO";
import { SEO_URL } from "../../../utils/constants";
import MFReturnCalculator from "./MFReturnCalculator";
import {
  getFundsCategory,
  getFundsPlanByCategory,
} from "../../../utils/APICollection";

const MutualFund = () => {
  const { showPath } = useContext(Context);
  const [fundData, setFundData] = useState([]);
  const [fundsCategoryData, setFundsCategoryData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [category, setCategory] = useState("");
  const [iconList, setIconList] = useState([Icon01, Icon02, Icon03, Icon04]);
  const navigate = useNavigate();

  const fetchFundsCategory = async () => {
    setIsLoading(true);
    try {
      const res = await getFundsCategory();
      setFundsCategoryData(res?.data?.data);
      setCategory(res?.data?.data[0]?.primary_category_name);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchFundsPlansByCategory = async () => {
    setIsLoading(true);
    try {
      const res = await getFundsPlanByCategory(category);
      setFundData(res?.data);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchFundsCategory();
  }, []);

  useEffect(() => {
    if (fundsCategoryData?.length > 0) {
      fetchFundsPlansByCategory();
    }
  }, [category]);

  const [orderBy, setOrderBy] = useState("");
  const [order, setOrder] = useState("asc");

  const handleSort = (property) => {
    const isAscending = orderBy === property && order === "asc";
    const sortedData = [...fundData].sort((a, b) => {
      if (a[property] < b[property]) return isAscending ? -1 : 1;
      if (a[property] > b[property]) return isAscending ? 1 : -1;
      return 0;
    });
    setFundData(sortedData);
    setOrderBy(property);
    setOrder(isAscending ? "desc" : "asc");
  };

  const showFundDetails = (id) => {
    navigate(`/invest/mutual-fund/detail/${id}`);
  };
  const isMobile = window.innerWidth <= 540;

  const title = "Mutual Funds";
  const image = "../../images/SMCNEWLOGO.webp";
  const url = `${SEO_URL}/invest/mutual-fund`;
  return (
    <>
      <Header />
      <SEO
        title={title}
        name="Company name."
        type="article"
        url={url}
        image={image}
      />

      <section id="banner">
        <div className="banner internal">
          <div className="container">
            <div className="bredcrum">
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>

                {showPath == "product" ? (
                  <li style={{ color: "black" }}>Products</li>
                ) : (
                  <li>
                    <Link to="/invest">Invest</Link>
                  </li>
                )}

                <li>Mutual Funds</li>
              </ul>
            </div>
            <div className="web-container">
              <div className="bannermg">
                <div className="bannerlt">
                  <h1>
                    Easy invest. <span>Mutual funds.</span>
                  </h1>
                </div>
                <div className="bannergt">
                  <img src={MutualBanner} alt="Hassle-free investing" />
                </div>
                <Form catID="MF" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <main>
        <section id="main">
          <div
            className="webcent"
            id="Account"
            data-aos="fade-in"
            data-aos-offset="100"
            data-aos-duration="600"
            data-aos-once="true"
            data-aos-easing="ease-in-sine"
          >
            <div className="container">
              <div className="row">
                <div className="web-container">
                  <div className="title">
                    <h3>Explore Mutual Funds</h3>
                  </div>
                  <div className="iconrow">
                    <ul>
                      {fundsCategoryData?.map((categoryName, index) => (
                        <li
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            setCategory(categoryName?.primary_category_name)
                          }
                        >
                          <span
                            style={
                              category === categoryName?.primary_category_name
                                ? { border: "5px solid lightgreen" }
                                : {}
                            }
                          >
                            <img src={iconList[index]} alt="AMC for Lifetime" />
                          </span>
                          <p>{categoryName?.primary_category_name}</p>
                        </li>
                      ))}

                      {/* <li>
                        <span>
                          <img
                            src={Icon04}
                            alt="Premium Telegram Channel Subscription"
                          />
                        </span>
                        <p>Sector Bets</p>
                      </li>
                      <li>
                        <span>
                          <img src={Icon02} alt="Research Webinars" />
                        </span>
                        <p>Top Rated</p>
                      </li>
                      <li>
                        <span>
                          <img src={Icon03} alt="Dedicated RM Support" />
                        </span>
                        <p>Diversified</p>
                      </li>
                      <li>
                        <span>
                          <img src={Icon01} alt="AMC for Lifetime" />
                        </span>
                        <p>Low Risk</p>
                      </li>
                      <li>
                        <span>
                          <img
                            src={Icon04}
                            alt="Premium Telegram Channel Subscription"
                          />
                        </span>
                        <p>Tax Saving</p>
                      </li> */}
                    </ul>
                  </div>
                  <div className="mbtn">
                    {/* commenting explore all may be used in future  */}
                    {/* <div className="btnn">
                      <span>EXPLORE all</span>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="ipoportfolio">
            <div className="container">
              <div className="row">
                <div className="web-container">
                  <div className="ipospace">
                    <div className="webcent">
                      <div className="datatable moblay">
                        {isLoading ? (
                          <ThreeDots
                            height="150"
                            width="100"
                            radius="10"
                            color="#3AA29F"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                            // wrapperClassName=""
                            visible={true}
                          />
                        ) : (
                          <Table>
                            <TableHead
                              className={isMobile ? "mfheadmob" : "gradient"}
                            >
                              <TableRow>
                                <TableCell style={{ color: "white" }}>
                                  {/* <TableSortLabel
                                  active={orderBy === "id"}
                                  direction={order}
                                  onClick={() => handleSort("id")}
                                > */}
                                  Fund Name
                                  {/* </TableSortLabel> */}
                                </TableCell>
                                <TableCell style={{ paddingRight: 0 }}>
                                  <TableSortLabel
                                    active={orderBy === "ret_1year"}
                                    direction={order}
                                    onClick={() => handleSort("ret_1year")}
                                  >
                                    1 Y
                                  </TableSortLabel>
                                </TableCell>
                                <TableCell style={{ paddingRight: 0 }}>
                                  <TableSortLabel
                                    active={orderBy === "ret_3year"}
                                    direction={order}
                                    onClick={() => handleSort("ret_3year")}
                                  >
                                    3 Y
                                  </TableSortLabel>
                                </TableCell>
                                <TableCell style={{ paddingRight: 0 }}>
                                  <TableSortLabel
                                    active={orderBy === "ret_5year"}
                                    direction={order}
                                    onClick={() => handleSort("ret_5year")}
                                  >
                                    5 Y
                                  </TableSortLabel>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {fundData.map((item) => (
                                <TableRow>
                                  <TableCell className="ipotable ">
                                    {/* <a href="/invest/mutual-fund/detail"> */}
                                    <div
                                      style={{ cursor: "pointer" }}
                                      className="ipologo"
                                      onClick={() =>
                                        showFundDetails(item?.plan_id)
                                      }
                                    >
                                      <span>
                                        <img src={item?.amc_logo} alt="logo" />
                                      </span>
                                      {item?.basic_name}
                                    </div>
                                    {/* </a> */}
                                  </TableCell>
                                  <TableCell>
                                    {Number(item?.ret_1year)?.toFixed(2)}%
                                  </TableCell>
                                  <TableCell>
                                    {Number(item?.ret_3year)?.toFixed(2)}%
                                  </TableCell>
                                  <TableCell>
                                    {Number(item?.ret_5year)?.toFixed(2)}%
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <MFReturnCalculator />
          {/* <div className="ipoportfolio">
            <div className="container">
              <div className="row">
                <div className="web-container">
                  <div className="moblay text-center">
                    <div className="ipospace">
                      <div className="webcent">
                        <div className="aboutcompany">
                          <div className="title text-center">
                            <h3>
                              Benefits of Investing in Mutual Funds with SMC
                            </h3>
                            <p>
                              Experience a new-age approach to IPO investing
                              with our platform, unlocking a plethora of
                              exclusive benefits that set us apart from the
                              competition. Enjoy a seamless and user-friendly
                              process, complete with 0% commission and
                              research-backed advisory services, making us the
                              ideal choice for your investment needs. From
                              account opening to executing trades, every step is
                              designed to be quick and effortless, allowing you
                              to focus on what truly matters. Bid farewell to
                              hidden fees and charges with our transparent 0%
                              commission structure. Trust lies at the core of
                              our platform, built upon principles of
                              transparency and security, ensuring the safety of
                              your investments. Whether you're a seasoned
                              investor or just starting out, our platform is the
                              ultimate destination for IPO investments.
                            </p>
                          </div>
                          <div className="investingipo">
                            <div className="investingbox">
                              <img src={Icon05} alt="0% Commission" />
                              <div className="investingcont">
                                <h4>0% Commission</h4>
                                <p>
                                  Choose from our vast investment portfolio at
                                  0% commission and enjoy all the benefits!
                                </p>
                              </div>
                            </div>
                            <div className="investingbox">
                              <img src={Icon06} alt="Trust" />
                              <div className="investingcont">
                                <h4>Trust</h4>
                                <p>
                                  We have gained the faith of 20+lakh happy
                                  customers over the last 30 years.
                                </p>
                              </div>
                            </div>
                            <div className="investingbox">
                              <img src={Icon07} alt="Seamless Investing" />
                              <div className="investingcont">
                                <h4>Seamless Investing</h4>
                                <p>
                                  Enjoy hassle-free investment processes. Open
                                  your account with SMC in just a few minutes
                                  and start investing!
                                </p>
                              </div>
                            </div>
                            <div className="investingbox">
                              <img
                                src={Icon08}
                                alt="Research Backed Recommendations"
                              />
                              <div className="investingcont">
                                <h4>Research Backed Recommendations</h4>
                                <p>
                                  Our advisory is backed by top-grade research &
                                  analysis. We are always happy to help you.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="webcent">
                        <div className="title text-center justify-content-center">
                          <h3>How to Invest in Mutual Fund Through SMC</h3>
                        </div>
                        <div className="downloadapp justify-content-between">
                          <div className="apprgt">
                            <img src={Esteps} alt="easysteps" />
                          </div>
                          <div className="applef">
                            <div className="stepstn">
                              <ul>
                                <li>
                                  <strong>Step 1 </strong>
                                  <p>Open SMC App</p>
                                </li>
                                <li>
                                  <strong>Step 2 </strong>
                                  <p>Make your Investment</p>
                                </li>
                                <li>
                                  <strong>Step 3 </strong>
                                  <p>Find the fund you want to invest</p>
                                </li>
                                <li>
                                  <strong>Step 4 </strong>
                                  <p>Invest & Track</p>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="mbtn">
                          <div
                            className="btnn"
                            onClick={() =>
                              window.scrollTo({ top: 0, behavior: "smooth" })
                            }
                          >
                            <span>Get Started</span>
                          </div>
                        </div>
                      </div>
                      <MutualFaq />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          <div className="ipoportfolio">
            <div className="container">
              <div className="row">
                <div className="web-container">
                  <div className="moblay text-center">
                    <div className="ipospace">
                      <div className="webcent">
                        <div className="aboutcompany">
                          <div className="title text-center">
                            <h3>
                              Empowering Your Wealth Journey with Mutual Funds
                            </h3>
                            <p>
                              Mutual funds are ideal for investors seeking
                              diverse investment opportunities with varying
                              goals but lacking the time or expertise to select
                              individual stocks for maximum profitability. They
                              provide professional oversight, reduce transaction
                              costs, and offer benefits such as diversification,
                              liquidity, and potential tax advantages. Our goal
                              is to assist you in financial planning and
                              realizing long-term objectives through our
                              extensive network and team of financial advisors.
                            </p>
                          </div>
                          <div className="title text-center">
                            <h3>Key Benefits</h3>
                            <p>Mutual Fund is the way to grow</p>
                          </div>

                          <div className="investingipo">
                            <div className="investingbox">
                              <img src={Icon05} alt="Diversification" />
                              <div className="investingcont">
                                <h4>Diversification</h4>
                                <p>
                                  With mutual fund investments, your money can
                                  be spread in small bits across varied
                                  companies. This way you reap the benefits of a
                                  diversified portfolio with small investments.
                                </p>
                              </div>
                            </div>
                            <div className="investingbox">
                              <img src={Icon06} alt="Professional Management" />
                              <div className="investingcont">
                                <h4>Professional Management</h4>
                                <p>
                                  The pool of money collected by a mutual fund
                                  is managed by professionals who possess
                                  considerable expertise, resources and
                                  experience.
                                </p>
                              </div>
                            </div>
                            <div className="investingbox">
                              <img src={Icon07} alt="Disciplined Investment" />
                              <div className="investingcont">
                                <h4>Disciplined Investment</h4>
                                <p>
                                  SIPs in best Mutual Fund help to curb
                                  unnecessary expenses and develop a disciplined
                                  approach towards investing
                                </p>
                              </div>
                            </div>
                            <div className="investingbox">
                              <img src={Icon08} alt="Low Transaction Costs" />
                              <div className="investingcont">
                                <h4>Low Transaction Costs</h4>
                                <p>
                                  Mutual funds take advantage of their buying
                                  and selling volume to reduce transaction costs
                                  for their investors.
                                </p>
                              </div>
                            </div>
                            <div className="investingbox">
                              <img src={Icon09} alt="Liquidity" />
                              <div className="investingcont">
                                <h4>Liquidity</h4>
                                <p>
                                  Mutual funds are considered as being extremely
                                  liquid since they are simple to buy and sell
                                  in the short term during market hours.
                                </p>
                              </div>
                            </div>
                            <div className="investingbox">
                              <img src={Icon10} alt="Tax Benefits" />
                              <div className="investingcont">
                                <h4>Tax Benefits</h4>
                                <p>
                                  Save taxes by investing in Best ELSS Mutual
                                  Fund schemes U/S 80C.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="webcent">
                        <div className="title text-center justify-content-center">
                          <h3>How to Invest in Mutual Fund Through SMC</h3>
                        </div>
                        <div className="downloadapp justify-content-between">
                          <div className="apprgt">
                            <img src={Esteps} alt="easysteps" />
                          </div>
                          <div className="applef">
                            <div className="stepstn">
                              <ul>
                                <li>
                                  <strong>Step 1 </strong>
                                  <p>Open SMC App</p>
                                </li>
                                <li>
                                  <strong>Step 2 </strong>
                                  <p>Make your Investment</p>
                                </li>
                                <li>
                                  <strong>Step 3 </strong>
                                  <p>Find the fund you want to invest</p>
                                </li>
                                <li>
                                  <strong>Step 4 </strong>
                                  <p>Invest & Track</p>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="mbtn">
                          <div
                            className="btnn"
                            onClick={() =>
                              window.scrollTo({ top: 0, behavior: "smooth" })
                            }
                          >
                            <span>Get Started</span>
                          </div>
                        </div>
                      </div>
                      <MutualFaq />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
};

export default MutualFund;
