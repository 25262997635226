import React, { useContext } from "react";
import Investbanner from "../../../images/webinar-detail.webp";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import { Form } from "../../../components/Form";
import Referimg from "../../../images/how-to-learn.webp";
import Expert from "../../../images/ca-paras-aggarwal.webp";
import Esteps from "../../../images/telegram-steps.webp";
import Date from "../../../images/calender.webp";
import Time from "../../../images/clock.webp";
import parse from "html-react-parser";
import PersonIcon from "@mui/icons-material/Person";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { Context } from "../../../context";
import {
  formatMyDate,
  formatTime,
} from "../../../components/common/FormattedDate";
import { style } from "@mui/system";
import {
  getAllWebinar,
  getThumbnailFileUrl,
  getWebinarById,
} from "../../../utils/APICollection";
import { Helmet } from "react-helmet";
import { AWS_S3_WEBINAR_FOLDER, SEO_URL } from "../../../utils/constants";
import Loader from "../../../components/Loader";
import { Avatar } from "@mui/material";
import SMCNEWLOGO from "../../../images/SMCNEWLOGO.webp";
import SEO from "../../../components/common/SEO";

export const WebinarDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { loading, setLoading } = useContext(Context);

  const [webinarDetail, setWebinarDetail] = React.useState([]);
  const [thumbnailImg, setThumnailImg] = React.useState([]);

  const getWebinar = async () => {
    try {
      const response = await getWebinarById(id);
      setWebinarDetail(response?.data?.data);
      getThumbnailFile(response?.data?.data);
    } catch (error) {
      navigate("/learning-centre/webinar");
      console.log(error);
    }
  };

  const getThumbnailFile = async (webFile) => {
    const webinarData = [];
    if (
      webFile?.[0].banner_file !== undefined ||
      webFile?.[0].banner_file !== null
    ) {
      webinarData.push(webFile[0].banner_file);
    }

    if (
      webFile?.[0].speaker_file !== undefined ||
      webFile?.[0].speaker_file !== null
    ) {
      webinarData.push(webFile[0].speaker_file);
    }
    setLoading(true);
    try {
      const { data } = await getThumbnailFileUrl({
        file_names: webinarData,
        folder_name: AWS_S3_WEBINAR_FOLDER,
      });
      setThumnailImg(data?.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  let result = webinarDetail?.map((item) => {
    let bannerFound = thumbnailImg?.find(
      (element) => element?.file_name === item.banner_file
    );
    if (bannerFound) {
      item.banner_url = bannerFound.file_url;
    }
    let speakerFound = thumbnailImg?.find(
      (element) => element?.file_name === item.speaker_file
    );
    if (speakerFound) {
      item.speaker_img_url = speakerFound?.file_url;
    }

    return item;
  });

  React.useEffect(() => {
    getWebinar();
  }, []);

  const url = `${SEO_URL}/learning-centre/webinar`;
  const image = "../../../images/SMCNEWLOGO.webp";
  const title = `${webinarDetail?.[0]?.title}`;
  const description = `With this webinar, you will gain insights on  ${webinarDetail?.[0]?.title} - SMC Webinar`;
  return (
    <>
      <Header />
      <SEO
        title={title}
        description={description}
        name="Company name."
        type="article"
        url={url}
        image={image}
      />

      <section id="banner">
        <div className="banner internal">
          <div className="container">
            <div className="bredcrum">
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>

                <li style={{ color: "black" }}>learn</li>

                <li style={{ color: "black" }}>
                  <NavLink to="/learning-centre/webinar">webinar</NavLink>
                </li>
                <li>{webinarDetail?.[0]?.title}</li>
              </ul>
            </div>
            <div className="web-container">
              <div className="bannermg">
                <div className="webinarbanner">
                  <div className="webinarheading">
                    <h3 className="fs-5 text-uppercase web-title">
                      {webinarDetail?.[0]?.category_name}
                    </h3>

                    <h1 className="lh-1">
                      <span className="gradient white-text">
                        {webinarDetail?.[0]?.title}
                      </span>
                    </h1>
                  </div>
                  <div className="webinartime">
                    <div className="webdate">
                      <span>
                        <img src={Date} />
                      </span>
                      <h4>
                        {webinarDetail?.[0] &&
                          formatMyDate(webinarDetail?.[0]?.date)}
                      </h4>
                    </div>
                    <div className="webtime">
                      <span>
                        <img src={Time} />
                      </span>
                      <h4>
                        {webinarDetail?.[0] &&
                          formatTime(webinarDetail?.[0]?.time)}{" "}
                        IST
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="bannergt">
                  {webinarDetail?.[0]?.banner_url !== null ? (
                    <img
                      src={webinarDetail[0]?.banner_url}
                      alt={webinarDetail[0]?.category_name}
                      style={{ height: "25rem", width: "30rem" }}
                    />
                  ) : (
                    <img
                      src={Investbanner}
                      style={{ height: "25rem", width: "30rem" }}
                      alt="Fallback Image"
                    />
                  )}
                </div>
                <Form catID="Webinar" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <main>
        <section id="main">
          <div
            className="webcent grey-background p-100"
            data-aos="fade-up"
            data-aos-offset="100"
            data-aos-duration="600"
            data-aos-once="true"
            data-aos-easing="ease-in-sine"
          >
            <div className="container">
              <div className="row">
                <div className="web-container">
                  <div className="title mb-2">
                    <h3>Introduction</h3>
                  </div>
                  <div
                    className="col-12 text-center"
                    style={{ wordWrap: "break-word" }}
                  >
                    <div>
                      {webinarDetail?.[0] &&
                        parse(webinarDetail?.[0]?.webinar_introduction)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="webcent"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-duration="600"
            data-aos-once="true"
            data-aos-easing="ease-in-sine"
          >
            <div className="container">
              <div className="row">
                <div className="web-container">
                  <div className="refernearn mainsection aboutlearn">
                    <div className="refernimg leftsection">
                      <img
                        src={Referimg}
                        alt=""
                        className="col-12 m-0 col-lg-12 w-100"
                      />
                    </div>
                    <div className="referntitle rightsection">
                      <div className="title">
                        <h3 className="m-0">What You Will Learn?</h3>
                      </div>
                      <div style={{ wordWrap: "break-word" }}>
                        {webinarDetail?.[0] &&
                          parse(webinarDetail?.[0]?.what_will_you_learn)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="webcent p-0"
            data-aos="fade-up"
            data-aos-offset="80"
            data-aos-duration="600"
            data-aos-once="true"
            data-aos-easing="ease-in-sine"
          >
            <div className="container">
              <div className="row">
                <div className="web-container">
                  <div className="topgrid d-flex experts m-0">
                    <div className="logotag">
                      <div className="expertimg mb-1">
                        {webinarDetail?.[0]?.speaker_img_url ? (
                          <Avatar
                            alt={webinarDetail?.[0]?.speaker_name}
                            src={webinarDetail?.[0]?.speaker_img_url}
                            sx={{
                              width: "200px",
                              justifyContent: "center",
                              height: "200px",
                              borderRadius: "50%",
                            }}
                          />
                        ) : (
                          <span>
                            <Avatar sx={{ height: "200px", width: "200px" }}>
                              <PersonIcon
                                sx={{ height: "100%", width: "100%" }}
                              />
                            </Avatar>
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="texttag">
                      <div className="title">
                        <h3>{webinarDetail?.[0]?.speaker_name}</h3>
                        <p>
                          <b className="text-black">Speaker</b>
                        </p>
                        <div className="speaker-introduction-container">
                          {webinarDetail?.[0] &&
                            parse(webinarDetail?.[0]?.speaker_introduction)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="webcent"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-duration="600"
            data-aos-once="true"
            data-aos-easing="ease-in-sine"
          >
            <div className="container">
              <div className="row">
                <div className="web-container">
                  <div className="title">
                    <h3>How to join a webinar?</h3>
                  </div>
                  <div className="downloadapp easystep flex-direction-row">
                    <div className="apprgt">
                      <img src={Esteps} alt="easysteps" />
                    </div>
                    <div className="applef">
                      <div className="stepstn">
                        <ul>
                          <li>
                            <strong>Step 1 </strong>
                            <p>
                              Register with your name, contact number, and email
                              id.
                            </p>
                          </li>
                          <li>
                            <strong>Step 2 </strong>
                            <p>
                              Webinar joining link will be shared with you over
                              email and SMS.
                            </p>
                          </li>
                          <li>
                            <strong>Step 3 </strong>
                            <p>
                              Click on the link received to join the webinar.
                            </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <Footer />
      {loading && <Loader />}
    </>
  );
};
