import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { getAdvancedRatiosData } from "../../../../utils/APICollection";
const AdvancedRatios = () => {
  const [ratiosData, setRatiosData] = useState({});
  const location = useLocation();
  const fetchAdvancedRatios = async () => {
    try {
      const res = await getAdvancedRatiosData(
        window.location.pathname.split("/")[4]
      );
      setRatiosData(res?.data[0]);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    fetchAdvancedRatios();
  }, [location.pathname]);
  return (
    <div className="returncalulator">
      <div className="calheading">
        <p className="text-black">
          <strong>Advanced ratios</strong>
        </p>
      </div>
      <div className="enquitytable">
        <ul>
          <li>
            <span>Top 5</span>
            <span>
              <strong>NA</strong>
            </span>
          </li>
          <li>
            <span>Top 20</span>
            <span>
              <strong>NA</strong>
            </span>
          </li>
          <li>
            <span>P/E Ratio</span>
            <span>
              <strong>
                {Number(ratiosData?.pescore).toFixed(2) === "NaN"
                  ? "NA"
                  : Number(ratiosData?.pescore).toFixed(2)}
              </strong>
            </span>
          </li>
          <li>
            <span>P/B Ratio</span>
            <span>
              <strong>
                {Number(ratiosData?.pbscore).toFixed(2) === "NaN"
                  ? "NA"
                  : Number(ratiosData?.pbscore).toFixed(2)}
              </strong>
            </span>
          </li>
        </ul>
        <ul>
          <li>
            <span>Alpha</span>
            <span>
              <strong>
                {Number(ratiosData?.alpha).toFixed(2) === "NaN"
                  ? "NA"
                  : Number(ratiosData?.alpha).toFixed(2)}
              </strong>
            </span>
          </li>
          <li>
            <span>Beta</span>
            <span>
              <strong>
                {Number(ratiosData?.beta).toFixed(2) === "NaN"
                  ? "NA"
                  : Number(ratiosData?.beta).toFixed(2)}
              </strong>
            </span>
          </li>
          <li>
            <span>Sharpe</span>
            <span>
              <strong>
                {Number(ratiosData?.sharpe_ratio).toFixed(2) === "NaN"
                  ? "NA"
                  : Number(ratiosData?.sharpe_ratio).toFixed(2)}
              </strong>
            </span>
          </li>
          <li>
            <span>Sortino</span>
            <span>
              <strong>
                {Number(ratiosData?.sortino_ratio).toFixed(2) === "NaN"
                  ? "NA"
                  : Number(ratiosData?.sortino_ratio).toFixed(2)}
              </strong>
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default AdvancedRatios;
