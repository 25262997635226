import { useEffect, useState } from "react";
import { getStockQuaterlyResult } from "../../../utils/APICollection";
import { ThreeDots } from "react-loader-spinner";
import { formatNumber } from "../../../components/common";
const StockQuaterly = ({ financeType }) => {
    const [stockQuaterlyData, setStockQuaterlyData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const fetchQuaterlyResult = async () => {
        setIsLoading(true);
        try {
            const data = await getStockQuaterlyResult(window.location.pathname.split("/")[3], financeType);
            setStockQuaterlyData(data?.data?.data);
            setIsLoading(false);
        } catch (e) {
            console.log(e);
        }
    }
    useEffect(() => {
        fetchQuaterlyResult();
    }, [window.location.pathname.split("/")[4], financeType])
    return (
        <div className="webcent financialsblock">
            {isLoading ? <ThreeDots
                height="100"
                width="100"
                radius="10"
                color="#3AA29F"
                ariaLabel="three-dots-loading"
                wrapperStyle={{
                    display: "flex",
                    justifyContent: "center",
                }}
                visible={true}
            /> :
                stockQuaterlyData.length > 0 ?
                    <div className="ipotable defaulttable">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">Column Name</th>
                                    <th scope="col">Mar 2024</th>
                                    <th scope="col">Dec 2023</th>
                                    <th scope="col">Sep 2023</th>
                                    <th scope="col">Jun 2023</th>
                                    <th scope="col">Mar 2023</th>
                                </tr>
                            </thead>
                            <tbody>
                                {stockQuaterlyData?.map(item =>
                                    <tr>
                                        <td>{item?.COLUMNNAME}</td>
                                        <td>{formatNumber(item?.Y202403?.toFixed(2)) || "NA"}</td>
                                        <td>{formatNumber(item?.Y202312?.toFixed(2)) || "NA"}</td>
                                        <td>{formatNumber(item?.Y202309?.toFixed(2)) || "NA"}</td>
                                        <td>{formatNumber(item?.Y202306?.toFixed(2)) || "NA"}</td>
                                        <td>{formatNumber(item?.Y202303?.toFixed(2)) || "NA"}</td>
                                    </tr>
                                )}

                            </tbody>
                        </table>
                    </div> : <div>No Data Found</div>}
        </div>
    )
}

export default StockQuaterly;